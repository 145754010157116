import { Table, Button, Modal, Spinner, Form, Row, Col } from "react-bootstrap"
import { useTranslation } from 'react-i18next'

import { getApiAdministration, postApiAdministration, deleteApiAdministration, putApiAdministration } from "../../common/APICalls/apiAdministration"
import { useEffect, useState } from "react"

import { useCases } from "../../kpi_queries/use_cases"
import * as FaIcons from 'react-icons/fa'

export const GroupsInfo = () => {

    const { t } = useTranslation()

    const [data, setData] = useState([])
    const [dataCOGS, setDataCOGS] = useState([])

    const [newGroup, setNewGroup] = useState(false)
    const [editGroup, setEditGroup] = useState(false)

    const [currentGroup, setCurrentGroup] = useState({});
    const [onWork, setOnWork] = useState(false)

    const [deleteGroup, setDeleteGroup] = useState()
    const [onDeleteGroup, setOnDeleteGroup] = useState(false)

    const [tabledUseCases , setTabledUseCases] = useState()
    const [tabledCOGS, setTabledCOGS] = useState()

    const handleGroups = () => {
      getApiAdministration('groups').then((response) =>{
          if (response ){
            setData(response)
          }    
      })
    }

    const handleDirectCOGS = () => {
      getApiAdministration('cogstypes').then((response) =>{
        if(response) {
          setDataCOGS(response)    
        }
      })
    }
    const handleSubmit = e => {        
      e.preventDefault()
      setOnWork(true)

      if (newGroup) {

        postApiAdministration('group',currentGroup).then((response) =>{
          if (response ){
              setNewGroup(false)
              handleGroups()
              setOnWork(false)
          }    
        })

      } else {

        putApiAdministration('group',currentGroup).then((response) =>{
            if (response ){
              setEditGroup(false)
              handleGroups()
              setOnWork(false)
          }    
        })
      }
    }
    
    const handleDeleteGroup = (idGroup) =>{
      setDeleteGroup(true)
      const index = data.findIndex(p => p.id === idGroup);
      setCurrentGroup(data[index])
    }

    const handleEditGroup = (idGroup) => {
      setEditGroup(true)
      const index = data.findIndex(p => p.id === idGroup);
      setCurrentGroup(data[index])
    }

    const handleCancelEditingAddGroups = () => { 
      setEditGroup(false)
      setNewGroup(false)
    }

    const handleGroupName = (e) => {
      let group = {...currentGroup};
      group.name = e;
      setCurrentGroup(group)
    }


    const handleGroupCode = (e) => {
      let group = {...currentGroup};
      group.Code = e;
      setCurrentGroup(group)

    }
    const handleGroupPriority = (e) => {
      let group = {...currentGroup};
      group.Priority = e;
      setCurrentGroup(group)
    }

    const handleChangeUse = (e) => {
      let group = {...currentGroup};
      group.UsedFor = e;
      setCurrentGroup(group)

    }

    const handleOnCheckboxClick = (e) => {

      const group ={...currentGroup}
      if (group.UseCases !== null && group.UseCases instanceof Array ) {
        const indexElement = group.UseCases.indexOf(e)
        if (indexElement === -1){
          group.UseCases.push(e)
        }  else {
          group.UseCases.splice(indexElement,1)
        }        
      } else {
          group.UseCases= Array(e)
      }

      setCurrentGroup(group)
 
    }

    const handleConfirmTrash = e => {
      setOnDeleteGroup(true)
      deleteApiAdministration('group/' +  currentGroup.id).then((response) => {
        if (response.code===200) {
          setOnDeleteGroup(false)
          setDeleteGroup(false)
          handleGroups()
        }
      })
    }

    const handleCheckBoxStatus = (itemValue) => {
      const group = {...currentGroup}
      if (group && group.UseCases !== null && group.UseCases instanceof Array) {
        if (group.UseCases.includes(itemValue)){
          return "checked"
        }
      } 
      return ""      
    }

    const handleCOGSBoxStatus = (itemValue) => {
      const group = {...currentGroup}
      if (group && group.DirectCost !== null && group.DirectCost instanceof Array) {
        if (group.DirectCost.includes(itemValue)){
          return "checked"
        }
      } 
      return ""      
    }

    const handleOnCheckboxCOGSClick = (e) => {

      const group ={...currentGroup}
      if (group.DirectCost !== null && group.DirectCost instanceof Array ) {
        const indexElement = group.DirectCost.indexOf(e)
        if (indexElement === -1){
          group.DirectCost.push(e)
        }  else {
          group.DirectCost.splice(indexElement,1)
        }        
      } else {
          group.DirectCost= Array(e)
      }

      setCurrentGroup(group)
 
    }

    const handleCheckManualEdtingStatus = ()  => {
      const group ={...currentGroup}
      if (group.ManuelEdit){
        return "checked"
      } 
      return ""
    }
    const handleManualeEditingCheckBox = (e) => {
      const group ={...currentGroup}
      group.ManuelEdit = !group.ManuelEdit
      setCurrentGroup(group)
    }


    const tableCOGS = () => {
      setTabledCOGS(dataCOGS.map(item => {

        return (
          <tr><td> 
            <Form.Check // prettier-ignore
            type={'checkbox'}
            value={item['code']}
            id={`${item['code']}`}
            checked={handleCOGSBoxStatus(item['code'])}
            onChange={() =>handleOnCheckboxCOGSClick(item['code'])}
            label={`${item['COGSDescription']}`}
          />

          </td></tr>
        );
      }))

    }

    const tableUseCases = () =>{

      let dataKeys = Object.entries(useCases.description)
      setTabledUseCases(dataKeys.map(item => {

        return (
          <tr><td> 
            <Form.Check // prettier-ignore
            type={'checkbox'}
            value={item[0]}
            id={`${item[1]}`}
            checked={handleCheckBoxStatus(item[0])}
            onChange={() =>handleOnCheckboxClick(item[0])}
            label={`${item[1]}`}
          />

          </td></tr>
        );
      }))
    }

    const formatUseCasesSummary = (group) => {
      if (group && group.UseCases !== null && group.UseCases instanceof Array) {
        return group.UseCases.join(",")
      } else {
        return ""
      }
    }

    const formatAssignedField = (assigned) => {
      switch (assigned) {
        case 'grossprofit':
          return "CoGS"
        case 'netprofit':
          return "OpEX"
        
        default:
          return "N/A";
      }
    }

  useEffect(() => {
      handleDirectCOGS()
      handleGroups()
  },[])

  useEffect(() => {
    tableUseCases()
    tableCOGS()
  },[currentGroup])



    return <div>
        <Table striped bordered style={{width:'100%'}}>
            <thead>
            <tr>
                <th className = "cell-label">{t('SECTION.ADMINISTRATION.GROUP_TABLE.PRIORITY')}</th>
                <th className = "cell-label">{t('SECTION.ADMINISTRATION.GROUP_TABLE.GROUPNAME')}</th>
                <th className = "cell-label">{t('SECTION.ADMINISTRATION.GROUP_TABLE.USECASES')}</th>
                <th className = "cell-label">{t('SECTION.ADMINISTRATION.GROUP_TABLE.USEDFOR')}</th>
                <th className = "cell-label">{t('SECTION.ADMINISTRATION.GROUP_TABLE.DIRECTCOST')}</th>
                <th className = "cell-label">{t('GENERAL.ACTIONS.LABEL')}</th>
            </tr>
            </thead>
            <tbody>
                {data.map((group) =>{
                    return <tr>
                        <td className="cell-label">{group.Priority}</td>
                        <td className="cell-label">{group.name}</td>
                        <td className="cell-label">{formatUseCasesSummary(group)}</td>
                        <td className="cell-label">{formatAssignedField(group.UsedFor)}</td>
                        <td className="cell-label">{group.DirectCost}</td>

                        <td className="cell-label">
                          <Row className="showComponentRowComponent">
                            <Col>
                              <Button variant="outline-secondary" 
                                onClick={((e) => handleEditGroup(group.id))}>
                                <FaIcons.FaPencilRuler />
                              </Button>
                            </Col>
                            <Col>
                            <Button variant="outline-danger" 
                                onClick={((e) => handleDeleteGroup(group.id))}
                                >
                                <FaIcons.FaTrash/>
                              </Button>
                            </Col>
                          </Row>
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
        <Button variant="primary"  className='login-button' onClick={()=>{setNewGroup(true); setCurrentGroup({})}}>
        
          New Group
        </Button>
        <Modal dialogClassName="modalGroup" show={newGroup || editGroup} >
          <Form onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title>{t('SECTION.ADMINISTRATION.NEW_GROUP_FORM.TITLE')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className={onWork ? "hideComponent" : "showComponent"}>
                  <Row>

                    <Col>
                          <Form.Group className="mb-3" controlId="nameGroup">
                          <Form.Label>Priority</Form.Label>
                              <Form.Control 
                                  type="text" 
                                  placeholder="Priority" 
                                  value={currentGroup.Priority}
                                  onChange={(e) => handleGroupPriority(e.target.value)} 
                              />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="nameGroup">
                          <Form.Label>Manual Editing</Form.Label>
                            <Form.Check
                              label="Manual Editing"
                              name="manual_editing"
                              type='checkbox'
                              value="1"
                              checked={handleCheckManualEdtingStatus()}
                              id={`manual-checkbox-1`}
                              onChange={() =>handleManualeEditingCheckBox()}
                  
                            />                          
                          </Form.Group>


                          <Form.Group className="mb-3" controlId="UsedFor">
                            <Form.Label>Cost Type:</Form.Label>
                            <Form.Select id='CAMGroupUse' onChange={(e) =>handleChangeUse(e.target.value)}>
                              <option value=''>-</option>
                              <option value='grossprofit'>CoGS</option>
                              <option value='netprofit'>OpEX</option>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="nameGroup">
                              <Form.Label>Name:</Form.Label>
                              <Form.Control 
                                  type="text" 
                                  placeholder="New Group Name" 
                                  value={currentGroup.name}
                                  onChange={(e) => handleGroupName(e.target.value)} 
                              />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="nameGroup">
                              <Form.Label>Code:</Form.Label>
                              <Form.Control 
                                  type="text" 
                                  placeholder="New Group Name" 
                                  value={currentGroup.Code}
                                  onChange={(e) => handleGroupCode(e.target.value)} 
                              />
                          </Form.Group>                  
                          <Form.Group className="mb-3" controlId="directCogs">
                          <Table striped bordered style={{width:'100%'}}>
                            <th>Direct Cogs</th>
                            <tbody>
                              {tabledCOGS}
                            </tbody>
                          </Table>
                      </Form.Group>
                    
                    </Col>

                    <Col size={8}>
                      <Form.Group className="mb-3" controlId="useCases">
                          <Table striped bordered style={{width:'100%'}}>
                            <th>Associated Use Cases</th>
                            <tbody>
                              {tabledUseCases}
                            </tbody>
                          </Table>
                      </Form.Group>
                    </Col>

                  </Row>                    
                </div>
                <div  className={!onWork ? "hideComponent" : "showComponent"}>
                 <Spinner animation="grow" size="xl" />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-danger" 
                        className="buttonQBOBoxInRow" 
                        onClick={()=>handleCancelEditingAddGroups()}>
                            {t('ACTIONS.CANCEL')}
                </Button>
                <Button variant="outline-primary" 
                        className="buttonQBOBoxInRow" 
                        type="submit">
                            {t('ACTIONS.CONFIRM')}
                </Button>

            </Modal.Footer>
            </Form>
        </Modal>

        <Modal show={deleteGroup} >
            <Modal.Header>
            <Modal.Title>
                Confirm Operation 
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='d-flex justify-content-center'>
                <div className={onDeleteGroup ? "hideComponent" : "showComponent"}>
                    Do you want delete this group?
                </div>
                <div  className={!onDeleteGroup ? "hideComponent" : "showComponent"}>
                <Spinner animation="grow" size="xl" />
                </div>
            </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" 
                        className="buttonQBOBoxInRow" 
                        onClick={()=>setDeleteGroup(false)}>
                            {t('ACTIONS.CANCEL')}
                </Button>
                <Button variant="primary" 
                        className="buttonQBOBoxInRow" 
                        onClick={() => handleConfirmTrash()}>
                            {t('ACTIONS.CONFIRM')}
                </Button>

            </Modal.Footer>
        </Modal>

        </div>
    }