import { useState, useEffect } from 'react';
import { useCubeQuery } from '@cubejs-client/react';
import { Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { MemoHalfDoughnutKPI } from './Graph/HalfDoughnut';
import { ChurnRateWidgetNoLabel } from './ChurnRate';
export const CustomersTotalGrouped = ({ queriesToRender, title, itemsLine }) => {

  const [data, setData] = useState()
  const [totalValue, setTotal] = useState(0)

  const { resultSet: total } = useCubeQuery(queriesToRender[0]);
  const { resultSet: inGroup } = useCubeQuery(queriesToRender[1]);

  const { t } = useTranslation();
  function getChart(totalRow, table, key,conf) {
    const columns = table.tableColumns()
    const labelKey = conf==='CustomersTotal.id_cohort' ? columns[1].key :columns[0].key
    const valueKey = conf==='CustomersTotal.id_cohort' ? columns[2].key :columns[1].key

    const groupRow = table.tablePivot().find(r => r[labelKey] === totalRow[labelKey])
    if (typeof groupRow === 'undefined') {
      return {
        total: 0,
        inGroup: 0,
        chart: ''
      }
    }
    return {
      total: parseInt(totalRow[valueKey]),
      inGroup: parseInt(groupRow[valueKey]),
      chart: <Col lg={12/itemsLine} key={key + totalRow[labelKey]} className={12/itemsLine === 6 ? 'chartplacement':''}>
        <div className='chart-xs' >
          <MemoHalfDoughnutKPI value={groupRow[valueKey] / totalRow[valueKey] * 100} ascending={false} heightPx={'200px'} />
        </div>
        <div>
          {totalRow[labelKey] ?? 'N/A'}
        </div>
      </Col>
    }
  }
  useEffect(() => {
    if (!total || !inGroup) {
      return
    }

    const columns = total.tableColumns()
    let inGroupCount = 0
    let totalCount = 0
    const inGroupCharts = total.tablePivot().filter(r => r[columns[0].key]).map((totalRow) => {
      const cohort = getChart(totalRow, inGroup, title,columns[0].key)
      inGroupCount += cohort.inGroup
      totalCount += cohort.total
      return cohort.chart
    })

    setTotal(inGroupCount / totalCount * 100)
    setData(inGroupCharts)
  }, [total, inGroup]);

  return <Row>
    <Col lg={3}>
      {totalValue > 0 ? <Card className="card_kpi">
        <Card.Title>{t('KPIs.TITLES.TOTAL')}</Card.Title>
        <Card.Body>
          <ChurnRateWidgetNoLabel />
          <div>
            Total
          </div>
        </Card.Body>
      </Card> : <div>Wait...</div>
      }
    </Col>
    <Col lg={9}>
      {data ? <Card className="card_kpi">
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Row>
            {data}
          </Row>
        </Card.Body>
      </Card> : <div>Wait...</div>
      }
    </Col>
  </Row>
};
