// Application Import
import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'

// Context Import
import { Context } from '../../App'

// Hooks Import
import { useContext, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'

// Queries Import
import { revenueByEvents } from '../../kpi_queries/gross_revenue'
import { concetrationIndex, concetrationIndexGrouped } from '../../kpi_queries/concentration_index'
import { gross_revenue_by_cohort } from '../../kpi_queries/customers_number'
import { grossProfitByUseCase } from '../../kpi_queries/gross_revenue'
// Internal Component Import
import { TotalByUseCase } from '../TotalByUseCase'
import { KPIMonthlyView } from '../KPI_Monthly_View'
import { KPITeamMember } from '../KPI_Team_Member'
import { QueryCountWidget } from '../QueryCountWidget';
import { CustomerCohort } from '../CustomerCohort'
import { ConcentrationIndexWidget } from '../ConcentrationIndex'
import { ConcentrationIndexGrupedWidget } from '../ConcentrationIndexGrouped'
import { KPICountryGroup } from '../KPI_CountryGroup';

// Formatting and Selector configuration import 
import { TitleCard, TitleSection } from '../../utilities/ui_tools'
import { dateUtilities, lastDayMonth, YearToDateRange } from '../../utilities/date_formatting';

//Version 1.5 Queries
import { total_reveneue_period_v15,total_revenue_v15 } from '../../kpi_queries/gross_revenue'
import { total_reveneue_period_long_v15 } from '../../kpi_queries/gross_revenue'

import { TopCustomersBy } from '../TopCustomersBy';


/**
* The component manage and display the KPI related to  Gross Revenue.  
* 
*/
export const GrossRevenueSection = () => {

  const { t } = useTranslation()    //Initialize translation component

  const [state] = useContext(Context)   //General Context
  const [topCustomersLabelRange] = useState(state.date_range.split(';'))

  // Label sub-section definition and their update listener. 
  const [summaryLabelRange] = useState(state.date_range.split(';'))
  const [trendLabelRange] = useState(state.date_range.split(';'))
  const [concentrationLabelRange] = useState(state.date_range.split(';'))
  const [cohortLabelRange] = useState(state.date_range.split(';'))
  const [countryLabelRange] = useState(state.date_range.split(';'))
  const [useCaseLabelRange] = useState(state.date_range.split(';'))
  const [teamLabelRange] = useState(state.date_range.split(';'))

  // Here is retruned the rendered JSX component
  return <div>

    {/* Gross Revenue Summary Section*/}
    <Card id="grossRevenue" className="card_section">

      <TitleCard label={t('SECTION.REVENUE.SUMMARY')}
        date_end={process.env.REACT_APP_HR_REFERENCE_DATE}
      />

      <Card.Body className='card-height-xs'>

        <Row xs={1} md={2} lg={3} className="g-5 car row_kpi">

          {/* Trailing Revenue at the date*/}
          <Col className="col_kpi" >
            <Card className="card_kpi">

              <Card.Title>{t('KPIs.TITLES.REVENUE_12MONTHS')}</Card.Title>

              <Card.Body>
                <QueryCountWidget 
                  period={[state.date_range.split(';'), state.date_range_prior.split(';')]}
                  query={total_reveneue_period_v15} 
                  kpi={'totalAmount'} 
                  multiplier={100}
                />
              </Card.Body>

            </Card>
          </Col>

          {/* Year To Date Revenue*/}
          <Col className="col_kpi">
            <Card className="card_kpi">
              <Card.Title>{t('KPIs.TITLES.REVENUE_YTD')}</Card.Title>
              <Card.Body>
                <QueryCountWidget 
                  period={YearToDateRange()} 
                  query={total_reveneue_period_v15} 
                  kpi={'totalAmount'}
                  multiplier={100}  
                />
              </Card.Body>
            </Card>
          </Col>

          {/* Concentration Index Self Service Revenue*/}
          <Col className="col_kpi">
            <Card className="card_kpi">

              <Card.Title>{t('KPIs.TITLES.CONCENTRATIONFORREVENUE')}</Card.Title>

              <Card.Body>
                <ConcentrationIndexWidget 
                  queryToRender={concetrationIndex(state.date_range.split(';'))} 
                  without_border='true' />
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Card.Body>
      <Card.Footer className='card-footer-muted'>
      {state.lookback === 1 ?
                  <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_MONTHTRAIL"
                    t={t}
                    components={{ DATE_START: dateUtilities.monthsNamesShort[new Date(summaryLabelRange[0]).getUTCMonth()] + ' ' + (new Date(summaryLabelRange[0]).getUTCFullYear()), }}
                  />
                  :
                  <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_TOTMONTHS"
                    t={t}
                    components={{
                      DATE_START: '01 ' + dateUtilities.monthsNamesShort[new Date(summaryLabelRange[0]).getUTCMonth()] + ' ' + (new Date(summaryLabelRange[0]).getUTCFullYear()),
                      DATE_END: process.env.REACT_APP_HR_REFERENCE_DATE,
                    }}
                  />
                }

      </Card.Footer>
    </Card>

    {/* Revenue Trend Section*/}
    <Card className="card_section">

      <TitleCard label={t('SECTION.REVENUE.TREND')} date_end={process.env.REACT_APP_HR_REFERENCE_DATE} />

      <Card.Body className='card-height-m'>
        <KPIMonthlyView granularity={state.granularity}
          queryTrend={total_reveneue_period_long_v15([state.data_series_date_interval_prior[0],state.data_series_date_interval[1]], 'totalAmount',state.granularity,null)}
          queryTrendCohort={total_reveneue_period_long_v15(state.data_series_date_interval, 'totalAmount',state.granularity, ["MnEvents.customerCohortId"],{"member": "MnEvents.customerCohortId","operator": "set", "values": []},[["MnEvents.customerCohortId","desc"]])}
          queryTrendUseCase={total_reveneue_period_long_v15(state.data_series_date_interval, 'totalAmount',state.granularity, ["MnEvents.useCase"],null)}
          queryTrendTopCustomer={total_reveneue_period_long_v15([state.data_series_date_interval[0],state.data_series_date_interval[1]], 'totalAmount',state.granularity, ["MnEvents.customerId"],{"member": "MnEvents.customerId","operator": "equals", "values":state.KPIs_Exclusion_List })}
          type="2"
          keyStorage='gross_month' 
          Yaxis="$"/>
      </Card.Body>

      <Card.Footer className='card-footer-muted'>
        {state.timeInterval === 1 ?
          <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_MONTHTRAIL"
            t={t}
            components={{
              DATE_START: dateUtilities.monthsNamesShort[new Date(trendLabelRange[0]).getUTCMonth()] + ' ' + (new Date(trendLabelRange[0]).getUTCFullYear()),
            }}
          />
          :
          <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_TOTMONTHS"
            t={t}
            components={{
              DATE_START: '01 ' + dateUtilities.monthsNamesShort[new Date(trendLabelRange[0]).getUTCMonth()] + ' ' + (new Date(trendLabelRange[0]).getUTCFullYear()),
              DATE_END: process.env.REACT_APP_HR_REFERENCE_DATE,
            }}
          />
        }
      </Card.Footer>
    </Card>

    {/* Concentration Index Section*/}
    <Card className="card_section">

      <TitleCard label={t('SECTION.REVENUE.CONCENTRATION')} date_end={process.env.REACT_APP_HR_REFERENCE_DATE}/>
      <Card.Body className='card-height-xs'>

        <Row xs={1} md={2} lg={2} className="g-5 car row_kpi">

          {/* General Concentration Index*/}
          <Col lg={3} className="col_kpi">
            <ConcentrationIndexWidget queryToRender={concetrationIndex(state.date_range.split(';'))} />
          </Col>

          {/* Concentration Index by Cohort*/}
          <Col lg={9} className="col_kpi">
            <ConcentrationIndexGrupedWidget queryToRender={concetrationIndexGrouped(state.date_range.split(';'))} />
          </Col>
        </Row>

      </Card.Body>

      <Card.Footer className='card-footer-muted'>
        {state.lookback === 1 ?
          <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_MONTHTRAIL"
            t={t}
            components={{
              DATE_START: dateUtilities.monthsNamesShort[new Date(concentrationLabelRange[0]).getUTCMonth()] + ' ' + (new Date(concentrationLabelRange[0]).getUTCFullYear()),
            }}
          />
          :
          <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_TOTMONTHS"
            t={t}
            components={{
              DATE_START: '01 ' + dateUtilities.monthsNamesShort[new Date(concentrationLabelRange[0]).getUTCMonth()] + ' ' + (new Date(concentrationLabelRange[0]).getUTCFullYear()),
              DATE_END: process.env.REACT_APP_HR_REFERENCE_DATE,
            }}
          />
        }
        <br />
        <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LOWER_BETTER"
          t={t}
        />

      </Card.Footer>
    </Card>

    {/*Revenue by Cohort */}
    <Card className="card_section">

      <TitleCard label={t('SECTION.REVENUE.COHORT')} date_end={process.env.REACT_APP_HR_REFERENCE_DATE} />

      <Card.Body className='card-height-s'>
        <CustomerCohort queryToRender={gross_revenue_by_cohort([state.date_range.split(';'), state.date_range.split(';')])}
          referenceTotal={total_revenue_v15(state.date_range.split(';'),'totalAmount')} type="gross_revenue" storageKey='GrossRevenue' />
      </Card.Body>

      <Card.Footer className='card-footer-muted'>
        {state.lookback === 1 ?
          <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_MONTHTRAIL"
            t={t}
            components={{
              DATE_START: dateUtilities.monthsNamesShort[new Date(cohortLabelRange[0]).getUTCMonth()] + ' ' + (new Date(cohortLabelRange[0]).getUTCFullYear()),
            }}
          />
          :
          <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_TOTMONTHS"
            t={t}
            components={{
              DATE_START: '01 ' + dateUtilities.monthsNamesShort[new Date(cohortLabelRange[0]).getUTCMonth()] + ' ' + (new Date(cohortLabelRange[0]).getUTCFullYear()),
              DATE_END: process.env.REACT_APP_HR_REFERENCE_DATE,
            }}
          />
        }
        <br />
        <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.FOOTER_COHORT"
          t={t}
          components={{
            MONTHS: state.lookback.toString(),
            UNIT: state.lookback === 1 ? 'month' : 'months'
          }}
        />
      </Card.Footer>
    </Card>

    {/* Revenue by Team Member */}
    <Card className="card_section">

      <TitleCard label={t('SECTION.REVENUE.TEAM_MEMBER')} date_end={process.env.REACT_APP_HR_REFERENCE_DATE}/>

      <Card.Body className='card-height-m'>
        <KPITeamMember 
          kpi={'grossRevenue'} 
          daterange={state.date_range.split(';')} 
          title={t('TABS.REV_FTE')}
        />
      </Card.Body>

      <Card.Footer className='card-footer-muted'>
        <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_TOTMONTHS"
          t={t}
          components={{
            DATE_START: '01 ' + dateUtilities.monthsNamesShort[new Date(teamLabelRange[0]).getUTCMonth()] + ' ' + ((new Date(teamLabelRange[0]).getUTCFullYear()) - 1),
            DATE_END: process.env.REACT_APP_HR_REFERENCE_DATE,
          }}
        />
        <br/>
        12-Month Rolling Average.

      </Card.Footer>
    </Card>

    {/* Gross Revenue by USe Case */}
    <Card className="card_section">

      <TitleCard label={t('SECTION.REVENUE.USECASE')} date_end={process.env.REACT_APP_HR_REFERENCE_DATE}/>

      <Card.Body className='card-height-m'>
        <TotalByUseCase query={grossProfitByUseCase}
          groupDimension="MnEvents.useCase"
          referenceTotal={total_revenue_v15(state.date_range.split(';'),'totalAmount')} />
      </Card.Body>

      <Card.Footer className='card-footer-muted'>
        {state.usecases_lookback === 1 ?
          <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_MONTHTRAIL"
            t={t}
            components={{
              DATE_START: dateUtilities.monthsNamesShort[new Date(useCaseLabelRange[0]).getUTCMonth()] + ' ' + (new Date(useCaseLabelRange[0]).getUTCFullYear()),
            }}
          />
          :
          <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_TOTMONTHS"
            t={t}
            components={{
              DATE_START: '01 ' + dateUtilities.monthsNamesShort[new Date(useCaseLabelRange[0]).getUTCMonth()] + ' ' + (new Date(useCaseLabelRange[0]).getUTCFullYear()),
              DATE_END: process.env.REACT_APP_HR_REFERENCE_DATE,
            }}
          />
        }
      </Card.Footer>
    </Card>

    {/* Gross Revenue by Country */}
    <Card className="card_section">

      <TitleCard label={t('SECTION.REVENUE.COUNTRY')} date_end={process.env.REACT_APP_HR_REFERENCE_DATE} />

      <Card.Body className='card-height-xl'>
        <KPICountryGroup referencesDates={state.date_range}
          groupDimension="MnEvents.ctStripeCardCountry"
          referenceTotal={total_revenue_v15(state.date_range.split(';'),'totalAmount')} 
          kpi={'MnEvents.totalAmount'}/>
      </Card.Body >

      <Card.Footer className='card-footer-muted'>
        {state.lookback === 1 ?
          <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_MONTHTRAIL"
            t={t}
            components={{
              DATE_START: dateUtilities.monthsNamesShort[new Date(countryLabelRange[0]).getUTCMonth()] + ' ' + (new Date(countryLabelRange[0]).getUTCFullYear()),
            }}
          />
          :
          <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_TOTMONTHS"
            t={t}
            components={{
              DATE_START: '01 ' + dateUtilities.monthsNamesShort[new Date(countryLabelRange[0]).getUTCMonth()] + ' ' + (new Date(countryLabelRange[0]).getUTCFullYear()),
              DATE_END: process.env.REACT_APP_HR_REFERENCE_DATE,
            }}
          />
        }
        <br />
        <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.CREDIT_CARD"
          t={t}
          components={{
            MONTHS: state.lookback.toString(),
            UNIT: state.countryu_lookback === 1 ? 'month' : 'months'
          }}
        />
      </Card.Footer>
    </Card>

        {/* Top Customers Card */}
        <Card className="card_section">
      <TitleCard label={t('SECTION.CUSTOMERS.TOP_CUSTOMERS')+t('KPIs.LABELS_AND_FOOTERS.NOTE_BY_REVENUE')}
        date_end={process.env.REACT_APP_HR_REFERENCE_DATE}
      />

      <Card.Body className='card-height-m'>

        {/* Top Customers Widget */}
        <TopCustomersBy
          measure={'MnEvents.totalAmount'}
          seeMargin={false}
          periodToShow={state.period}
          granularity={state.granularity}
          limit={state.limit}
          noPercent={false}

        />
      </Card.Body>
      <Card.Footer className='card-footer-muted'>
        <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_TOTMONTHS"
          t={t}
          components={{
            DATE_START: '01 ' + dateUtilities.monthsNamesShort[new Date(topCustomersLabelRange[0]).getUTCMonth()] + ' ' + (new Date(topCustomersLabelRange[0]).getUTCFullYear()),
            DATE_END: process.env.REACT_APP_HR_REFERENCE_DATE,
          }}
        />
        <br />
      </Card.Footer>
    </Card> 

  </div>
}