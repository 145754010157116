// Hooks Import
import {useState, useEffect, useRef} from 'react';

import { Modal } from 'react-bootstrap';
// Graph Type Import  
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import 'chart.js/auto';
import {Bar, getElementAtEvent} from 'react-chartjs-2';
// Internal Component  Import
import { BarChartTeamLegend } from './BarChartTeamLegend';

import { dateUtilities } from '../../utilities/date_formatting';
// Utilitie Import
import { palette } from '../../utilities/ui_tools';
import { useCases } from '../../kpi_queries/use_cases';
import { customerCohorts } from '../../kpi_queries/customer_cohorts';
export const BarChartFTEKPI = (({dataseries, title, colorMono }) => {


  const[state, setState] =useState()
    const[chartTitle, setTitle] = useState("")
    const[selectedChart, setSelectedChart] = useState()
    const chartRef = useRef(null)
    
    const [tooltip, setTooltip] = useState({refDate:'', refLabel:''}) //initial tooltip state


    const options = {
      title:{
        display:true,
        text:{chartTitle},
        fontSize:20
      },
      maintainAspectRatio:false,
      scales: {
        x: {
          ticks: {
            autoSkip: false,
          },
          title: {
            display: true,
            text: 'Period'
          },
          border: {
            color: '#0C3761',
            width: 1
          }
  
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: 'FTE Number'
          },
          border: {
            color: '#0C3761',
            width: 1
          }
    
        },

      },

      
      plugins:{
        legend: {
          display: false,
        },
        tooltip:{
          enabled: false
        }
      },
      onHover: (event, chartElement) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
      }
    }

    const onBarClick = (event,state) =>{
      const itemsCurrentEvent = getElementAtEvent(chartRef.current,event)
      if (itemsCurrentEvent && itemsCurrentEvent.length > 0){
        setTooltip({refDate:state.references[itemsCurrentEvent[0].index], refLabel:state.labels[itemsCurrentEvent[0].index]})
        itemsCurrentEvent[0].element.options.backgroundColor = '#fd7e14'
        setSelectedChart(itemsCurrentEvent[0].index)
      }

    }
    useEffect(() => {
        if (dataseries){
          setTitle(title)
          const BarScaleColor = () =>{
            const arrayPalette = []
            for (let index = 0; index < dataseries.data.length; index++) {
              arrayPalette.push(index === selectedChart ? '#fd7e14' : "#33658a");
            }
            return arrayPalette
          }
          setState({
            labels: dataseries.dimension,
            references: dataseries.references,
            datasets: [{
              fill: false,
              lineTension: 0.5,
              borderColor: palette({colorMono: colorMono, storeKey:null}),
              tension: 0.1,
              backgroundColor: BarScaleColor(),
              borderWidth: 1,
              data: dataseries.data
            }]
          })
      } 
    },[dataseries, title, colorMono, selectedChart])

    return (<>
        {state ?
        <div>
        <div style={{width:'800px',float:'left'}}>
          <Bar  
            data = {state}
            width={700}
            height={800}
            options = {options}
            ref = {chartRef}
            onClick={((e) => onBarClick(e, state))}
          />
          </div>
          <div style={{float:'left', marginLeft:'20px'}}>
          {/** For each Istogram Bar is created the Legend with , the legend is rendereed  */}
          <BarChartTeamLegend refDate = {tooltip} />
        </div>
        </div>
        :
        <div></div>}
        </>)
})

export const BarChartKPINoOver = (({dataseries, title, colorMono, Yaxis, Xaxis }) => {


  const[state, setState] =useState()
    const[chartTitle, setTitle] = useState("")
    
    const chartRef = useRef(null)
    
    const options = {
      title:{
        display:true,
        text:{chartTitle},
        fontSize:20
      },
      scales: {
        x: {
          ticks: {
            autoSkip: false,
          },
          title: {
            display: true,
            text: Xaxis
          },
        },
        y: {
          stacked: true,
          border: {
            color: '#0C3761',
            width: 1
          },
          grid: {
            color: function(context) {  
              if (context.tick.value === 0) {
                return '#0C3761';
              } 

              return '#ddd';            
            },
            lineWidth: function(context) {
              if (context.tick.value === 0){
                return 1
              }
              return 1
            }
          }
  
        }
      },

      responsive:true,
      maintainAspectRatio:false,
      plugins:{
        legend: {
          display: false,
        },
        tooltip:{
          enabled: false,
        }
      },
    }

    if (Yaxis === "$"){
      options.scales.y.ticks = {
        // Include a dollar sign in the ticks
        callback: function(value, index, ticks) {
            
          return  value >= 0 ?  "$"+ value.toLocaleString() : "-$" + Math.abs(value).toLocaleString();
        }
      }
  
      options.scales.y.title={
        display: true,
        text:'US Dollars'
      }
  
    } else if (Yaxis === "%") {
      options.scales.y.ticks = {
        // Include a dollar sign in the ticks
        callback: function(value, index, ticks) {
            return  value.toFixed(2) +" %";
        }
      }
      options.scales.y.title={
        display: true,
        text:'Percentage'
      }
    } else {
      options.scales.y.title={
        display: true,
        text:'Customers'
      }
    }


    useEffect(() => {
        if (dataseries){

            setTitle(title)
            setState({
              labels: dataseries.dimension,
              references: dataseries.references,
              datasets: [{
                    fill: false,
                    lineTension: 0.5,
                    borderColor: palette({colorMono: colorMono, storeKey:null}),
                    tension: 0.1,
                    backgroundColor: palette({colorMono: colorMono, storeKey:null}),
                    borderWidth: 1,
                    data: dataseries.data
                }]
            })
      } 
    },[dataseries,title,colorMono])

    return (<>
        {state ?
          <Bar  data = {state}
            width={700}
            height={800}
            options = {options}
            ref = {chartRef}
          />
        :
        <div></div>}
        </>)
})



export const BarChartRevenue = (({dataseries, title, Yaxis, Xaxis,colorMono, filterable,filterSeries }) => {

  const[state, setState] =useState()
  const[chartTitle, setTitle] = useState("")
      
  const chartRef = useRef(null)
  
    const options = {
      title:{
        display:true,
        text:{chartTitle},
        fontSize:20
      },
      scales: {
        x: {
          ticks: {
            autoSkip: false,
            maxRotation: 45,
            minRotation: 45
          },
          title: {
            display: true,
            text: 'Period'
          },
        },
        y: {
          stacked: true,
          title:{
            display: true,
            text: Xaxis
          },
          border: {
            color: '#0C3761',
            width: 1
          },
          grid: {
            color: function(context) {
              if (context.tick.value === 0) {
                return '#0C3761';
              } 

              return '#ddd';            
            },
            lineWidth: function(context) {
              if (context.tick.value === 0){
                return 1
              }
              return 1
            }
          }
        }
      },
  
      maintainAspectRatio:false,

      plugins:{
        legend: {
          display: false,
        },
        tooltip:{
          enabled: false,
        }
      },
    }
  
    if (Yaxis === "$"){
      options.scales.y.ticks = {
        // Include a dollar sign in the ticks
        callback: function(value, index, ticks) {
            
          return  value >= 0 ?  "$"+ value.toLocaleString() : "-$" + Math.abs(value).toLocaleString();
        }
      }
  
      options.scales.y.title={
        display: true,
        text:'US Dollars'
      }
  
    } else if (Yaxis === "%") {
      options.scales.y.ticks = {
        // Include a dollar sign in the ticks
        callback: function(value, index, ticks) {
            return  value.toFixed(2) +" %";
        }
      }
      options.scales.y.title={
        display: true,
        text:'Percentage'
      }
    } else {
      options.scales.y.title={
        display: true,
        text:'Customers'
      }
    }
  
    useEffect(() => {
        if (dataseries){

          const labelsFormatted = dataseries[0].labels.map((c) => {
            const month = c.split(' ')[0];
            if (month.length === 3) {
              return dateUtilities.Quarters[dateUtilities.monthsNamesShort.indexOf(month)] + ' ' + c.split(' ')[1]
            } else {
              return c
            }

            })
            setTitle(title)
            setState({
              labels: labelsFormatted,
              references: dataseries[0].labels,
              datasets: [{
                fill: false,
                lineTension: 0.5,
                borderColor: palette({colorMono: colorMono, storeKey:null}),
                tension: 0.1,
                backgroundColor:(context) =>{
                  const chart = context.chart
                  const {ctx, chartArea} = chart;
                  if(!chartArea )  {
                    return null
                  }
                  return getGradient(context.raw)},
                borderWidth: 1,
                data: dataseries[0].data
              }]
            })
      } 
    },[dataseries,title,colorMono,filterable, filterSeries])

    function getGradient(chart){

      if (colorMono && chart <1)
        return 'rgba(237,41,56,0.9)'

      return palette({colorMono: colorMono, storeKey:null})
   
    }
    return (<>
        {state ?
        <div>
          <Bar  data = {state}
                width = {'400px'}
                height={'500px'}

                options = {options}
                ref = {chartRef}
          />
          </div>
        :
        <div></div>}
        </>)
})


export const MultiBarStackKPI = ({ dataSeries, kindOfGroup, granularity,Yaxis, filterable=false }) => {

  const colorMultiLine = ["#33658a", "#86bbd8", "#5b8298", "#2f4858", "#f6ae2d", "#f26419", "#D26E3F", "#da2c38", "#b17864", "#C2967A", "#87c38f", "#D3B490", "#f4f0bb"]
  const [state, setState] = useState()
  const multiLinesOptions = {
    scales: {
      x: {
        stacked: true,
        title: {
          display:true,
          text: 'Period'
        },
        border: {
          color: '#0C3761',
          width: 1
        },
      },
      y: {
        stacked: true,
        border: {
          color: '#0C3761',
          width: 1
        },
        grid: {
          color: function(context) {
            if (context.tick.value === 0) {
              return '#0C3761';
            } 

            return '#ddd';            
          },
          lineWidth: function(context) {
            if (context.tick.value === 0){
              return 1
            }
            return 1
          }
        }

      },
  
    },  
    plugins: {
      legend: {
        onHover: (event,chartElement) =>{
          event.native.target.style.cursor = 'pointer'          
        },
        onLeave: (event,chartElement) =>{
          event.native.target.style.cursor = 'normal'          
        },
        display: true,
        position: 'right',
        reverse: kindOfGroup === 'cohort' ? true :false,
      },
      tooltip : {
        callbacks: {
          label: function (tooltipItem, data) {
            let formattedValues = ''
            if (tooltipItem.raw > 0) {
              formattedValues ='$' + tooltipItem.raw.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0})
            } else {
              formattedValues ='-$' + Math.abs(tooltipItem.raw).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0})
            }
            return tooltipItem.dataset.label + ': ' + formattedValues  ;
        },
        }
      }
    },
    hover: false,
    maintainAspectRatio: false,
  }
  
  if (Yaxis === "$"){
    multiLinesOptions.scales.y.ticks = {
      // Include a dollar sign in the ticks
      callback: function(value, index, ticks) {
          
        return  value >= 0 ?  "$"+ value.toLocaleString() : "-$" + Math.abs(value).toLocaleString();
      }
    }

    multiLinesOptions.scales.y.title={
      display: true,
      text:'US Dollars'
    }

  } else if (Yaxis === "%") {
    multiLinesOptions.scales.y.ticks = {
      // Include a dollar sign in the ticks
      callback: function(value, index, ticks) {
          return  value.toFixed(2) +" %";
      }
    }
    multiLinesOptions.scales.y.title={
      display: true,
      text:'Percentage'
    }
  } else {
    multiLinesOptions.scales.y.title={
      display: true,
      text:'Customers'
    }
  }

  useEffect(() => {
    if (dataSeries) {
      let chartDataset = dataSeries.series().map((s, index) => ({
        label: kindOfGroup === 'cohort' ? s.title.split(',')[0] === '[Empty string]' ? 'N/A' : customerCohorts.cohort_description[s.title.split(',')[0]] : useCases.description_short[s.title.split(',')[0]],
        data: s.series.map((r) => (r.value / 100)),
        backgroundColor: colorMultiLine[index],
      }))
      const finalData = {
        labels: dataSeries.categories().map((c) => {
          const dateEvent = new Date(c.x)
          if (dateEvent.getTimezoneOffset() < 0){
            dateEvent.setUTCDate(dateEvent.getUTCDate()+1 );
          } else {
            dateEvent.setUTCDate(dateEvent.getUTCDate() + 1 );
          }

          switch (granularity) {
            case 'quarter':
              return dateUtilities.Quarters[dateEvent.getUTCMonth()] + ' ' + (dateEvent.getUTCFullYear()).toString()
            default:
              return '';
          }

        }),
        datasets: chartDataset,
      }
      setState(finalData)
    }
  }, [dataSeries, granularity, kindOfGroup])

  return (<>
    {(state) ?
      <Bar
        height={'500px'}
        width={'400px'}
        type="line"
        data={state}
        options={multiLinesOptions}
      />
      :
      <></>
    }
  </>)

}
