// Application Import
import React from 'react'
import { Card, Col, Row, Form, Button, Container, Table, FloatingLabel } from 'react-bootstrap';
import { FaArrowCircleUp, FaArrowCircleDown, FaSort } from 'react-icons/fa';
import { TitleCard, TitleSection } from '../../../utilities/ui_tools'
import { FormattedValue } from '../../../common/FormattedValues/FormattedValues';
import { useCases } from '../../../kpi_queries/use_cases';
import { getApiData, getApiAdministration } from '../../../common/APICalls/apiAdministration';
// Context Import
import { Context } from '../../../App'
import { timezoneOffset } from '../../../utilities/ui_tools';
// Hooks Import
import { useState, useContext, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next';
import { FaMagnifyingGlassChart } from "react-icons/fa6";
import { VscBlank } from "react-icons/vsc";
import { dateUtilities } from '../../../utilities/date_formatting';
/**
* The component manage and display the KPI related to  Customers.  
* 
*/
export const ProfitAndLossSection = () => {
  const { t } = useTranslation()  //Initialize Translation related component.
  const [state] = useContext(Context)
  const [dimension,setDimension] =useState('')
  const [customCustomerID, setCustomCustomerID] = useState('')
  const [data, setData] = useState([])
  const [tableData, setTableData] = useState()

  const [sortField, setSortField] = useState("total"); 
  const [sortOrder, setSortOrder] = useState("desc"); 
  const [showDetail, setShowDetail] = useState(false);
  const [customSearch, setCustomSearch] = useState(false);

  const [detailSelected, setDetailSelected] = useState({})
  const [detailOwner, setDetailOwner] = useState('')
  const [cartTitle, setCardTitle] = useState('')

  const prepareCardTitle = () => {
    const IdDetailed = dimension === 'TransactionType' ? useCases.description_short[detailSelected.Key] : detailSelected.key
    setCardTitle( 'Quarter' ? createLabelDimension(dimension):  createLabelDimension(dimension) + IdDetailed)
  }

  const prepareCustomerNameField = (value) => {
    return value ? value : 'N/A'
  }
  const prepareDetailOwner = (item) => {
    switch (dimension) {
      case 'TransactionType':
        setDetailOwner(createLabelDimension() + ': ' + useCases.onlyText[item])
        break
      case 'CustomerId':
      case 'customCustomer':
         getApiAdministration('customer/' + item).then((response) =>{
          if (response ){
            setDetailOwner('Customer Name: ' + prepareCustomerNameField(response[0].firstName)  + ' ' + prepareCustomerNameField(response[0].lastName) + ' (' + prepareCustomerNameField(response[0].companyName) + ') ' + prepareCustomerNameField(response[0].email))
          }    
        })
        break
      case 'Quarter':
        const d = new Date(item)
        if (d.getTimezoneOffset() < 0){
          d.setUTCDate(d.getUTCDate()+1 );
        } else {
          d.setUTCDate(d.getUTCDate() + 1 );
        }

        setDetailOwner(createLabelDimension() + ': ' + dateUtilities.Quarters[d.getUTCMonth()] + ' ' + new Date(item).getFullYear())
        break

      default:
        setDetailOwner(createLabelDimension() + ': ' + item)
        break
    }
    
  }
  const handleChangeDimension =(e) => {
    setShowDetail(false)
    if (e.target.value === 'customCustomer'){
      setCustomSearch(true)
      setDimension(e.target.value)
    } else {
      setCustomSearch(false)
      setDimension(e.target.value)
    }
  }

  const handleCustomerID = (e) =>{
    setCustomCustomerID(e)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setShowDetail(false)
    loadData()
  }

  const handleDetail = (index) =>{
    const dataToOrder = data
    const sorted = dataToOrder.sort((a, b) => { 
        const multi = sortOrder === "asc" ? 1 : -1 
        return multi * (a.Priority - b.Priority || a[sortField] - b[sortField]) 
      })
    setDetailSelected(sorted[index])
    setShowDetail(true)
    prepareDetailOwner(sorted[index].key)
    prepareCardTitle()
  }

  const createLabelDimension = () => {
    switch (dimension) {
      case 'CustomerId':
        return 'Customer ID'
      case 'TransactionType':
        return 'Use Case';
      case 'CustomerCohort':
        return 'Customer Cohort'
      case 'CtCardCountry':
        return 'Customer Country'
      case 'Quarter':
        return 'Quarter'
      default:
        return ''
    }
  }

  const loadData = () => {
    if (dimension !== ''){
      const params = {
        group :dimension,
        referenceDate: process.env.REACT_APP_REFERENCE_DATE,
        lookback: state.lookback,
        limit: dimension === 'CustomerId' ? 20 :0, 
        customerID: customCustomerID
      }
      setData([])
      setTableData([])
      getApiData('pandl/groupbydimension',{params}).then((response) =>{
          if (response ){
            setData(response)
          }    
      })
  
    }

  }

  const sortFunction = (f) => { 
    if (sortField === f) { 
        setSortOrder(sortOrder === "asc" ? "desc" : "asc") 
    } else { 
        setSortField(f) 
    } 
  }; 


  useEffect (() => {
    setShowDetail(false)
    loadData()
  },[state.lookback])

  useEffect (() =>{
    const parseResultSet = (data) => {
      const dataToOrder = data
      const sorted = dataToOrder.sort((a, b) => { 
          const multi = sortOrder === "asc" ? 1 : -1 
          if (sortField === 'key' && typeof b.key === 'string' ) {
            if (b.key && a.key){
              return  multi * a.key.localeCompare(b.key)
            } else {
              return 1
            }
          }else {
            return  ( b.Priority- a.Priority || multi *( a[sortField] - b[sortField])) 
          }
            //return multi * (a[sortField] - b[sortField]) 
      })
      const labelDimension = createLabelDimension()

      const tableHead = <tr>
        <th  role='button' onClick={() => sortFunction("key")} className='cell-left'>
          {labelDimension} &nbsp;
          {sortField === "key" ? 
            sortOrder === "asc" ? <FaArrowCircleUp /> : <FaArrowCircleDown />
            :<VscBlank />
          } 

        </th>
        <th role='button' onClick={() => sortFunction("total")} className='cell-right'>
          Revenue &nbsp;
          {sortField === "total" ? 
            sortOrder === "asc" ? <FaArrowCircleUp /> : <FaArrowCircleDown />
            :<VscBlank />
          } 
        </th>
        <th role='button' onClick={() => sortFunction("totalgrossprofit")} className='cell-right'>
          Gross Profit &nbsp;
          {sortField === "totalgrossprofit" ? 
                  sortOrder === "asc" ?  
                      <FaArrowCircleUp /> : <FaArrowCircleDown />
            :<VscBlank />
          }               
        </th>
        <th role='button' onClick={() => sortFunction("totalgrossmargin")} className='cell-right'>
          Gross Margin &nbsp;
          {sortField === "totalgrossmargin" ? 
                  sortOrder === "asc" ?  
                      <FaArrowCircleUp /> : <FaArrowCircleDown />
                      :<VscBlank />
          }               
        </th>
        <th role='button' onClick={() => sortFunction("totalnetprofit")} className='cell-right'>
          Net Profit &nbsp;
          {sortField === "totalnetprofit" ? 
                  sortOrder === "asc" ?  
                      <FaArrowCircleUp /> : <FaArrowCircleDown />
                      :<VscBlank />
          } 
        </th>
        <th role='button' onClick={() => sortFunction("totalnetmargin")} className='cell-right'>
          Net Margin &nbsp;
          {sortField === "totalnetmargin" ? 
                  sortOrder === "asc" ?  
                      <FaArrowCircleUp /> : <FaArrowCircleDown />
            :<VscBlank />

          }               
        </th>
        <th>Actions</th>

      </tr>

      const rows = sorted.map((k,j) => {

        const objClass = Object.keys(k).map((i) => {
          return k[i];
        });
      
        return <tr>
              <td className="cell-label" role='button' onClick={() =>handleDetail(j)}>
                { 
                dimension === 'TransactionType' ? useCases.description[objClass[0]] : 
                dimension === 'Quarter' ? dateUtilities.Quarters[timezoneOffset(objClass[0]).getUTCMonth()] + ' ' + new Date(objClass[0]).getFullYear() :
                objClass[0] !== null ? objClass[0] : 'N/A'  }
              </td>
              <td className="cell-value"> <FormattedValue type="money" value={Math.round(objClass[1])} /></td>
              <td className="cell-value"> <FormattedValue type="money" value={Math.round(objClass[2])} /></td>
              <td className="cell-value"> <FormattedValue type="percent" value={parseFloat(objClass[4])} /></td>
              <td className="cell-value"> <FormattedValue type="money" value={Math.round(objClass[3])} /></td>
              <td className="cell-value"> <FormattedValue type="percent" value={parseFloat(objClass[5])} /></td>
              <td className="cell-value cell-detail" role='button' onClick={() =>handleDetail(j)} > <FaMagnifyingGlassChart />  </td>
          </tr>
      })

      return {tableHead:tableHead,  tableBody:rows}
    }
    const dataParsed = (data.length >0) ? parseResultSet(data): null;
    setTableData(dataParsed)
  },[data, sortField, sortOrder])

  return <div>
            <div className='rounded-3 jumbotron'>
            <h2>{t('SECTION.PROFITANDLOSS.TITLE')}</h2>
        </div>

    <Card id="select" className="card_section">

      <Card.Header>
      <Form onSubmit={handleSubmit}>

        <div  className = "jumbotron-kpi">
          <div className = 'jumbotron-section-wrapper'>
            <div md = {7} className = "section-title-left">
            <Row className='align-items-end'>
            <Col>
              <div className = 'selectLeftItemContainer'>
                <FloatingLabel controlId = 'SelectTimeInterval' label = "Dimension" >

              <Form.Select id='SelectDimension' onChange={handleChangeDimension} value={dimension}>
                      <option value=''  disabled={true}>-</option>
                      <option value='CustomerId'>Customer (Top 20)</option>
                      <option value='customCustomer'>Customer (by ID)</option>
                      <option value='TransactionType'>Use Case</option>
                      <option value='CustomerCohort'>Cohort</option>
                      <option value='CtCardCountry'>Country</option>
                      <option value='Quarter'>Quarter</option>

              </Form.Select>
              </FloatingLabel>
              </div>
            </Col>
            <Col>
              <div className= {customSearch ? 'selectLeftItemContainer displayed': 'selectLeftItemContainer hideelement'} >
                  <FloatingLabel controlId = 'SelectTimeInterval' label = "Customer ID" >
                    <Form.Control  type="text" onChange={(e) => handleCustomerID(e.target.value)}/>
                  </FloatingLabel>
              </div>            
            </Col>
              </Row>
            </div>
            <div md = {5} className = 'section-title-right'>
                <Button class="btn btn-block btn-outline-primary mt-auto" type="submit">
                    View
                </Button>

            </div>
          </div>
        </div>
        </Form>
      </Card.Header>


      <Card.Body className='card-height-xs' style={{fontSize:'.85rem'}}>
        {tableData  ?
          <Table striped bordered>
            <thead> 
              {tableData.tableHead}
            </thead>
            <tbody>
              {tableData.tableBody}
            </tbody>
          </Table>
          :<></>
        }
      </Card.Body>

    </Card>
    <Card id="grossProfit" className={showDetail ? 'card_section  displayed': 'card_section hidden'}>

      <TitleCard label={t('SECTION.PROFITANDLOSS.DETAIL')+ cartTitle} 
        date_end={process.env.REACT_APP_HR_REFERENCE_DATE}
      />

      <Card.Body className='card-height-xs'>
          {detailOwner}
          <Row>
            <Col className='PandLDetailLeft PandLBold'>Revenue: </Col>
            <Col className='PandLDetailRight PandLBold'><FormattedValue type="money" value={detailSelected.total} /></Col>
          </Row>
          <hr/>
          <Row>
            <Col className='PandLDetailLeft'>Domain Registration:</Col>
            <Col className='PandLDetailRight'><FormattedValue type="money" value={Math.round(detailSelected.domainregistration)}/></Col>
          </Row>
          <Row>
            <Col className='PandLDetailLeft'>Payment Processor:</Col>
            <Col className='PandLDetailRight'><FormattedValue type="money" value={Math.round(detailSelected.paymentprocessor)}/></Col>
          </Row>
          <Row>
            <Col className='PandLDetailLeft'>Other COSS:</Col>
            <Col className='PandLDetailRight'><FormattedValue type="money" value={Math.round(detailSelected.othercoss)}/></Col>
          </Row>
          <Row>
            <Col className='PandLDetailLeft PandLBold'>Total COGS:</Col>
            <Col className='PandLDetailRight PandLBold'>
              <FormattedValue 
                type="money" 
                value={Math.round(detailSelected.domainregistration) + Math.round(detailSelected.paymentprocessor) + Math.round(detailSelected.othercoss)}/>
            </Col>
          </Row>
          <hr/>
          <Row>
            <Col className='PandLDetailLeft PandLBold'>Gross Profit:</Col>
            <Col className='PandLDetailRight PandLBold'><FormattedValue type="money" value={Math.round(detailSelected.totalgrossprofit)}/></Col>
          </Row>

          <hr/>
          <Row>
            <Col className='PandLDetailLeft'>Personnel - Engineering:</Col>
            <Col className='PandLDetailRight'><FormattedValue type="money" value={Math.round(detailSelected.personneleng)}/></Col>
          </Row>
          <Row>
            <Col className='PandLDetailLeft'>Personnel - Customer Support:</Col>
            <Col className='PandLDetailRight'><FormattedValue type="money" value={Math.round(detailSelected.personnelcustomersupport)}/></Col>
          </Row>
          <Row>
            <Col className='PandLDetailLeft'>Personnel - Management and Staff:</Col>
            <Col className='PandLDetailRight'><FormattedValue type="money" value={Math.round(detailSelected.personnelmanagement)}/></Col>
          </Row>

          <Row>
            <Col className='PandLDetailLeft'>Marketing:</Col>
            <Col className='PandLDetailRight'><FormattedValue type="money" value={Math.round(detailSelected.marketing)}/></Col>
          </Row>

          <Row>
            <Col className='PandLDetailLeft'>Information Technology:</Col>
            <Col className='PandLDetailRight'><FormattedValue type="money" value={Math.round(detailSelected.informationtechnology)}/></Col>
          </Row>

          <Row>
            <Col className='PandLDetailLeft'>Depreciation, Amortization, and Taxes:</Col>
            <Col className='PandLDetailRight'><FormattedValue type="money" value={Math.round(detailSelected.depreciationamortitationtaxes)}/></Col>
          </Row>
          <Row>
            <Col className='PandLDetailLeft PandLBold'>Total:</Col>
            <Col className='PandLDetailRight PandLBold'>
              <FormattedValue 
              type="money" 
              value={Math.round(detailSelected.personneleng) + Math.round(detailSelected.personnelcustomersupport) + Math.round(detailSelected.personnelmanagement) + Math.round(detailSelected.marketing) + Math.round(detailSelected.informationtechnology) + Math.round(detailSelected.depreciationamortitationtaxes)}/></Col>
          </Row>
          <hr/>
          <Row>
            <Col className='PandLDetailLeft PandLBold'>Net Profit:</Col>
            <Col className='PandLDetailRight PandLBold'><FormattedValue type="money" value={Math.round(detailSelected.totalnetprofit)}/></Col>
          </Row>

      </Card.Body>
      </Card>
</div>

}
