import { FormattedValue } from '../../common/FormattedValues/FormattedValues';
import {  Col } from 'react-bootstrap';
import './Graph.css'
export const LineChartTeamLegend  = ({tooltip}) =>{

  const valueRef = tooltip.value ? parseFloat(tooltip.value.replace(',','')) : 0
  return <div className='tooltip'  style={{ top: tooltip.top, left: tooltip.left, opacity: tooltip.opacity }}>
    <p className='tooltip-title'>{ tooltip.date }</p>
      <div className='tooltip-body'>
        <Col style={{float:'left'}}>Yearly Revenue per FTE:</Col>
        <Col style={{float:'right'}}><FormattedValue type="money"  value={valueRef} /></Col>
      </div>
      <div className='tooltip-body'>
        <Col style={{float:'left'}}>Yearly Revenue Projection:</Col>
        <Col style={{float:'right'}}><FormattedValue type="money"  value={tooltip.ref} /></Col>
      </div>
    </div>
  
}