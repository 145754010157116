import { Card } from 'react-bootstrap';

import { useState, useEffect } from 'react'
import { MemoHalfDoughnutKPI } from './Graph/HalfDoughnut'
import { useTranslation } from 'react-i18next'
import { QueryCubeRemote } from '../common/APICalls/apiCalls'

export const ConcentrationIndexWidget = ({queryToRender,without_border}) => {
    const { t } = useTranslation();
    const[data, setData] = useState([])
    
    const concentrationIndexDataArray =   QueryCubeRemote(queryToRender) 

    useEffect(() => {
        const parseResultSet = (concentrationIndexDataArray) =>{
            const concentrationIndex =Object.keys(concentrationIndexDataArray.tablePivot()[0]).map(function(key){
                let concentration = concentrationIndexDataArray.tablePivot()[0][key];
                return concentration
            })

            return [(concentrationIndex[0])?
                without_border ? 
                    <div className="chart-xs">
                        <MemoHalfDoughnutKPI value={concentrationIndex[0]} ascending={false} heightPx={'200px'}/>
                    </div>
                :
                    <Card className="card_kpi">
                        <Card.Title>{t('KPIs.TITLES.CONCENTRATION')}</Card.Title>
                        <Card.Body>
                            <div className="chart-xs">
                                <MemoHalfDoughnutKPI value={concentrationIndex[0]} ascending={false} heightPx={'200px'}/>
                            </div>
                        </Card.Body>
                    </Card>
            : 
            <>
            </>
            ]
        }
        const dataParsed =[(concentrationIndexDataArray)? parseResultSet(concentrationIndexDataArray): []]
        setData(dataParsed) 
    },[concentrationIndexDataArray,t])
    return <><span>&nbsp;</span>{data}</>

}
