// Application Import
import React from 'react'

//Hooks Import
import {useState, useEffect, useRef} from 'react';

// CSS Import
import './Graph.css'

// Graph Type Import
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { generateUUID } from '../../utilities/ui_tools';

// Chart Configuration Options for Positive values
const options = {
  legend: {
    display: false,
  },
  maintainAspectRatio:false,
  layout: {
      padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
      }
  },

  elements: {
    arc: {
      borderWidth: 0
    }
  },
  animation: true,
  cutout: '75%',
  rotation: -130.0, // start angle in radians
  circumference: 260.0, // sweep angle in radians
  plugins: {
    tooltip: {
      enabled: false  // disable show label on hover
    }
  },
  hover: false  // disable animation on hover
}
// Chart Configuration Option for Negatives values
const options_neg = {
  legend: {
    display: false,
    position: "right"
  },
  maintainAspectRatio:false,
  layout: {
      padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
      }
  },

  elements: {
    arc: {
      borderWidth: 0
    }
  },
  animation: true,
  cutout: '75%',
  rotation: -310.0, // start angle in radians
  circumference: 260.0, // sweep angle in radians
  plugins: {
    tooltip: {
      enabled: false  // disable show label on hover
    }
  },
  hover: false  // disable animation on hover
}


// The function calculate the sign and the trend of the value to be rendered 
const calculateValues =(inputValue) =>{
  return (inputValue < 0) ? [100 - (inputValue * -1 ), inputValue * - 1] : [inputValue, 100 - inputValue ]
}

// The function determine the color palette that must be rendered. 
const gradients = (inputValue, ascending,negative)=> {

  if (negative) {
    return [['#dadada', '#ED2938'],['rgba(237,41,56,0.6)', '#ED2938']]
  } else {
    if (ascending){
      if ( inputValue <= 20) {
        return [['rgba(237,41,56,0.4)','#dadada'],['#ED2938','rgba(218,218,218,0.1)']]
      } else if (inputValue > 20 && inputValue < 50){
        return [['rgba(255,206,16,0.4)','#dadada'],['#FFCE10','rgba(218,218,218,0.1)']]
      } else {
        return [['rgba(107,189,0,0.4)','#dadada'],['#6bbd00','rgba(218,218,218,0.1)']]
      } 
    } else {
      if (inputValue > 0 && inputValue < 50) {
        return [['rgba(107,189,0,0.4)','#dadada'],['#6bbd00','rgba(218,218,218,0.1)']]
      } else if (inputValue >= 50 && inputValue < 75){
        return [['rgba(255,206,16,0.4)','#dadada'],['#FFCE10','rgba(218,218,218,0.1)']]
      } else {
        return [['rgba(237,41,56,0.4)','#dadada'],['#ED2938','rgba(218,218,218,0.1)']]
      } 
  
    }
  }
}


const fontGradients = (inputValue, ascending,negative)=> {

  if (negative) {
    return ['#ED2938']
  } else {
    if (ascending){
      if ( inputValue <= 20) {
        return ['#ED2938']
      } else if (inputValue > 20 && inputValue < 50){
        return ['#FFCE10']
      } else {
        return ['#6bbd00']
      } 
    } else {
      if (inputValue > 0 && inputValue < 50) {
        return ['#6bbd00']
      } else if (inputValue >= 50 && inputValue < 75){
        return ['#FFCE10']
      } else {
        return ['#ED2938']
      } 
  
    }
  }
}

// Return color gradient to be used in grphs that indicates a % variation
const gradientsRef = (ascending,negative) => {

  //If the value is a negative change is returned only red
  if (negative){
    return ['rgba(237,41,56,0.2)']
  } else {
    //Else the green yellow red scale is returned in straight or reverse order    
    if(ascending) {
      return ['rgba(237,41,56,0.2)', 'rgba(255,206,16,0.2)', 'rgba(107,189,0,0.2)']
    } else {
      return ['rgba(107,189,0,0.2)', 'rgba(255,206,16,0.2)', 'rgba(237,41,56,0.2)']
    }
  }
}

/**
 * 
 * @param {*} value            Dataset to be rendered 
 * @param {*} ascending        Label for graph series
 * @param {*} ascending        Height in pixel for graph box
 * @param {boolean} signed     Boolean. TRUE if positive values must show sign. 
 * @returns Rendered Doughnuth Graph inside JSX component
 * This Graph is used for all doughnugth grphas where total is 100%
 * 
*/
export const HalfDoughnutKPI =({value,ascending,heightPx,signed}) => {

  const [data,setData] = useState([<></>])    //Content to be rendered
  const [received, setReceived] = useState(0)
  const chartRef = useRef();


  //useEffect Hook is triggered when one of the function parameter change his status.
  useEffect(()=>{

    //Existing chart is destroyed 
    if (chartRef?.current) {
      chartRef.current.destroy();
    }

    // Determine the colors, values and chart background 
    const colors = gradients(value,ascending,(value < 0 ? true : false))
    const serieValue = calculateValues(value)
    const backgroundRef = gradientsRef(ascending,(value < 0 ? true : false))
    
    setReceived(value)

    //The graph dataset is initialized
    const datasets = []

    // Build the graph dataset
    datasets.push({
      label: 'Dataset',
      data: serieValue,
      backgroundColor:colors[0],
      borderColor: 'white',
      borderWidth: 1
    })

    datasets.push({
        data: (value < 0 ? [100] : ascending? [20,30,50] : [50, 25, 25]) ,
        backgroundColor: backgroundRef,
        borderWidth: 1,
        borderColor: 'white',
        cutout: '75%',
        weight: 0.5
      })


    const renderValues = ({
      labels: [],
      datasets: datasets
    
    })

    //Define graph plugin to draw text at the center
    let plugins = ([{
      afterDraw: (chart) => {
        let width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
            ctx.restore();
        let fontSize = (height / 120).toFixed(2);
            ctx.font = "bold " + fontSize + "rem Nunito";
            ctx.textBaseline = "bottom";
        let text = ((signed && value >0 ) ? '+':'') + parseFloat(value).toFixed(2) + '%',
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height/1.5;

        ctx.fillStyle = fontGradients(value,ascending,(value < 0 ? true : false))
        ctx.fillText(text, textX, textY);
        ctx.save();
      } 
    }])
        
    //The graph is created and assigned to Data state
    setData([<>
      {received !== 0  ?
        <Doughnut 
        ref={chartRef}
        key={generateUUID(16)}
        // height={heightPx}
        options={value > 0 ?options : options_neg}
            data={renderValues} 
            plugins={plugins} 
            redraw/>
        :
        <></>
      }
    </>])
  

  },[value,ascending,heightPx,signed,received])

  // This return redendered  
 return <div key={generateUUID(16)}>{data}</div>
}

export const MemoHalfDoughnutKPI  = React.memo(HalfDoughnutKPI) 