import { useCases } from "./use_cases";


export const total_amount_by_events_bottomup_v15 = (compareDateRange,kpi,granularity,dimensions) =>{

  const query =  {
    "measures": [
      "MnEvents." + kpi
    ],
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "compareDateRange": compareDateRange
      }
    ],
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.revenue
        
      }
    ],
  }
  if (dimensions) {
    query.dimensions = dimensions
  }

  if (granularity) {
    query.timeDimensions[0].granularity = granularity
  }
  return query
}
