import {useState, useEffect} from 'react'
import {useCubeQuery} from '@cubejs-client/react'

/** 
* 
* General function to perform query on BE
* @param {*} queryRender Json Query Parameters will be passed to Back End 
* Return:    @data JSON Object 
*   
* */  

export const QueryCubeRemote = (queryRender) =>{

    const[data, setData] = useState()     //returned data
    const {resultSet:CubeQuery} = useCubeQuery(queryRender)   //query handler 

    //When the CubeQuery change his state the data state is updated and returned to caller
    useEffect(() =>{
        const dataParsed = CubeQuery ? CubeQuery:null
        setData(dataParsed)
    },[CubeQuery])
    
    return data
} 