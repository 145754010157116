import { useState, useEffect, useContext } from 'react';
import { Context } from '../App';
import { FormattedValue } from '../common/FormattedValues/FormattedValues';
import { CustomersTotal } from '../logic/CalculatedValues';
import { HalfDoughnutKPI } from './Graph/HalfDoughnut';
import { useTranslation } from 'react-i18next';
import { referencePlusOneDay } from '../utilities/date_formatting';

export const CustomersCountWidget = () => {
    const { t } = useTranslation();

    const [data, setData] = useState({ prevCustomers: 0, changeCustomers: 0 })
    const [state] = useContext(Context)

    const customers = CustomersTotal(
        [
          state.date_range.split(';')[1].slice(0,-2) + '01',
        ],
        'quarter',
        state.lookback
    )

    useEffect(() => {
        if (!customers) {
            return
        }
        const changeCustomers = ((customers.prev - customers.prior) / customers.prior) * 100
        const dataParsed = { prevCustomers: customers.prev, changeCustomers: changeCustomers, priorCustomers: customers.prior}
        setData(dataParsed)

    }, [customers]);

    return <>
        {data ? <>
            <span>{t('KPIs.LABELS_AND_FOOTERS.NUMBER_COUNT')} </span>
            <FormattedValue type="absolut" value={data.prevCustomers} />
            <div className="chart-xs">
                <HalfDoughnutKPI value={data.changeCustomers} ascending={true} heightPx={'200px'} signed={true} />
            </div>
        </>
            :
            <></>
        }
    </>
}