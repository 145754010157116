import React from 'react'
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getApiAdministration } from "../../common/APICalls/apiAdministration"

/**
 * Footer component displayed across the application.
 * @returns HTML component for the Footer
 */
export const Footer = () => {

    const { t } = useTranslation()        //String Translation function.
    const [version, setVersion] = useState({be_version:'-', fe_version:'-' })

    useEffect (() =>{
      getApiAdministration('version').then((response) =>{
        if (response ){
            setVersion(response)
        }    
      })  
    },[])

return <div md={11}>
        <div className='footer-phantom' />
          <div className='footer-style'>
            <div id='footer' className="footer">
                {t('FOOTER.FRONTEND')}  {version.fe_version} | {t('FOOTER.BACKEND')} {version.be_version} | {t('FOOTER.COPY')} {(new Date().getUTCFullYear())} {t('FOOTER.MOTUNOVU')}<br/>
                Note: Business Intelligence figures and Financial Accounting figures may differ.  
            </div>
          </div>
      </div>
}
