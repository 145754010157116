import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

import { getVersionCheck } from '../../../common/APICalls/apiAdministration';
import { Tab, Tabs } from 'react-bootstrap'

import { AdminTabSetUp } from '../../Sections/Admin_Tab_Setup';
import { AdminTabEditing } from '../../Sections/Admin_Tab_Editing';
import { ExecutionComponent } from '../../ExecutionComponent/ExecutionComponent';
export const AdministrationPage = () => {
    const { t } = useTranslation() // Define component to use i18n internationalitation strings

    let location = useLocation()    // The variable has information about the url. 
                                    // useLocation hook to obtain from internal router the URL object and his components.
    const version = getVersionCheck()
    return <>
        <div className='rounded-3 jumbotron'>
            <h2>{t('SECTION.ADMINISTRATION.PAGE_TITLE')}</h2>
        </div>
        <div style={{marginTop:'10px', paddingTop:'10px'}}>
        <Tabs  defaultActiveKey="fte_list" id="personel" className="mb-3">
            <Tab eventKey="fte_list" title={t('SECTION.ADMINISTRATION.TAB_SETUP')}>
                <AdminTabSetUp />
            </Tab>
            <Tab eventKey="coa_list" title={t('SECTION.ADMINISTRATION.TAB_EDITING')}>
                <AdminTabEditing />
            </Tab>
            <Tab eventKey="cam_execution" title={t('SECTION.ADMINISTRATION.TAB_RUNCAM')}>
                <ExecutionComponent title={'CAM'} groupsEndpoint={'groups'} executionEndpoint={'cam/execution'}  batch={'cam/batchexecution'}/>
            </Tab>
            <Tab eventKey="kpi_execution" title={t('SECTION.ADMINISTRATION.TAB_RUNKPI')}>

                <ExecutionComponent title='KPI' groupsEndpoint={'kpi/areas'} executionEndpoint={'kpi/calculation'} batch={'kpi/batchexecution'}/>
            </Tab>
        </Tabs>
        </div>
    </>                            
}
