import React from 'react';
import {useState, useEffect} from 'react';
import {useCubeQuery} from '@cubejs-client/react';
import { Tab, Tabs } from 'react-bootstrap'
import { generateUUID } from '../utilities/ui_tools';

import Table from 'react-bootstrap/Table'
import {LineChartKPI} from '../components/Graph/LineChart'
import { BarChartRevenue } from './Graph/Istogram';
import { useTranslation } from 'react-i18next';
import { ValueLayoutComponent } from '../utilities/ui_tools';

// utilities
import { capitalizeSentence } from '../utilities/stringUtility'; 
import { FormatCustomerKPIs } from '../utilities/dataSeriesParsing';

export const  KPIMonthlySingleQuery = ({
  queryTrend, 
  type, 
  keyStorage,
  granularity, 
  Yaxis, 
  reduce, 
  istogram}) =>{
    const { t } = useTranslation()

    const cachedData = JSON.parse(sessionStorage.getItem(keyStorage))

    const[data, setData] =useState({
      trendData: null
})

    const {resultSet:valueMonth} = useCubeQuery(queryTrend);

    useEffect(()=>{
      const parseResultSet = () =>{
        setData({
          trendData: parseValueMonth(),
        })
      }
      const dataParsedCohort = (valueMonth ) ?  parseResultSet() : null
    },[valueMonth])

    const parseValueMonth  = () => {
       const parseResultSet = (valueMonth) =>{
        const pivotedResult = valueMonth.tablePivot({
            fillMissingDates:true,
            x:["MnEvents.timestamp"],
            y:["MnEvents.useCase","measures"]
        })
        return FormatCustomerKPIs({pivotedResult,type, granularity})

      };
      const dataParsed = (valueMonth)? parseResultSet(valueMonth): null;
      return dataParsed
      //sessionStorage.setItem(keyStorage,JSON.stringify(dataParsed))

    }

    //,type, keyStorage, state
    const avgPrev =data.trendData ? (type === '2' ? ((data.trendData.totalPrevious/100)) :(data.trendData.totalPrevious/data.trendData.serieLenght)) :0;
    const avgPrior = data.trendData ? (type === '2' ? ((data.trendData.totalPrior/100)) :(data.trendData.totalPrior/data.trendData.serieLenght)) :0;
    const avgPriorYear = data.trendData ? (type === '2' ? ((data.trendData.totalPriorYear/100)) :(data.trendData.totalPriorYear/data.trendData.serieLenght)) :0;
  
    const previousAvgLayout = (<ValueLayoutComponent typeLayout={type} valueRender={avgPrev} /> )
    const priorAvgLayout = (<ValueLayoutComponent typeLayout={type} valueRender={avgPrior} /> )
    const priorYearAvgLayout = (<ValueLayoutComponent typeLayout={type} valueRender={avgPriorYear} /> )

    return <>
        {data.trendData  
            ?<>
            <Tabs  defaultActiveKey="graph" id="uncontrolled-tab-example" className="mb-3">
            <Tab key={generateUUID(16)} eventKey="graph" title={t('TABS.GRAPH')}>
                  {istogram === true ?
                    <BarChartRevenue 
                      key={generateUUID(16)}
                      formatLabel={true} 
                      colorMono={true} 
                      dataseries={data.trendData.dataChart} 
                      title="" 
                      Yaxis="$" 
                      fitlerable={false}/> :
                    <LineChartKPI 
                      key={generateUUID(16)}
                      formatLabel={true} 
                      dataSeries={data.trendData.dataChart} 
                      title="" 
                      Yaxis={Yaxis} 
                      granularity={granularity}  
                      filterable={false}
                      filterSeries = {data.linesTopCustomers}
                      />

                  }
                </Tab>
                 
                <Tab eventKey="table" title={t('TABS.TABLE')}>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th className="cell-label">{capitalizeSentence(granularity)}</th>
                                <th className="cell-value">{capitalizeSentence('# Customers')}</th>
                                <th className="cell-label">{capitalizeSentence('Prior ' +  granularity)}</th>
                                <th className="cell-value">{capitalizeSentence('# Customers')}</th>
                                <th className="cell-value">{capitalizeSentence('Difference %')}</th>
                                <th className="cell-label">{capitalizeSentence('Prior Year')}</th>
                                <th className="cell-value">{capitalizeSentence('# Customers')}</th>
                                <th className="cell-value">{capitalizeSentence('Difference %')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.trendData.tableData}
                            <tr className='row-summary'>
                                <td className="cell-label">{type === '0' ? t('TABLES.END_ROW.AVG_PERIOD') : t('TABLES.END_ROW.TOTAL')}</td>
                                {previousAvgLayout}
                                <td></td>
                                {priorAvgLayout}                
                                <td className="cell-value">

                                </td>
                                <td></td>
                                {priorYearAvgLayout}
                                </tr>
                        </tbody>
                
                    </Table> 
                </Tab>
                </Tabs>
            </>
            : 
            <div>Wait...</div>
        }
        </>;
};
