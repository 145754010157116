export const concetrationIndex =(date) => {
  return {
    "measures": [
      "Ranking.gini"
    ],
    "timeDimensions": [
      {
        "dimension": "Ranking.created_at",
        "dateRange": date
      }
    ],
    "order": {
    },
    "filters": [
    ]
  }
}

export const concentrationIndexStoreCredit = (date) => {
  return {
    "measures": [
      "RankingStoreCredit.gini"
    ],
    "timeDimensions": [
      {
        "dimension": "RankingStoreCredit.created_at",
        "dateRange": date
      }
    ],

  }
}

export const concetrationIndexSelfService =(date) => {
  return {
    "measures": [
      "RankingSelfService.gini"
    ],
    "timeDimensions": [
      {
        "dimension": "RankingSelfService.created_at",
        "dateRange": date
      }
    ],
    "order": {
    },
    "filters": [
    ]
  }
}


export const concetrationIndexGrouped =(date) => {
  return {
    "measures": [
      "Ranking_Cohort.gini"
    ],
    "timeDimensions": [
      {
        "dimension": "Ranking_Cohort.created_at",
        "dateRange": date
      }
    ],
    "order": {
      "Ranking_Cohort.cohort": "desc"
    },
    "filters": [
    ],
    "dimensions": [
      "Ranking_Cohort.cohort"
    ]
  }
}
export const concetrationIndexStoreCreditGrouped =(date) => {
  return {
    "measures": [
      "RankingStoreCreditCohort.gini"
    ],
    "timeDimensions": [
      {
        "dimension": "RankingStoreCreditCohort.created_at",
        "dateRange": date
      }
    ],
    "order": {
      "RankingStoreCreditCohort.cohort": "desc"
    },
    "filters": [
    ],
    "dimensions": [
      "RankingStoreCreditCohort.cohort"
    ]
  }
}