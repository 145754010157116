import { useState, useEffect, useContext } from 'react';
import { ChurnRateSimple } from '../logic/CalculatedValues';
import { MemoHalfDoughnutKPI } from './Graph/HalfDoughnut';
import { FormattedValue } from '../common/FormattedValues/FormattedValues';
import { useTranslation } from 'react-i18next';
import { Context } from '../App';
import { referencePlusOneDay } from '../utilities/date_formatting';

export const ChurnRateWidget = () => {
    const { t } = useTranslation();

    const [data, setData] = useState({ churn: 0, lost: 0 })
    const [state] = useContext(Context)

    const churnRate = ChurnRateSimple(
        [state.date_range.split(';')[1].slice(0,-2) + '01'],
        'month',
        state.lookback
    )


    useEffect(() => {
        if (!churnRate) {
            return
        }
        setData(churnRate)
    }, [churnRate, t]);

    return <>
        {data ? <>
            {t('KPIs.LABELS_AND_FOOTERS.LOST')}<FormattedValue type="absolut" value={data.lost} />
            <div className="chart-xs">
                <MemoHalfDoughnutKPI value={data.churn} ascending={false} heightPx={'200px'} />
            </div>
        </>
            : <></>
        }
    </>
};

export const ChurnRateWidgetNoLabel = () => {
  const { t } = useTranslation();

  const [data, setData] = useState({ churn: 0, lost: 0 })
  const [state] = useContext(Context)

  const churnRate = ChurnRateSimple(
      [state.date_range.split(';')[1].slice(0,-2) + '01'],
      'month',
      state.lookback
  )


  useEffect(() => {
      if (!churnRate) {
          return
      }
      setData(churnRate)
  }, [churnRate, t]);

  return <>
      {data ? <>
          <div className="chart-xs">
              <MemoHalfDoughnutKPI value={data.churn} ascending={false} heightPx={'200px'} />
          </div>
      </>
          : <></>
      }
  </>
};