// Application Import
import { Card } from 'react-bootstrap'
import { FormattedValue } from '../common/FormattedValues/FormattedValues'

// Return a color palette 
export const palette = ({colorMono,storeKey}) => {
  if (storeKey && ['Customer_Government','Customer_Embargoed','Customer_Politically_Exposed'].includes(storeKey)){
    return ['#2A9270','#f6b02d','#890725']
  }
  return colorMono ?  ["#33658a"] : ["#33658a","#86bbd8","#5b8298","#2f4858","#f6ae2d","#f26419","#D26E3F","#da2c38","#b17864","#C2967A","#87c38f","#D3B490","#f4f0bb"]
}

// Component used to dispaly a squared legend bulled isntead default cricoular
export const SquareLegend = ({indexColor, keyStorage}) => {
    const colorPalette = palette({colorMono: false, storeKey:keyStorage})
    const color = colorPalette[indexColor % colorPalette.length]
    return <div key = {indexColor} style = {{ backgroundColor:color }} className = "squareLegend"/>
}

/**  Component to display a common card header across the application . 
* @param {*} label Card Title to show
* @param {*} filter_componenet Lookback , TIme Interval and Granularity filters control rendered. If not defined the control is not showed
*/
export const TitleCard = ({label,filter_component,grouping_component = null}) =>{
  return <Card.Header>
    <div  className = "jumbotron-kpi">
      <div className = 'jumbotron-section-wrapper'>
        <div md = {7} className = "section-title-left">
          <h5 className = 'text-custom-primary'><span style={{float:'left'}}>{label}</span></h5>
        </div>
        <div md = {5} className = 'section-title-right'>
        {grouping_component ? grouping_component : <></>}
          {filter_component ? filter_component : <></>}
        </div>
      </div>
    </div>
  </Card.Header>
}

/**  Component to display a common section header across the application . 
* @param {*} label Section Title to show
* @param {*} filter_componenet Lookback , TIme Interval and Granularity filters control rendered. If not defined the control is not showed
*/
export const TitleSection = ({label, filter_component}) =>{
    return <div md = {7}className = 'jumbotron-section'>
        <div className = 'jumbotron-section-wrapper'>
          <div className = "section-title-left"><h2>{label}</h2></div>
          <div md = {5} className = 'section-title-right'>
            {filter_component ? filter_component : <></>}
          </div>
        </div>
      </div>

}

export const  ValueLayoutComponent = ({valueRender, typeLayout}) => {
  if (typeLayout === '2')
      return <td key={generateUUID(16)} className="cell-value" >
          <FormattedValue type="money" value={valueRender} />
      </td>
  else if ( typeLayout === 'percent')
    return <td key={generateUUID(16)} className="cell-value" >
        <FormattedValue type="percent" value={valueRender} />
      </td>

  else
      return <td key={generateUUID(16)} className="cell-value" >
          <FormattedValue type="absolut" value={valueRender} />
      </td>

}


export function generateUUID(digits) {
  let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXZ';
  let uuid = [];
  for (let i = 0; i < digits; i++) {
    uuid.push(str[Math.floor(Math.random() * str.length)]);
  }
  return uuid.join('');
}

export const timezoneOffset = (item) => {
  const d = new Date(item)
  if (d.getTimezoneOffset() < 0){
    d.setUTCDate(d.getUTCDate()+1 );
  } else {
    d.setUTCDate(d.getUTCDate() + 1 );
  }

  return d
}