
import React from 'react';
// Hooks Import
import { useState, useEffect, useContext } from 'react';

import { dateUtilities, weekNumber } from '../../utilities/date_formatting'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Line } from 'react-chartjs-2';
import {  Form, Col, Row, Card } from 'react-bootstrap'

import trendlineLinear from 'chartjs-plugin-trendline'

import { LineChartTeamLegend } from './LineChartTeamLegend'
import { useCases } from '../../kpi_queries/use_cases'
import { Context } from '../../App';
import { generateUUID } from '../../utilities/ui_tools';

const colorArray = ['rgba(0, 63, 92,1)', 'rgba(188, 80, 144,1', 'rgba(88, 80, 141,1)']
const bgColorArray = ['rgba(0, 63, 92,.4)', 'rgba(188, 80, 144,.4', 'rgba(88, 80, 141,.4)']
const colorMultiLine = ["#33658a", "#86bbd8", "#5b8298", "#2f4858", "#f6ae2d", "#f26419", "#D26E3F", "#da2c38", "#b17864", "#C2967A", "#87c38f", "#D3B490", "#f4f0bb"]


/**
 * Is
 * 
 * @param {*} param0 
 * @returns 
 * 
 */
const BaseMultiLineChartKPI = ({ dataSeries, kindOfGroup, granularity,Yaxis, filterable=false }) => {
  const [state, setState] = useState()
  const multiLinesOptions = {
    scales: {
      x: {
        title: {
          display:true,
          text: 'Period'
        },
        ticks: {
          autoSkip: false,
          maxRotation: 45,
          minRotation: 45
        },
        border: {
          color: '#0C3761',
          width: 1
        },
      },
      y: {
        stacked: true,
        border: {
          color: '#0C3761',
          width: 1
        },
        grid: {
          color: function(context) {
            if (context.tick.value === 0) {
              return '#0C3761';
            } 

            return '#ddd';            
          },
          lineWidth: function(context) {
            if (context.tick.value === 0){
              return 1
            }
            return 1
          }
        },
        afterTickToLabelConversion: function( scaleInstance ){
          scaleInstance.ticks.pop();
          scaleInstance.ticks.shift();
        }

      },
  
    },
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        display: true,
        position: 'right'
      },
      tooltip: {
        enabled: false  // disable show label on hover
      },
    },
    hover: false,
  
  }
  

  ChartJS.register(ArcElement, Tooltip, Legend);

  if (Yaxis === "$"){
    multiLinesOptions.scales.y.ticks = {
      // Include a dollar sign in the ticks
      callback: function(value, index, ticks) {
        return  value >= 0 ?  "$"+ value.toLocaleString() : "-$" + Math.abs(value).toLocaleString();
      }
    }

    multiLinesOptions.scales.y.title={
      display: true,
      text:'US Dollars'
    }

  } else if (Yaxis === "%") {
    multiLinesOptions.scales.y.ticks = {
      // Include a dollar sign in the ticks
      callback: function(value, index, ticks) {
          return  value.toFixed(2) +" %";
      }
    }
    multiLinesOptions.scales.y.title={
      display: true,
      text:'Percentage'
    }
  } else {
    multiLinesOptions.scales.y.title={
      display: true,
      text:'Customers'
    }
  }

  useEffect(() => {
    if (dataSeries) {
      let chartDataset = dataSeries.series().map((s, index) => ({
        label: kindOfGroup === 'cohort' ? s.title.split(',')[0] === '[Empty string]' ? 'N/A' : s.title.split(',')[0] : useCases.description_short[s.title.split(',')[0]],
        data: s.series.map((r) => (r.value / 100)),
        borderColor: colorMultiLine[index],
        backgroundColor: colorMultiLine[index],
        yValues: [s.key],
        fill: true
      }))
      const finalData = {
        labels: dataSeries.categories().map((c) => {
          const dateEvent = new Date(c.x)
          switch (granularity) {
            case 'quarter':
              return dateUtilities.Quarters[dateEvent.getUTCMonth()] + ' ' + (dateEvent.getUTCFullYear()).toString()
            case 'month':
              return dateUtilities.monthsNamesShort[dateEvent.getUTCMonth()] + ' ' + (dateEvent.getUTCFullYear()).toString()
            case 'week':
              return weekNumber(dateEvent)[1] + 'th ' + (dateEvent.getUTCFullYear()).toString()
            case 'day':
              return dateEvent.getUTCDate() + ' th ' + dateUtilities.monthsNamesShort[dateEvent.getUTCMonth()] + ' ' + dateEvent.getUTCFullYear().toString()
            default:
              return '';
          }

        }),
        datasets: chartDataset,
      }
      minmaxMultiLine(chartDataset)
      setState(finalData)
    }
  }, [dataSeries, granularity, kindOfGroup])

  const minmaxMultiLine = (chartDataset) =>{
    const series = chartDataset.map((chartSerie) => chartSerie.data)
    const sum = (r, a) => r.map((b, i) => a[i] + b);
    //setMaxGraph(Math.max(...series.reduce(sum))) Commented due investigation for better result
  }
  return (<>
    {(state) ?
      <Line
        height={'500px'}
        width={'400px'}
        type="line"
        data={state}
        options={multiLinesOptions}
      />
      :
      <></>
    }
  </>)

}


const BaseLineChartKPI = ({dataSeries, title, tooltipShow, references, granularity, Yaxis, formatLabel, filterable, filterSeries }) => {

  const [appContext] = useContext(Context)
  const [state, setState] = useState()
  const [minValue, setMinValue] = useState(0)
  const [listExclusionCheck , setListExclusionCheck] = useState()
  const [excluded, setExcluded ] = useState([])
  const [renderedSerie, setRenderedSerie] = useState()


  const handleOnCheckboxClick = (value) => {
    const currentExcluded = excluded
    if (currentExcluded.includes(value)){
      const index = currentExcluded.indexOf(value)
      currentExcluded.splice(index,1)
    } else {
      currentExcluded.push(value)
    }
    const mappedValues = filterSeries.series().map((s, index) => ({
      data: s.series.map((r) => (r.value/100 )),
      customer: s.title.split(',')[0],
    }))

    if (mappedValues){
      const removableSerie = mappedValues.filter((element) => {
        const test =currentExcluded.includes(parseInt(element.customer))
        return test ? element : null
      }) 

    const referenceSeries = JSON.parse(JSON.stringify(dataSeries))

     removableSerie.forEach((reference) => {
      reference.data.forEach((datapoint, index) => {
        referenceSeries[0].data[index] = referenceSeries[0].data[index] - datapoint
      })
     })

     setRenderedSerie(referenceSeries)
    }

    setExcluded(currentExcluded)
    filterListBuilder()
  }

  const filterListBuilder = () => {
    if (appContext.KPIs_Exclusion_List){
      setListExclusionCheck(appContext.KPIs_Exclusion_List.map(item => {
        return (
          <Form.Check
           type='switch'
          checked={!excluded.includes(item)}
          key={generateUUID(16)} 
          onChange={()=>handleOnCheckboxClick(item)}
          label={item}
          />
        );
      }))
    }
  }

  const [tooltip, setTooltip] = useState({
    opacity: 0,
    top: 0,
    left: 0,
    date: '',
    value: '',
    reference: '',
  }) //initial tooltip state

  const singleLineOptions = {
    scaleShowValues: true,
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 45,
          minRotation: 45
        },
        title: {
          display: true,
          text: 'Period'
        },
        border: {
          color: '#0C3761',
          width: 1
        }
  
      },
      y: {
        stacked: true,
        min: renderedSerie ? (Math.min.apply(null, renderedSerie[0].data) * 0.95) : 0,
        max: renderedSerie ? (Math.max.apply(null, renderedSerie[0].data) * 1.05) : 0,
        border: {
          color: '#0C3761',
          width: 1
        },
        grid: {
          color: function(context) {
            if (context.tick.value === 0) {
              return '#0C3761';
            } 

            return '#ddd';            
          },
          lineWidth: function(context) {
            if (context.tick.value === 0){
              return 1
            }
            return 1
          }
        },
        afterTickToLabelConversion: function( scaleInstance ){
          scaleInstance.ticks.pop();
          scaleInstance.ticks.shift();
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,  // disable show label on hover
        external: tooltipShow ? context => {
          const tooltipModel = context.tooltip
          //if (!chart || !chart.current) return

          if (tooltipModel.opacity === 0) {
            if (tooltip.opacity !== 0) setTooltip(prev => ({ ...prev, opacity: 0 }))
            return
          }
          const indexLabel = state.labels.indexOf(tooltipModel.dataPoints[0].label)
          const position = context.chart.canvas.getBoundingClientRect()
          const newTooltipData = {
            opacity: 1,
            left: 50 + tooltipModel.caretX,
            top: 120 + tooltipModel.caretY,
            date: tooltipModel.dataPoints[0].label,
            ref: references[2][indexLabel],
            value: tooltipModel.dataPoints[0].formattedValue,
          }
          if (tooltip !== newTooltipData) setTooltip(newTooltipData)
        } : null
      },
    },
    hover: false,

  };

  if (Yaxis === "$"){
    singleLineOptions.scales.y.ticks = {
      // Include a dollar sign in the ticks
      callback: function(value, index, ticks) {
          
        return  value >= 0 ?  "$"+ value.toLocaleString() : "-$" + Math.abs(value).toLocaleString();
      }
    }

    singleLineOptions.scales.y.title={
      display: true,
      text:'US Dollars'
    }

  } else if (Yaxis === "%") {
    singleLineOptions.scales.y.ticks = {
      // Include a dollar sign in the ticks
      callback: function(value, index, ticks) {
          return  value.toFixed(2) +" %";
      }
    }
    singleLineOptions.scales.y.title={
      display: true,
      text:'Percentage'
    }
  } else {
    singleLineOptions.scales.y.title={
      display: true,
      text:'Customers'
    }
  }
  useEffect(() => {
    filterListBuilder()
  },[appContext])

  useEffect(() => {
    if (typeof renderedSerie === 'undefined' && typeof dataSeries !== 'undefined') {
      setRenderedSerie(dataSeries)
    }
  }, [dataSeries])

  useEffect(() => {
    if (typeof renderedSerie != 'undefined'){
      let chartDataset = []
      renderedSerie.forEach((element, index) => {
        chartDataset.push({
          label: element.label,
          fill: true,
          animation: true,
          lineTension: 0,
          borderColor: colorArray[index],
          tension: 0,
          backgroundColor: bgColorArray[index],
          borderWidth: 2,
          data: element.data,
          pointRadius: 0,
          trendlineLinear: {
            style: '#007bff',
            lineStyle: "dotted",
            width: 3
          }
        })
      })

      const labelsFormatted = renderedSerie[0].labels.map((c) => {
        const dateEvent = new Date(c)
        if (dateEvent.getTimezoneOffset() < 0){
          dateEvent.setUTCDate(dateEvent.getUTCDate() + 1);
        } else {
          dateEvent.setUTCDate(dateEvent.getUTCDate() + 1);
        }

        switch (granularity) {
          case 'quarter':
            if (formatLabel) {
              const month = c.split(' ')[0];
              return dateUtilities.Quarters[dateUtilities.monthsNamesShort.indexOf(month)] + ' ' + c.split(' ')[1]
            } else {
              return c
            }
          case 'week':
            return weekNumber(dateEvent)[1] + 'th ' + (dateEvent.getUTCFullYear()).toString()
          case 'day':
            return dateEvent.getUTCDate() + ' th ' + dateUtilities.monthsNamesShort[dateEvent.getUTCMonth()] + ' ' + dateEvent.getUTCFullYear().toString()
          default:
            return dateUtilities.monthsNamesShort[dateEvent.getUTCMonth()] + ' ' + (dateEvent.getUTCFullYear()).toString()

          }
        })

      setMinValue(Math.min(...renderedSerie[0].data) < 0 ? Math.min(renderedSerie[0].data) : 0)

      setState({
        labels: labelsFormatted,        
        datasets: chartDataset
      })
    }
  }, [renderedSerie])

  return (<>
    {(state) ?
      <Row>        
        <Col sm={filterable === true ? 10:12} className='tabLayout'>
          <Line
            redraw={false}
            height={'500px'}
            width={'400px'}
            type="line"
            data={state}
            options={singleLineOptions}
            plugins={[trendlineLinear]}
          />
          {tooltipShow ? <LineChartTeamLegend tooltip={tooltip} /> : <></>}
        </Col>
        <Col sm={filterable === true ? 2:0} style={{'textAlign' : 'left', paddingRight:0}}>
          {filterable === true ?  
            <Form className='form_filter'>
              <Card>
                <Card.Title>Top Customer:</Card.Title>
                <Card.Body>
                  <Form>
                {listExclusionCheck}
                </Form>
                </Card.Body>
              </Card>
              </Form>

            :
            <></>}
        </Col>
      </Row>
      :
      <div></div>}
  </>
  );
}

export const MultiLineChartKPI  = React.memo(BaseMultiLineChartKPI)
export const LineChartKPI  = React.memo(BaseLineChartKPI)