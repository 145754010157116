import { useCases } from "./use_cases"

export const net_margin_by_cohort = (dateRange) => {
  return {
    "measures": [
      "MnEvents.netMargin"
    ],
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "compareDateRange": dateRange
      }
    ],
    "order":[["MnEvents.customerCohortId","asc"]],
    "dimensions": [
      "MnEvents.customerCohort",
      "MnEvents.customerCohortId"
    ],
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.revenue
      },
      {
        "member": "MnEvents.customerCohort",
        "operator": "set",
        "values": []
      }

    ],
    "limit": 50
  }
}

export const net_margin_by_cohort_history = (dateRange, granularity, dimension) => {

  let queryDimension = []
  let queryOrder = [[
    "MnEvents.timestamp",
    "asc"
  ],
  [
    "MnEvents.netMargin",
    "desc"
  ]]
  switch (dimension) {
    case '2':
      queryDimension = ["MnEvents.customerCohort"]
      break
    default:
      break
  }


  return {
    "measures": [
      "MnEvents.netMargin"
    ],
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "dateRange": dateRange,
        "granularity": granularity
      }
    ],
    "order": queryOrder,
    "dimensions": queryDimension,
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.revenue
      },
      {
        "member": "MnEvents.customerCohort",
        "operator": "set",
        "values": []
      },

    ]
  }
}


export const gross_margin_by_cohort = (dateRange) => {
  return {
    "measures": [
      "MnEvents.grossMargin"
    ],
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "compareDateRange": dateRange
      }
    ],
    "order": {},
    "dimensions": [
      "MnEvents.customerCohort"
    ],
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.revenue
      },
      {
        "member": "MnEvents.customerCohort",
        "operator": "set",
        "values": []
      }

    ],
    "limit": 50
  }
}

export const gross_margin_by_cohort_history = (dateRange, granularity, dimension) => {

  let queryDimension = []
  let queryOrder = [[
    "MnEvents.timestamp",
    "asc"
  ],
  [
    "MnEvents.grossMargin",
    "desc"
  ]]
  switch (dimension) {
    case '2':
      queryDimension = ["MnEvents.customerCohort"]
      break
    default:
      break
  }


  return {
    "measures": [
      "MnEvents.grossMargin"
    ],
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "dateRange": dateRange,
        "granularity": granularity
      }
    ],
    "order": queryOrder,
    "dimensions": queryDimension,
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.revenue
      },
      {
        "member": "MnEvents.customerCohort",
        "operator": "set",
        "values": []
      },

    ]
  }
}


export const gross_revenue_by_cohort_history = (dateRange, granularity, dimension) => {
  let queryDimension = []
  let queryOrder = [[
    "MnEvents.timestamp",
    "asc"
  ],
  [
    "MnEvents.totalAmount",
    "desc"
  ]]
  switch (dimension) {
    case '1':
      queryDimension = [  ]
      break
    case '2':
      queryDimension = ["MnEvents.customerCohort"]
      break
    default:
      break
  }

  return {
    "measures": [
      "MnEvents.totalAmount"
    ],
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "dateRange": dateRange,
        "granularity": granularity
      }
    ],
    "order": queryOrder,
    "dimensions": queryDimension,
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.revenue
      },
      {
        "member": "MnEvents.customerCohort",
        "operator": "set",
        "values": []
      },

    ]
  }

}

export const gross_revenue_by_cohort = (compareRange) => {
  return {
    "measures": [
      "MnEvents.totalAmount"
    ],
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "compareDateRange": compareRange
      }
    ],
    "dimensions": [
      "MnEvents.customerCohort",
      "MnEvents.customerCohortId"
    ],
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.revenue
      },
      {
        "member": "MnEvents.customerCohort",
        "operator": "set",
        "values": []
      },

    ],
    "order":[["MnEvents.customerCohortId","asc"]]
    ,
    "limit": 50
  }
}

export const pandl_by_customer = (dateRange, kpi, limit) =>{
  const query =  {
    "measures": kpi.concat(["MnEvents.totalAmount"]),
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "dateRange": dateRange
      }
    ],
    "dimensions": [
      "MnEvents.customerId",
    ],
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.revenue
      },
    ],
    "limit": limit
  }
  var obj = {};

  obj["MnEvents.totalAmount"] = "desc";
  query.order=obj

  return query
}

export const top_customers_by = (measure, compareRange, limit, granularity, customers) => {

  const query = {
    "dimensions": [
      "MnEvents.customerId",
    ],
    "measures": [
      measure,
      "MnEvents.totalAmount"
    ],
    "timeDimensions": [{
      "dimension": "MnEvents.timestamp",
      "dateRange":compareRange,
      "granularity": granularity
    }],
    "filters": [
      {
        "member": measure,
        "operator": "gt",
        "values": [
          "0"
        ]
      },
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.revenue
      }
    ]
  }

  if (limit) {
    query.limit = limit
  }

  if (customers && 0 < customers.length) {
    query.filters.push({
      "member": "MnEvents.customerId",
      "operator": "equals",
      "values": customers
    })
  }

  return query
}
