import { Table,Row, Col, Button, Form, Modal, Spinner } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { formatDate } from "../../utilities/date_formatting"
import { dateUtilities } from "../../utilities/date_formatting"
import { getApiAdministration, putApiAdministration, deleteApiAdministration, postApiAdministration } from "../../common/APICalls/apiAdministration"
import { useEffect, useState } from "react"
import * as FaIcons from 'react-icons/fa'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const PersonnelFTEs = () => {

    const { t } = useTranslation()
    const [data, setData] = useState([])
    const [currentPersonnelFTE, setCurrentPersonnelFTE] = useState({})
    const [onEditing, setOnEditing] = useState(false)
    const [deletePersonnel, setDeletePersonnel] = useState(false)
    const [onDeletePersonnel, setOnDeletePersonnel] = useState(false)
    const [onWork, setOnWork] = useState(false)


    const [referenceStartDate, setReferenceStartDate] = useState()  
    const [referenceEndDate, setReferenceEndDate] = useState()  


    const [groupSelectValue, setGroupSelectValue]= useState()
    const [newFTE, setNewFTE] = useState(false)
    const handleFTE = () => {
        getApiAdministration('personnelfte/list').then((response) =>{
            if (response ){
                setData(response)
                setOnEditing(false)
                setOnWork(false)
            }    
        })
    }

    const handleNewFTE = () => {
      setCurrentPersonnelFTE({
        lastname:'',
        name: '',
        flFTE:0
      })
      setReferenceEndDate()
      setReferenceStartDate()
      setGroupSelectValue(0)
      setNewFTE(true)
    }

    const handleCancelNewFTE = () => {
      setCurrentPersonnelFTE({})
      setNewFTE(false)
    }
   
    const classesNewEntryButton = () => {
      return [newFTE ? 'hideComponent' : 'showComponentRow'].join(' ')
    }
  

    const formatStatusValue = (value) => {
      return value === 0 ? 'Inactive' : 'Active'
    }

    
    const handleDeletePersonnelFTE = (index) => {
      setDeletePersonnel(true)
      const currentFTE = data[index]
      setCurrentPersonnelFTE(currentFTE)


    }

    const handleConfirmTrash = e => {
      setOnDeletePersonnel(true)
      deleteApiAdministration('personnelfte/' +  currentPersonnelFTE.id).then((response) => {
        if (response.code===200) {
          setOnDeletePersonnel(false)
          setDeletePersonnel(false)
          handleFTE()
        }
      })
    }

    const handleEditPersonnelFTE = (index) => {
      setOnEditing(true)
      setCurrentPersonnelFTE(data[index])
      setGroupSelectValue(data[index].flFTE)

      if (data[index].StartDate){
        const startDateReference = data[index].StartDate.split('T')[0].split('-')     
        setReferenceStartDate(new Date(startDateReference[1] + '-' + startDateReference[2] + '-' + startDateReference[0]))
      }

      if (data[index].EndDate){
        const endDateReference = data[index].EndDate.split('T')[0].split('-')     
        setReferenceEndDate(new Date(endDateReference[1] + '-' + endDateReference[2] + '-' + endDateReference[0]))
      }
    }

    const handleName = (value) => {
      let personnelFTE = {...currentPersonnelFTE};
      personnelFTE.name = value;
      personnelFTE.fullname =   personnelFTE.lastname + ', ' + value
      setCurrentPersonnelFTE(personnelFTE)
    }

    const handleLastName = (value) => {
      let personnelFTE = {...currentPersonnelFTE};
      personnelFTE.lastname = value;
      personnelFTE.fullname =   value + ', ' + personnelFTE.name
      setCurrentPersonnelFTE(personnelFTE)

    }


    const handleChangeFTE = (value) => {
      setGroupSelectValue(value)
      let personnelFTE = {...currentPersonnelFTE};
      personnelFTE.flFTE = parseFloat(value)
      setCurrentPersonnelFTE(personnelFTE)
    }

    const handleSetStartDate = (date) => {
    
      setReferenceStartDate(date)
  
      let personnelFTE = {...currentPersonnelFTE};
      personnelFTE.StartDate = date.toISOString().split('T')[0]
      setCurrentPersonnelFTE(personnelFTE)
    }

    const handleSetEndDate = (date) => {
      setReferenceEndDate(date)

      let personnelFTE = {...currentPersonnelFTE};
      personnelFTE.EndDate = date.toISOString().split('T')[0]
      
      setCurrentPersonnelFTE(personnelFTE)      
    }

    const handleSubmit = (e) => {
      e.preventDefault()
      let personnelFTE = {...currentPersonnelFTE};
      const startDate = new Date(personnelFTE.StartDate)
      personnelFTE.StartDate = (startDate.getUTCMonth()+1) + '-' + startDate.getUTCDate()+ '-' + startDate.getUTCFullYear()

      if (personnelFTE.EndDate){
        const endDate = new Date(personnelFTE.EndDate)
        personnelFTE.EndDate = (endDate.getUTCMonth()+1) + '-' + endDate.getUTCDate()+ '-' + endDate.getFullYear()
        personnelFTE.status = 0
      } else {
        personnelFTE.status = 1
      }

      setOnWork(true)

      if (newFTE){
        postApiAdministration('personnelfte',personnelFTE).then((response) =>{
          if (response ){
            setCurrentPersonnelFTE({})
            handleFTE()
            setOnWork(false)
            setNewFTE(false)
        }})    

      } else {
        putApiAdministration('personnelfte',personnelFTE).then((response) =>{
          if (response ){
            setCurrentPersonnelFTE({})
            handleFTE()
            setOnWork(false)
        }})    
  
      }
    

    }

    const handleCancelEditing =() => {
      setCurrentPersonnelFTE({})
      setOnEditing(false)
    }
    useEffect(() => {
        handleFTE()
    },[])


    const newCostActionRow = <tr className={newFTE ? 'hideComponent' : 'showComponentRow'} >
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td className="cell-value">
      <Button variant="outline-primary" disabled={onEditing} className={classesNewEntryButton()} onClick={()=>handleNewFTE()}>    
            Add Entry
      </Button>
    </td>
  </tr>

  const newCostRow =  <tr className={newFTE ? 'showComponentRow' : 'hideComponent'}>
      <td className="cell-label">
        <Form.Control 
          type="text" 
          placeholder="First Name" 
          value={currentPersonnelFTE.name}
          onChange={(e) => handleName(e.target.value)} />
        <Form.Control 
          type="text" 
          placeholder="Last Name " 
          value={currentPersonnelFTE.lastname}
          onChange={(e) => handleLastName(e.target.value)} />
      </td>
      <td className="cell-label">
        <span className='showComponentRow'>
        </span>
      </td>
      <td className="cell-label">
        <DatePicker 
          showIcon 
          dateFormat="MM/dd/yyyy"
          wrapperClassName="customDatePickerWidth"
          value={referenceStartDate}
          selected={referenceStartDate}
          onChange={(date) => handleSetStartDate(date)}
          />
      </td>
      <td className="cell-label">
        <DatePicker 
          showIcon 
          dateFormat="MM/dd/yyyy"
          wrapperClassName="customDatePickerWidth"
          value={referenceEndDate}
          selected={referenceEndDate}
          onChange={(date) => handleSetEndDate(date)}
          />
      </td>
      <td className="cell-label">
        <Form.Select id='PersonnelStatus'  onChange={(e) =>handleChangeFTE(e.target.value)} value={groupSelectValue} >
              <option value='0'>-</option>
              <option value='1'>1.00</option>
              <option value='0.75'>0.75</option>
              <option value='0.5'>0.50</option>
              <option value='0.25'>0.25</option>
          </Form.Select>
      </td>
        <td className="cell-label">
        <Row className='showComponentRow'>
          <Col>
            <Button
              variant="outline-danger" 
              onClick={()=>handleCancelNewFTE()}>
                  <FaIcons.FaBan />
            </Button>
          </Col>
          <Col>
            <Button 
              variant="outline-success" 
              onClick={(e)=> handleSubmit (e)}
            >
              <FaIcons.FaCheckDouble />
            </Button>
              
          </Col>
        </Row>
      </td>

    </tr>


    return <div>
      <Table striped bordered style={{width:'100%'}}>
      <tbody>
          {newCostRow}  
          {newCostActionRow}        

      </tbody>
      </Table>

    <Table striped bordered style={{width:'100%'}}>
        <thead>
        <tr>
            <th className = "cell-label">{t('SECTION.ADMINISTRATION.PERSONNELFTE_TABLE.NAME')}</th>
            <th className = "cell-label">{t('SECTION.ADMINISTRATION.PERSONNELFTE_TABLE.STATUS')}</th>
            <th className = "cell-label">{t('SECTION.ADMINISTRATION.PERSONNELFTE_TABLE.START')}</th>
            <th className = "cell-label">{t('SECTION.ADMINISTRATION.PERSONNELFTE_TABLE.END')}</th>
            <th className = "cell-label">{t('SECTION.ADMINISTRATION.PERSONNELFTE_TABLE.FTE')}</th>
            <th className = "cell-label">{t('GENERAL.ACTIONS.LABEL')}</th>
        </tr>
        </thead>
        <tbody>
            {data.map((perosnnelfte, index) =>{
                return <tr>
                    <td className="cell-label">
                      <span className={onEditing && currentPersonnelFTE.id === perosnnelfte.id ? 'hideComponent' : 'showComponentRow'}>
                      {perosnnelfte.name}<br/>{perosnnelfte.lastname}
                      </span> 
                      <span className={onEditing && currentPersonnelFTE.id === perosnnelfte.id ? 'showComponentRow' : 'hideComponent'}>
                        <Form.Control 
                          type="text" 
                          placeholder="Description" 
                          value={currentPersonnelFTE.name}
                          onChange={(e) => handleName(e.target.value)} />
                        <Form.Control 
                          type="text" 
                          placeholder="Description" 
                          value={currentPersonnelFTE.lastname}
                          onChange={(e) => handleLastName(e.target.value)} />


                      </span> 

                    </td>
                    <td className="cell-label">
                      <span className='showComponentRow'>
                        {formatStatusValue(perosnnelfte.status)}
                      </span>
                    </td>
                    <td className="cell-label">
                      <span className={onEditing && currentPersonnelFTE.id === perosnnelfte.id ? 'hideComponent' : 'showComponentRow'}>
                        {perosnnelfte.StartDate ? formatDate(perosnnelfte.StartDate) : ''}
                      </span>
                      <span className={onEditing && currentPersonnelFTE.id === perosnnelfte.id ? 'showComponentRow' : 'hideComponent'}>
                      <DatePicker 
                        showIcon 
                        dateFormat="MM/dd/yyyy"
                        wrapperClassName="customDatePickerWidth"
                        value={referenceStartDate}
                        selected={referenceStartDate}
                        onChange={(date) => handleSetStartDate(date)}
                        />
                      </span>
                    </td>
                    <td className="cell-label">
                      <span className={onEditing && currentPersonnelFTE.id === perosnnelfte.id ? 'hideComponent' : 'showComponentRow'}>
                      {perosnnelfte.EndDate ? formatDate(perosnnelfte.EndDate) : ''}

                      </span>
                      <span className={onEditing && currentPersonnelFTE.id === perosnnelfte.id ? 'showComponentRow' : 'hideComponent'}>
                      <DatePicker 
                        showIcon 
                        dateFormat="MM/dd/yyyy"
                        wrapperClassName="customDatePickerWidth"
                        value={referenceEndDate}
                        selected={referenceEndDate}
                        onChange={(date) => handleSetEndDate(date)}
                        />
                      </span>
 
                    </td>
                    <td className="cell-label">
                      <span className={onEditing && currentPersonnelFTE.id === perosnnelfte.id ? 'hideComponent' : 'showComponentRow'}>

                        {perosnnelfte.flFTE}
                      </span>
                      <span className={onEditing && currentPersonnelFTE.id === perosnnelfte.id ? 'showComponentRow' : 'hideComponent'}>

                        <Form.Select  id='PersonnelStatus' onChange={(e) =>handleChangeFTE(e.target.value)} value={currentPersonnelFTE.flFTE} >
                              <option  {...groupSelectValue === '0'} value='0'>-</option>
                              <option {...groupSelectValue === '1'} value='1'>1.00</option>
                              <option {...groupSelectValue === '0.5'} value='0.75'>0.75</option>
                              <option {...groupSelectValue === '0.5'} value='0.5'>0.50</option>
                              <option {...groupSelectValue === '0.25'} value='0.25'>0.25</option>
                          </Form.Select>
                      </span>
                    </td>

                    <td className="cell-label">
                    <Row className={onEditing && currentPersonnelFTE.id === perosnnelfte.id ? 'hideComponent' : 'showComponentRow'}>
                      <Col>
                        <Button variant="outline-secondary" 
                          disabled={onEditing && currentPersonnelFTE.id !== perosnnelfte.id }
                          onClick={((e) => handleEditPersonnelFTE(index))}>
                            <FaIcons.FaPencilRuler />
                        </Button>
                      </Col>
                      <Col>
                        <Button variant="outline-danger" 
                          disabled={onEditing && currentPersonnelFTE.id !== perosnnelfte.id }
                          onClick={(e)=> handleDeletePersonnelFTE(index)}
                          >
                            <FaIcons.FaTrash />
                        </Button>
                        
                      </Col>
                    </Row>
                    <Row className={onEditing && currentPersonnelFTE.id === perosnnelfte.id ? 'showComponentRow' : 'hideComponent'}>
                      <Col>
                        <Button variant="outline-danger" 
                              onClick={()=>handleCancelEditing()}>
                                <FaIcons.FaBan />
                        </Button>
                      </Col>
                      <Col>
                        <Button variant="outline-success" 
                          onClick={(e)=> handleSubmit (e)}
                          >
                          <FaIcons.FaCheckDouble />
                        </Button>
                          
                      </Col>
                    </Row>

                    </td>
                </tr>
            })}
        </tbody>
    </Table>

    <Modal show={deletePersonnel} >
            <Modal.Header>
            <Modal.Title>
                Confirm Operation 
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='d-flex justify-content-center'>
                <div className={onDeletePersonnel ? "hideComponent" : "showComponent"}>
                    Do you want delete this group?
                </div>
                <div  className={!onDeletePersonnel ? "hideComponent" : "showComponent"}>
                <Spinner animation="grow" size="xl" />
                </div>
            </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" 
                        className="buttonQBOBoxInRow" 
                        onClick={()=>setDeletePersonnel(false)}>
                            {t('ACTIONS.CANCEL')}
                </Button>
                <Button variant="primary" 
                        className="buttonQBOBoxInRow" 
                        onClick={() => handleConfirmTrash()}>
                            {t('ACTIONS.CONFIRM')}
                </Button>

            </Modal.Footer>
        </Modal>

    </div>

}