import Table from "react-bootstrap/Table";

import { Container, Card, Row, Col, Button } from "react-bootstrap";
import { Context } from "../App";
import { FullDoughnuthKPI } from "./Graph/FullDoughnuth";
import { useState, useEffect, useContext, Fragment } from "react";
import { CubeContext, useCubeQuery } from "@cubejs-client/react";

import { dateUtilities, quarterOrMonth } from "../utilities/date_formatting";
import { FormattedValue } from "../common/FormattedValues/FormattedValues";
import { MultiLineChartKPI } from "./Graph/LineChart";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

import {
  pandl_by_customer,
  top_customers_by,
} from "../kpi_queries/customers_number";
import { historical_revenue_period_v15 } from "../kpi_queries/gross_revenue";
import { QueryPeriod } from "../logic/CalculatedValues";
import { groupValues, groupValuesExtraFields } from "../utilities/array_manipulation";
import { generateUUID } from "../utilities/ui_tools";
import { BarChartKPINoOver } from './Graph/Istogram';

export const TopCustomersBy = ({
  measure,
  periodToShow,
  granularity,
  limit,
  seeMargin, 
  noPercent
}) => {
  const [state] = useContext(Context); /**Customer Context*/
  const { cubejsApi } = useContext(CubeContext);

  const [data, setData] = useState();
  const [csvData, setCsvData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [topCustomersData, setTopCustomersData] = useState({
    customerIdsAmount: [],
    customerIds: [],
    customerAmounts: [],
    customerMargins: [],
    topCustomerAmounts: { top10: 0, top20: 0, top30: 0, top40: 0, top50: 0 },
    totalAmount: 0,
  });
  const [groupedData, setGroupedData] = useState();

  const [totalTops, setTotalTops] = useState({ total: 0, others: 0 });

  const { t } = useTranslation();

  const { resultSet: topCustomerList } = useCubeQuery(
    pandl_by_customer(state.topcustomers_period[1], [measure], 20)
  );
  const totalGrossRevenue = QueryPeriod(
    state.topcustomers_period,
    historical_revenue_period_v15,
    [measure]
  );

  const topCustomerTrend = [];
  const formatVal = (n) => (n ? n / 100 : "-");
  const diffVal = (priorVal, lastVal) =>
    priorVal & lastVal ? ((priorVal - lastVal) / lastVal) * 100 : 0;

  const getTableData = (priors, lasts, field) => {
    return topCustomersData.customerIds
      .slice(0, limit)
      .map((customerId) => {
        const totals = { last: 0, prior: 0, diff: 0 };
        const fields = [];
        for (const i in lasts) {
          const last = parseInt(lasts[i][customerId + "," + field]);
          const prior = parseInt(
            priors[i] ? priors[i][customerId + "," + field] ?? 0 : 0
          );
          const diff = diffVal(prior, last);
          totals.prior += Number.isNaN(prior) ? 0 : prior;
          totals.last += Number.isNaN(last) ? 0 : last;
          fields.push({ i, last, prior, diff });
        }
        return { customerId, totals, fields };
      })
      .sort((a, b) => b.totals.last - a.totals.last);
  };
  const loadCsvData = async (event, done) => {
    const data = await cubejsApi.load(
      top_customers_by(
        measure,
        state.topcustomers_period[1],
        false,
        "month"
      )
    );

    const measureMap = {
      "MnEvents.totalAmount": t("SELECTORS.PANDL.GROSSREVENUE"),
      "MnEvents.grossMargin": t("SELECTORS.PANDL.GROSSPROFIT"),
      "MnEvents.netMargin": t("SELECTORS.PANDL.NETPROFIT"),
    };
    setCsvData(
      data
        .tablePivot()
        .map((r) => [
          quarterOrMonth(r["MnEvents.timestamp.month"], "month"),
          r["MnEvents.customerId"],
          r[measure],
        ])
    );
    setCsvHeaders(["month", "customer", measureMap[measure]]);
    document.getElementById('csvButton').click();
  };

  const defineTotals = (topAmount, totalAmount) => {
    const total = topAmount;
    const others = totalAmount - topAmount;
    return { total: total, others: others };
  };

  useEffect(() => {
    switch (Number(limit)) {
      case 10:
        setTotalTops(
          defineTotals(
            topCustomersData.topCustomerAmounts.top10,
            topCustomersData.totalAmount
          )
        );
        break;
      case 30:
        setTotalTops(
          defineTotals(
            topCustomersData.topCustomerAmounts.top30,
            topCustomersData.totalAmount
          )
        );
        break;
      case 40:
        setTotalTops(
          defineTotals(
            topCustomersData.topCustomerAmounts.top40,
            topCustomersData.totalAmount
          )
        );
        break;
      case 50:
        setTotalTops(
          defineTotals(
            topCustomersData.topCustomerAmounts.top50,
            topCustomersData.totalAmount
          )
        );
        break;
      default:
        setTotalTops(
          defineTotals(
            topCustomersData.topCustomerAmounts.top20,
            topCustomersData.totalAmount
          )
        );
        break;
    }
  }, [limit, measure, topCustomersData]);

  useEffect(() => {
    if (
      topCustomerTrend !== null &&
      topCustomerTrend !== undefined &&
      topCustomerTrend.length !== 0
    ) {
      const pivotedResult = topCustomerTrend.tablePivot({
        x: ["MnEvents.timestamp"],
        y: ["MnEvents.customerId"],
        fillMissingDates: true,
      });
      const headersSerie = topCustomerTrend.pivotedRows().map((r) => r.x);

      const half = Math.ceil(pivotedResult.length / 2);
      const top_customer_trend_prior = pivotedResult.slice(0, half);
      const top_customer_trend_previous = pivotedResult.slice(-half);
      const headers_prior = headersSerie.slice(0, half);
      const headers_prev = headersSerie.slice(-half);

      const tableData = getTableData(
        top_customer_trend_prior,
        top_customer_trend_previous,
        measure
      );

      const tableDataHTML = tableData.map((r) => (
        <tr key={generateUUID(16)}>
          <td key={generateUUID(16)} style={{ position: "sticky", left: 0, background: "white" }}>
            {r.customerId}
          </td>
          <td key={generateUUID(16)}
            style={{
              position: "sticky",
              left: 75,
              background: "white",
              whiteSpace: "nowrap",
            }}
          >
            <FormattedValue type="money" value={formatVal(r.totals.last)} />
          </td>
          <td key={generateUUID(16)}
            style={{
              position: "sticky",
              left: 150,
              background: "white",
              whiteSpace: "nowrap",
            }}
          >
            <FormattedValue type="money" value={formatVal(r.totals.prior)} />
          </td>
          <td key={generateUUID(16)}
            style={{
              position: "sticky",
              left: 225,
              background: "white",
              whiteSpace: "nowrap",
            }}
          >
            <FormattedValue
              type="percent"
              signed
              value={diffVal(r.totals.prior, r.totals.last)}
            />
          </td>
          {r.fields.map((f) => (
            <Fragment key={r.customerId + f.i}>
              <td  key={generateUUID(16)} style={{ whiteSpace: "nowrap" }}>
                <FormattedValue type="money" value={formatVal(f.last)} />
              </td>
              <td key={generateUUID(16)} style={{ whiteSpace: "nowrap" }}>
                <FormattedValue type="money" value={formatVal(f.prior)} />
              </td>
              {seeMargin === true ?
              <td key={generateUUID(16)} style={{ whiteSpace: "nowrap" }}>
                <FormattedValue type="percent" signed value={f.diff} />
              </td>
              :
              <></>}
            </Fragment>
          ))}
        </tr>
      ));

      const tableHeadersHTML = headers_prev.map((h, i) => {
        const lastDate = new Date(h);
        const priorDate = new Date(headers_prior[i]);
        return (
          <th colSpan={3} key={h}>
            {granularity === "month"
              ? dateUtilities.monthsNamesShort[lastDate.getUTCMonth()]
              : dateUtilities.Quarters[lastDate.getUTCMonth()]}
            &nbsp;{lastDate.getUTCFullYear()}/
            {granularity === "month"
              ? dateUtilities.monthsNamesShort[priorDate.getUTCMonth()]
              : dateUtilities.Quarters[priorDate.getUTCMonth()]}
            &nbsp;{priorDate.getUTCFullYear()}
          </th>
        );
      });

      setData({
        tableRawData: tableData,
        tableRawHeadersPrev: headers_prev,
        tableRawHeadersPrior: headers_prior,
        tableData: tableDataHTML,
        tableHeaders: tableHeadersHTML,
        tableSubHeaders: headers_prev.map((h) => (
          <Fragment key={h}>
            <th>last</th>
            <th>prior</th>
            <th>+/-</th>
          </Fragment>
        )),
        dataChart: { data: topCustomerTrend },
      });
    }
  }, [topCustomerTrend, granularity]);

  useEffect(() => {
    if (
      topCustomerList !== undefined &&
      topCustomerList !== null &&
      totalGrossRevenue !== undefined &&
      totalGrossRevenue !== null
    ) {
      const topCustomerFromQuery = topCustomerList.tablePivot();
      const sortedCustomer = topCustomerFromQuery.sort((a, b) => { 
        const multi =  -1 
        return multi * (a.Priority - b.Priority || a[measure] - b[measure]) 
      })

      const reducedData = topCustomerFromQuery.reduce((acc, curr, index) => {
        if (index === 0) {
          acc.customerIdsAmount = [];
          acc.customerIds = [];
          acc.customerAmounts = [];
          acc.customerMargins = [];
          acc.topCustomerAmounts = {
            top10: 0,
            top20: 0,
            top30: 0,
            top40: 0,
            top50: 0,
          };
          acc.totalAmount = 0;
        }
        acc.customerIds.push(curr["MnEvents.customerId"]);
        acc.customerAmounts.push(Number(curr[measure]) / 100);
        if (seeMargin){
          acc.customerMargins.push(
            (Number(curr[measure]))/Number(curr['MnEvents.totalAmount'])* 100
            )
        }
        acc.customerIdsAmount.push([
          curr["MnEvents.customerId"],
          Number(curr[measure]) / 100,
        ]);
        return {
          customerIds: acc.customerIds,
          customerAmounts: acc.customerAmounts,
          customerIdsAmount: acc.customerIdsAmount,
          customerMargins: acc.customerMargins,
          topCustomerAmounts: {
            top10:
              index < 10
                ? acc.topCustomerAmounts.top10 + Number(curr[measure]) / 100
                : acc.topCustomerAmounts.top10,
            top20:
              index < 20
                ? acc.topCustomerAmounts.top20 + Number(curr[measure]) / 100
                : acc.topCustomerAmounts.top20,
            top30:
              index < 30
                ? acc.topCustomerAmounts.top30 + Number(curr[measure]) / 100
                : acc.topCustomerAmounts.top30,
            top40:
              index < 40
                ? acc.topCustomerAmounts.top40 + Number(curr[measure]) / 100
                : acc.topCustomerAmounts.top40,
            top50:
              index < 50
                ? acc.topCustomerAmounts.top50 + Number(curr[measure]) / 100
                : acc.topCustomerAmounts.top50,
          },
          totalAmount: Number(totalGrossRevenue[1]) / 100,
        };
      }, topCustomersData);

      const totalsValues = [
        ["Top Customers", reducedData.topCustomerAmounts.top20],
        [
          "Others",
          reducedData.totalAmount - reducedData.topCustomerAmounts.top20,
        ],
      ];
      setGroupedData({
        total: groupValues(
          totalsValues,
          reducedData.totalAmount,
          false,
          "money",
          true,
          measure === 'MnEvents.netMargin' 
        ),
        detailed: groupValuesExtraFields(
          reducedData.customerIdsAmount.slice(0, 20),
          reducedData.totalAmount,
          false,
          "money",
          true,
          reducedData.customerMargins.slice(0,20),
          noPercent
        ),
      });
      setTopCustomersData(reducedData);
    }
  }, [topCustomerList, totalGrossRevenue]);

  return (
    <>
      {totalTops.total > 0 ? (
        <Card.Body className="card-height-xl">
          {measure !== 'MnEvents.netMargin' ?
          <>
          <Row md={2} lg={2} className="g-5 car row_kpi">
            <Col className="col_kpi">
              <Card className="card_kpi">
                <Card.Title>{t("KPIs.TITLES.TOTAL")}</Card.Title>
                <Card.Body>
                  <div className="chart-md">
                    <FullDoughnuthKPI
                      values={[totalTops.total, totalTops.others]}
                      labelsIn={["Top Customers", "Others"]}
                    />
                  </div>
                  <Table bordered striped>
                    <thead>
                      <tr key={generateUUID(16)}>
                        <th className="cell-emphasis"></th>
                        <th className="cell-emphasis cell-value">
                          {t("TABLES.ROW_HEADERS.NUMBER")}
                        </th>
                        <th className="cell-emphasis cell-value">
                          {t("TABLES.ROW_HEADERS.PERCENT")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedData.total[2][0]}

                      <tr key={generateUUID(16)} className="row-summary">
                        <td className="cell-label">
                          {t("TABLES.END_ROW.TOTAL")}
                        </td>
                        <td className="cell-value">
                          <FormattedValue
                            type="money"
                            value={parseInt(topCustomersData.totalAmount)}
                          />
                        </td>
                        <td  className="cell-value">
                        <FormattedValue
                            type="percent"
                            value={100} />

                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col_kpi">
              <Card className="card_kpi">
                <Card.Title>{t("KPIs.TITLES.TOP_CUSTOMER")}</Card.Title>
                <Card.Body>
                  <div className="chart-md">
                    <FullDoughnuthKPI
                      values={topCustomersData.customerAmounts.slice(0, limit)}
                      labelsIn={topCustomersData.customerIds.slice(0, limit)}
                    />
                  </div>
                <Table bordered striped>
                  <thead>
                    <tr key={generateUUID(16)}>
                      <th className="cell-emphasis"></th>
                      <th className="cell-emphasis cell-value">
                        {t("TABLES.ROW_HEADERS.NUMBER")}
                      </th>
                      {noPercent === false ?
                        <th className="cell-emphasis cell-value">
                          {t("TABLES.ROW_HEADERS.PERCENT")}
                        </th>
                        :
                        <></>
                      }
                      {seeMargin === true ?
                      <th className="cell-emphasis cell-value">
                        Margin
                      </th>
                      :
                      <></>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {groupedData.detailed[2][0]}

                    <tr key={generateUUID(16)} className="row-summary">
                      <td className="cell-label">
                        {t("TABLES.END_ROW.TOTALTOP")}
                      </td>
                      <td className="cell-value">
                        <FormattedValue
                          type="money"
                          value={parseInt(totalTops.total)}
                        />
                      </td>
                      <td className="cell-value">
                      </td>

                    </tr>
                    <tr key={generateUUID(16)} className="row-summary-secondary">
                      <td className="cell-label cell-label-secondary">
                        {t("TABLES.END_ROW.TOTAL")}
                      </td>
                      <td className="cell-value cell-label-secondary">
                        <FormattedValue
                          type="money"
                          value={parseInt(topCustomersData.totalAmount)}
                        />
                      </td>
                      <td className="cell-value cell-label-secondary">
                      </td>

                    </tr>

                  </tbody>
                </Table>
                </Card.Body>

              </Card>
            </Col>
          </Row>
          <Container fluid>
            <Row id="Graph"></Row>
            <Row id="Tables">
              <Col md={6}></Col>
            </Row>
          </Container>
          </> 
          :
          <>
          <Row md={2} lg={2} className="g-5 car row_kpi">
            <Col className="col_kpi">
              <Card className="card_kpi">
                <Card.Title>{t("KPIs.TITLES.TOTAL")}</Card.Title>
                <Card.Body>
                  <div className="chart-md">
                  <BarChartKPINoOver 
                  key={generateUUID(16)}
                  dataseries={{title:'Top vs Others', dimension: ["Top Customers", "Others"], data: [totalTops.total, totalTops.others]}} 
                  colorMono={false}  
                  Yaxis="$"
                  Xaxis="Customer Type" /> 

                  </div>
                  <Table bordered striped>
                    <thead>
                      <tr key={generateUUID(16)}>
                        <th className="cell-emphasis"></th>
                        <th className="cell-emphasis cell-value">
                          {t("TABLES.ROW_HEADERS.NUMBER")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedData.total[2][0]}

                      <tr key={generateUUID(16)} className="row-summary">
                        <td className="cell-label">
                          {t("TABLES.END_ROW.TOTAL")}
                        </td>
                        <td className="cell-value">
                          <FormattedValue
                            type="money"
                            value={parseInt(topCustomersData.totalAmount)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col_kpi">
              <Card className="card_kpi">
                <Card.Title>{t("KPIs.TITLES.TOP_CUSTOMER")}</Card.Title>
                <Card.Body>
                  <div className="chart-md">
                  <BarChartKPINoOver 
                  key={generateUUID(16)}
                  dataseries={{title:'Top vs Others', dimension:topCustomersData.customerIds.slice(0, limit), data: topCustomersData.customerAmounts.slice(0, limit)}} colorMono={false}  
                  Yaxis="$"
                  Xaxis="Customer" /> 
 

                  </div>
                </Card.Body>
                <Table bordered striped>
                  <thead>
                    <tr key={generateUUID(16)}>
                      <th className="cell-emphasis"></th>
                      <th className="cell-emphasis cell-value">
                        {t("TABLES.ROW_HEADERS.NUMBER")}
                      </th>
                      
                      {seeMargin === true ?
                      <th className="cell-emphasis cell-value">
                        Margin
                      </th>
                      :
                      <></>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {groupedData.detailed[2][0]}

                    <tr key={generateUUID(16)} className="row-summary">
                      <td className="cell-label">
                        {t("TABLES.END_ROW.TOTAL")}
                      </td>
                      <td className="cell-value">
                        <FormattedValue
                          type="money"
                          value={parseInt(totalTops.total)}
                        />
                      </td>
                      <td className="cell-value">
                      </td>

                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
          <Container fluid>
            <Row id="Graph"></Row>
            <Row id="Tables">
              <Col md={6}></Col>
            </Row>
          </Container>
          </> 
          }
          {/** 
          {csvData.length > 0 ? (
            <CSVLink
              className="btn btn-primary mt-5"
              data={csvData}
              headers={csvHeaders}
              filename={"top-customers.csv"}
              id="csvButton"
            >
              {t("TABS.CSV")}
            </CSVLink>
          ) : (
            <Button className="btn btn-primary mt-5" onClick={loadCsvData}>
              {t("TABS.CSV")}
            </Button>
          )}
          */}
        </Card.Body>
        
      ) : (
        <></>
      )}

      {false ? (
        <>
          <Tabs defaultActiveKey="graph" className="mb-3">
            <Tab eventKey="graph" title={t("TABS.GRAPH")}>
              <MultiLineChartKPI
                dataSeries={data.dataChart}
                kindOfGroup="cohort"
                granularity={granularity}
                Yaxis='$'  
              />
            </Tab>
            <Tab eventKey="table" title={t("TABS.TABLE")}>
              <Table striped bordered size="sm" responsive>
                <thead>
                  <tr key={generateUUID(16)}>
                    <th
                      rowSpan={2}
                      style={{
                        position: "sticky",
                        left: 0,
                        background: "white",
                      }}
                    >
                      Customer
                    </th>
                    <th
                      colSpan={3}
                      style={{
                        position: "sticky",
                        left: 75,
                        background: "white",
                      }}
                    >
                      TOTAL
                    </th>
                    {data.tableHeaders}
                  </tr>
                  <tr key={generateUUID(16)}>
                    <th
                      style={{
                        position: "sticky",
                        left: 75,
                        background: "white",
                      }}
                    >
                      last
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        left: 150,
                        background: "white",
                      }}
                    >
                      prior
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        left: 225,
                        background: "white",
                      }}
                    >
                      +/-
                    </th>
                    {data.tableSubHeaders}
                  </tr>
                </thead>
                <tbody>{data.tableData}</tbody>
              </Table>
            </Tab>
          </Tabs>
          {/**
          {csvData.length > 0 ? (
            <CSVLink
              className="btn btn-primary mt-5"
              data={csvData}
              headers={csvHeaders}
              filename={"top-customers.csv"}
            >
              {t("TABS.CSV")}
            </CSVLink>
          ) : (
            <Button className="btn btn-primary mt-5" onClick={loadCsvData}>
              {t("TABS.CSV")}
            </Button>
          )}
           */}
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};
