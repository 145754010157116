
export const store_credit_last_month = ({
  "measures": [
    "StoreCreditQBO.rollingAmount"
  ],
  "timeDimensions": [
    {
      "dimension": "StoreCreditQBO.dateDocument",
      "dateRange": process.env.REACT_APP_DATERANGE_STORE.split(';'),
    }
  ],
  "order": {},
  "dimensions": [],

  "filters": [
  ],
  "limit": 50
});

export const store_credit_by_month = (compareDateRange, granularity) => {
  return {
    "measures": [
      "StoreCreditQBO.rollingAmount"
    ],
    "timeDimensions": [
      {
        "dimension": "StoreCreditQBO.dateDocument",
        "compareDateRange": compareDateRange,
        "granularity": granularity
      }
    ],
    "order": {},
    "dimensions": [],

    "filters": [
    ]
  }
}

export const store_credit_by_month_long = (dateRange, granularity) => {
  return {
    "measures": [
      "StoreCreditQBO.rollingAmount"
    ],
    "timeDimensions": [
      {
        "dimension": "StoreCreditQBO.dateDocument",
        "dateRange": dateRange,
        "granularity": granularity
      }
    ],
    "order": {},
    "dimensions": [],

    "filters": [
    ]
  }
}


export const store_credit_by = (dimension,date,order) => {
  const queryCube = {"measures": [
    "StoreCredit.balanceCredit"
  ],
  "order": {},
  "dimensions": 
    dimension
  ,
  "timeDimensions": [
    {
      "dimension": "StoreCredit.created_at",
      "dateRange": date
    }],

  "filters": [
    {
      "member": dimension[0],
      "operator": "set",
    },
  ],
  "limit": 50   
  }

  if (order){
    queryCube.order = order
  }
  return queryCube
}

export const store_credit_month = (compareDateRange) => {
  return {
    "measures": [
      "StoreCredit.avgMonth"
    ],
    "timeDimensions": [

      {
        "dimension": "StoreCredit.timestamp",
        "compareDateRange": compareDateRange
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": []
  }
}