import React from 'react';
import {useState, useEffect} from 'react';
import {useCubeQuery} from '@cubejs-client/react';
import { Tab, Tabs } from 'react-bootstrap'
import { generateUUID } from '../utilities/ui_tools';

import Table from 'react-bootstrap/Table'
import {dateUtilities} from '../utilities/date_formatting'
import {FormattedValue} from '../common/FormattedValues/FormattedValues'
import {LineChartKPI} from '../components/Graph/LineChart'
import { BarChartRevenue } from './Graph/Istogram';
import { useTranslation } from 'react-i18next';
import { ValueLayoutComponent } from '../utilities/ui_tools';
import { MultiBarStackKPI } from './Graph/Istogram';

const BaseKPIMonthlyView = ({
  queryTrend, 
  queryTrendTopCustomer,
  queryTrendCohort, 
  queryTrendUseCase ,
  type, 
  keyStorage,
  granularity, 
  Yaxis, 
  reduce, 
  istogram}) =>{
    const { t } = useTranslation()

    const cachedData = JSON.parse(sessionStorage.getItem(keyStorage))

    const[data, setData] =useState({
      trendData: null,
      linesCohort: null,
      linesUseCase: null,
      linesTopCustomers: null
})

    const {resultSet:valueMonth} = useCubeQuery(queryTrend);
    const {resultSet:valueTrendTopCustomers} = useCubeQuery(queryTrendTopCustomer)
    const {resultSet:valueMonthCohort} = useCubeQuery(queryTrendCohort)  
    const {resultSet:valueMonthUseCase} =  useCubeQuery(queryTrendUseCase) 

    useEffect(()=>{
      const parseResultSet = (valueMonthCohort,valueMonthUseCase,valueTrendTopCustomers) =>{
        setData({
          trendData: parseValueMonth(),
          linesCohort: valueMonthCohort,
          linesUseCase: valueMonthUseCase,
          linesTopCustomers: valueTrendTopCustomers
        })
      }
      const dataParsedCohort = (valueMonth && ((valueMonthCohort && valueMonthUseCase && valueTrendTopCustomers) || keyStorage==='customer_trend')) ?  parseResultSet(valueMonthCohort,valueMonthUseCase,valueTrendTopCustomers) : null
    },[valueMonth,valueMonthCohort,valueMonthUseCase,valueTrendTopCustomers])

    const parseValueMonth  = () => {
       const parseResultSet = (valueMonth) =>{
        const pivotedResult = valueMonth.tablePivot({
            fillMissingDates:true,
            x:["MnEvents.timestamp"],
            y:["MnEvents.useCase","measures"]
        })

        const half = Math.ceil(pivotedResult.length / 2); 
        const firstSerie = pivotedResult.slice(0,half)
        const secondSerie = pivotedResult.slice(-half)
        let aggregatedResult = []
        for (let i = 0; i < firstSerie.length; i++) {
          const objClassFirst = Object.keys(firstSerie[i]).map((j) => {
            return secondSerie[i][j];
          });
          const objClassSecond = Object.keys(secondSerie[i]).map((j) => {
            return firstSerie[i][j];
          });

           aggregatedResult.push([objClassFirst[0],objClassFirst[1],objClassSecond[0],objClassSecond[1]]  )
        } 

        let totalPrev = 0
        let totalPrior = 0
        let previousDataSet = []
        let priorDataSet =[]
        let monthsInterval = []
        let monthsIntervalPrior = []


        if(granularity === 'quarter' && reduce){
          
          aggregatedResult = aggregatedResult.filter((x) =>{
            const d = new Date(x[0].replace('T', ' '));
            if (d.getTimezoneOffset() < 0){
              d.setUTCDate(d.getUTCDate()+1 );
            } else {
              d.setUTCDate(d.getUTCDate() + 1 );
            }
            return [2, 5, 8, 11].includes(d.getUTCMonth())
          })
        }

        const monthData = aggregatedResult.map((objClass) => {

            const dateEvent = new Date(objClass[0].replace('T',' '));
            const dateEventPrior = new Date(objClass[2].replace('T',' '));
            
            if (dateEvent.getTimezoneOffset() < 0){
              dateEvent.setUTCDate(dateEvent.getUTCDate()+1 );
            } else {
              dateEvent.setUTCDate(dateEvent.getUTCDate() + 1 );
            }

            if (dateEventPrior.getTimezoneOffset() < 0){
              dateEventPrior.setUTCDate(dateEventPrior.getUTCDate()+1 );
            } else {
              dateEventPrior.setUTCDate(dateEventPrior.getUTCDate() + 1 );
            }


            const prev = (type === '2'? objClass[1]/100 : objClass[1]*1)  ;
            const prior = (type === '2'? objClass[3]/100 : objClass[3]*1) ;
            const diff =((objClass[1] - objClass[3])/objClass[3]) * 100;
            
            const previousLayout = (<ValueLayoutComponent typeLayout={type} valueRender={prev} /> )
            const priorLayout = (<ValueLayoutComponent typeLayout={type} valueRender={prior} /> )
            
            totalPrev += parseInt(objClass[1])
            totalPrior += parseInt(objClass[3])
            previousDataSet.push((type === '2' ? parseInt(objClass[1])/100 : parseInt(objClass[1])))
            priorDataSet.push((type === '2' ? parseInt(objClass[3])/100 : parseInt(objClass[3])))

            let prefix ='' 
            let prefix_prior = ''

            switch (granularity) {
              case 'quarter':
                prefix = dateUtilities.monthsNamesShort[dateEvent.getUTCMonth()]
                prefix_prior = dateUtilities.monthsNamesShort[dateEventPrior.getUTCMonth()]
                monthsInterval.push(dateUtilities.monthsNamesShort[dateEvent.getUTCMonth()] + ' ' + (dateEvent.getUTCFullYear()).toString())
                monthsIntervalPrior.push(dateUtilities.monthsNamesShort[dateEventPrior.getUTCMonth()] + ' ' + (dateEventPrior.getUTCFullYear()).toString())
  
                break;

              case 'month':
                prefix = dateUtilities.monthsNamesShort[dateEvent.getUTCMonth()]
                prefix_prior = dateUtilities.monthsNamesShort[dateEventPrior.getUTCMonth()]
                monthsInterval.push(dateUtilities.monthsNamesShort[dateEvent.getUTCMonth()] + ' ' + (dateEvent.getUTCFullYear()).toString())
                monthsIntervalPrior.push(dateUtilities.monthsNamesShort[dateEventPrior.getUTCMonth()] + ' ' + (dateEventPrior.getUTCFullYear()).toString())
  
                break;
                  
              default:
                break;
            }
            return <tr>
                <td className="cell-label">{prefix} {dateEvent.getUTCFullYear()}</td>
                {previousLayout}
                <td className="cell-label">{prefix_prior} {dateEventPrior.getUTCFullYear()}</td>
                
                {priorLayout}
                <td className="cell-value" >
                        <div className="cell-value"><FormattedValue type="percent" value={diff} signed={true}/></div>
                </td>
            </tr>
        })

        return {
            tableData: monthData, 
            totalPrevious: totalPrev, 
            totalPrior:totalPrior,
            dataChart: [{label:"Months",data:previousDataSet, labels:monthsInterval}],
            serieLenght:monthData.length
          }
      };
      const dataParsed = (valueMonth)? parseResultSet(valueMonth): null;
      return dataParsed
      //sessionStorage.setItem(keyStorage,JSON.stringify(dataParsed))

    }

    //,type, keyStorage, state
    const avgPrev =data.trendData ? (type === '2' ? ((data.trendData.totalPrevious/100)) :(data.trendData.totalPrevious/data.trendData.serieLenght)) :0;
    const avgPrior = data.trendData ? (type === '2' ? ((data.trendData.totalPrior/100)) :(data.trendData.totalPrior/data.trendData.serieLenght)) :0;
    const avgDiff =data.trendData ?  ((data.trendData.totalPrev - data.trendData.totalPrior)/data.trendData.totalPrior) * 100: 0;
    const previousAvgLayout = (<ValueLayoutComponent typeLayout={type} valueRender={avgPrev} /> )
    const priorAvgLayout = (<ValueLayoutComponent typeLayout={type} valueRender={avgPrior} /> )

    return <>
        {data.trendData && 
           ((keyStorage !== 'customer_trend' && keyStorage !== 'net_month' && data.linesTopCustomers) ||(
           keyStorage === 'customer_trend' || keyStorage === 'net_month')) ?<>
            <Tabs  defaultActiveKey="graph" id="uncontrolled-tab-example" className="mb-3">
            <Tab key={generateUUID(16)} eventKey="graph" title={t('TABS.GRAPH')}  >
                  {istogram === true ?
                    <BarChartRevenue 
                      key={generateUUID(16)}
                      formatLabel={true} 
                      colorMono={true} 
                      dataseries={data.trendData.dataChart} 
                      title="" 
                      Yaxis="$" 
                      fitlerable={false}/> :
                    <LineChartKPI 
                      key={generateUUID(16)}
                      formatLabel={true} 
                      dataSeries={data.trendData.dataChart} 
                      title="" 
                      Yaxis={Yaxis} 
                      granularity={granularity}  
                      filterable={false}
                      filterSeries = {data.linesTopCustomers}
                      />

                  }
                </Tab>
              {keyStorage !== 'customer_trend' && keyStorage !== 'net_month' ?
              <Tab key={generateUUID(16)} eventKey="filter" title={t('TABS.GRAPHFILTERED')} c>
                {istogram === true ?
                  <BarChartRevenue 
                  key={generateUUID(16)}
                    formatLabel={true} 
                    colorMono={true} 
                    dataseries={data.trendData.dataChart} 
                    title="" Yaxis="$"
                    filterable={true}
                    filterSeries = {data.linesTopCustomers}
                  /> :
                  <LineChartKPI 
                    key={generateUUID(16)}
                    formatLabel={true} 
                    dataSeries={data.trendData.dataChart} 
                    title="Filter Trend" 
                    Yaxis={Yaxis} 
                    granularity={granularity}
                    filterable={true}
                    filterSeries = {data.linesTopCustomers}/>
                }
              </Tab>
              :
              <></>}
              {data.linesCohort ?
                  <Tab key={generateUUID(16)} eventKey="graphCohort" title={t('TABS.GRAPH_COHORT')}>
                      <MultiBarStackKPI 
                        key={generateUUID(16)}
                        dataSeries={data.linesCohort} 
                        kindOfGroup='cohort'
                        Yaxis={Yaxis}  
                        granularity={granularity}/>
                  </Tab>
              :
                  <></>
              }
              {data.linesUseCase ?
                  <Tab key={generateUUID(16)} eventKey="graphUseCase" title={t('TABS.GRAPH_USECASE')}>
                    <div>
                      <MultiBarStackKPI 
                        dataSeries={data.linesUseCase} 
                        kindOfGroup='use_cases' 
                        granularity={granularity}
                        Yaxis={Yaxis}  
                        />
                    </div>
                  </Tab>
              :
                  <></>
              }
                
 
                {(queryTrendCohort || queryTrendUseCase) && process.env.REACT_APP_LOGIN==='YES' ?
                    <></>
                :
                <Tab eventKey="table" title={t('TABS.TABLE')}>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th colSpan="2" className="cell-label">{t('TABLES.ROW_HEADERS.LAST')}</th>
                                <th colSpan="2" className="cell-label">{t('TABLES.ROW_HEADERS.PRIOR')}</th>
                                <th  className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.DIFFERENCE')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.trendData.tableData}
                            <tr className='row-summary'>
                                <td className="cell-label">{type === '0' ? t('TABLES.END_ROW.AVG_PERIOD') : t('TABLES.END_ROW.TOTAL')}</td>
                                {previousAvgLayout}
                                <td></td>
                                {priorAvgLayout}                
                                <td className="cell-value">
                                    <FormattedValue type="percent" value={avgDiff} signed={true} />
                                </td>
                                </tr>
                        </tbody>
                
                    </Table> 
                </Tab>
                }
                </Tabs>
            </>
            : 
            <div>Wait...</div>
        }
        </>;
};

export const KPIMonthlyView = React.memo(BaseKPIMonthlyView)