import { useCases } from "./use_cases";

export const total_reveneue_period_v15 = (daterange, kpi) =>{
  const obj = {
    "measures": [
      "MnEvents." + kpi
    ],
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "compareDateRange": daterange
      }
    ],
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.revenue
        
      }
    ]
  }
  return obj
}
export const total_revenue_v15 = (daterange, kpi) =>{
  const obj = {
    "measures": [
      "MnEvents." + kpi
    ],
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "dateRange": daterange
      }
    ],
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.revenue
        
      }
    ]
  }
  return obj
}


export const total_reveneue_period_long_v15 = (daterange, kpi,granularity,dimensions,filter,sort) =>{
   const query = {
    "measures": [
      "MnEvents." + kpi
    ],
    "timeDimensions": [

      {
        "dimension": "MnEvents.timestamp",
        "dateRange": daterange,
        "granularity": granularity
      }
    ],
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.revenue        
      },      
    ]
  }
  if (dimensions) {
    query.dimensions = dimensions
  }
  if (filter) {
    query.filters.push(filter)
  }
  if (sort) {
    query.order = sort
  }
  return query
}


export const historical_revenue_period_v15 = (compareDateRange,kpi) =>{
  return {
    "measures": kpi,
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "compareDateRange": compareDateRange
      }
    ],
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.revenue
        
      }
    ],
  }
}

  export const grossProfitByUseCase = (value, dateRange) => ({
        "measures": [
          "MnEvents.totalAmount"
        ],
        "timeDimensions": [
          {
            "dimension": "MnEvents.timestamp",
            "dateRange": dateRange,
          }
        ],
        "order": {},
        "dimensions": [
          value
        ],
        "filters": [
          {
            "member": "MnEvents.useCase",
            "operator": "equals",
            "values": useCases.revenue
      
          }
        ],
        "limit": 50
      });
       
export const revenueByEvents = (value,dateRange,kpi) =>{
  const query =  {
  "measures": [
    kpi
  ],
  "timeDimensions": [
    {
      "dimension": "MnEvents.timestamp",
      "dateRange": dateRange
    }
  ],
  "dimensions": [
    value
  ],

  "filters": [
    {
      "member": "MnEvents.useCase",
      "operator": "equals",
      "values": useCases.revenue
    },
    {
      "member": value,
      "operator": "set",
      "values": []
    },    
  ],
  "limit": 500,
  "order":[]
  }
  var obj = {};

  obj[kpi] = "desc";
  query.order=obj
  return query
};


export default total_reveneue_period_v15;