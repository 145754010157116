import { GrossRevenueSection } from '../../../components/Sections/GrossRevenue'
import { StoreCreditSection } from '../../../components/Sections/StoreCredit'
import { CustomersSection } from '../../../components/Sections/Customers'
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { GrossProfitSection } from '../../Sections/GrossProfit';
import { NetProfitSection } from '../../Sections/NetProfit';

/**
 * 
 * @returns Data page for every section.
 * 
 */
export const DataPage = () => {
    const { t } = useTranslation() // Define component to use i18n internationalitation strings
    const params = useParams()
    let section = ''
    switch (params.element) {
        case 'customers':
            section = <CustomersSection />
            break

        case 'revenue':
            section = <GrossRevenueSection />
            break

        case 'grossprofit':
            section = <GrossProfitSection />
            break

        case 'netprofit':
            section = <NetProfitSection />
            break
            
        case 'store_credit':
            section = <StoreCreditSection />
            break
        default:
          break
    }

    return <>
        <div className='rounded-3 jumbotron'>
            <h2>{t('SECTION.' + params.element.replace('#', '').toUpperCase() + '.TITLE')}</h2>
        </div>
        {section}
    </>
}