import { useCases } from "./use_cases";

export const netprofit = (compareDateRange, granularity) => {
  return {
    "measures": [
      "Qboobjects.lineAmount"
    ],
    "timeDimensions": [
      {
        "dimension": "Qboobjects.dateDocument",
        "compareDateRange": compareDateRange,
        "granularity": granularity
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Qboobjects.useCase",
        "operator": "equals",
        "values": useCases.margin

      }
    ],
  }
}

export const netProfitByUseCase = (value, dateRange) => ({
  "measures": [
    "MnEvents.netMargin"
  ],
  "timeDimensions": [
    {
      "dimension": "MnEvents.timestamp",
      "dateRange": dateRange,
    }
  ],
  "order": {},
  "dimensions": [
    value
  ],
  "filters": [
    {
      "member": "MnEvents.useCase",
      "operator": "equals",
      "values": useCases.margin
    },
    {
      "member": "MnEvents.netMargin",
      "operator": "set",
    }
  ],
  "limit": 50
});


export const netProfitByTeamMember = (dateRange) => {
  return {

    "measures": [
      "MnEvents.teamMemberRevenue"
    ],
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "dateRange": dateRange
      }
    ],
    "order": {
      "MnEvents.timestamp": "asc"
    },
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.margin

      },
    ],
    "dimensions": []
  }
}

export const netprofit_by_cohort_history = (dateRange, granularity, dimension) => {
  let queryDimension = []
  let queryOrder = [[
    "MnEvents.timestamp",
    "asc"
  ],
  [
    "MnEvents.netMargin",
    "desc"
  ]]
  switch (dimension) {
    case '1':
      queryDimension = ["MnEvents.useCase"]
      break
    case '2':
      queryDimension = ["MnEvents.customerCohort"]
      break
    default:
      break
  }

  return {
    "measures": [
      "MnEvents.netMargin"
    ],
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "dateRange": dateRange,
        "granularity": granularity
      }
    ],
    "order": queryOrder,
    "dimensions": queryDimension,
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.margin

      },
      {
        "member": "MnEvents.customerCohort",
        "operator": "set",
        "values": []
      },

    ]
  }

}

export default netprofit;