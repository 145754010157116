// Application Import
import { useState } from 'react'


export  const useToken =() => {
  const getToken = () => {
    const tokenString = localStorage.getItem('token') 
    if (tokenString === 'undefined'){
      return null
    }
    const userToken = JSON.parse(tokenString)
    return userToken
  }

  const [token, setToken] = useState(getToken())

  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken.token))
    localStorage.setItem('refresh_token',JSON.stringify(userToken.refresh_token))
    setToken(userToken.token);
  }

  return {
    setToken: saveToken,
    token
  }
}

export const destroyToken= () =>{
  localStorage.removeItem('token')
  return null 
}