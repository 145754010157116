
// Hooks Import
import { useState, useEffect } from 'react'
import { getApiData } from '../../common/APICalls/apiAdministration'
// Queries Import
import { teammember_monthlydetail } from '../../kpi_queries/team_members'

// Component Import
import { QueryCubeRemote } from '../../common/APICalls/apiCalls'
import { Table } from 'react-bootstrap'

// CSS Import
import './Graph.css'

/** 
 * Render the Chart Bar Legend during the mouse over on chart bar for FTE across time  
 * @param {JSON} tooltip tooltipo obkect passed by caller
*/
export const BarChartTeamLegend  = ({refDate}) =>{

  const[data, setData] = useState([])    // State variable used to render component content 
  const[ apiResponse , setApiResponse] = useState([])
  //Query the back end to obtain the list of team mem 
  const teamFTEDetails = () => {
    if (refDate){
    const params = {
      refDate :refDate.refDate,
    }
    getApiData('personnel/list/',{params}).then((response) =>{
        if (response ){
          setApiResponse(response)
        }    
    })
    }
  }

  useEffect(()=>{
    teamFTEDetails()
  },[refDate])
  // Return the JSX component redered.
  return <>
      <div className='tooltip'  style={{ display: refDate.refDate === '' ? 'none' : 'inline', opacity:1 }}>
      {/** Total Number FTE  */}
      <div className='tooltip-title'>Total FTE for {refDate.refLabel}: {apiResponse.length}</div>
  
      {/** Table Name FTE  */}
      <div className='tooltip-content'>
        <Table striped className='tooltip-table'>      
          <tbody className='tooltip-tbody'>
            { apiResponse.map((k) => {
              let labelname = k['lastname'] + ' ' + k['name'].trimStart().charAt(0) + "."
              return <tr>
                <td className='tooltip-td'>{labelname}</td>
                <td className='tooltip-td'>{k['flFTE']}</td>
              </tr>
            })
            
            }
          </tbody>
        </Table>
      </div>
    </div>
    <div className='tooltip'  style={{ display: refDate.refDate === '' ? 'inline' : 'none', opacity: 1 }}>
      Click on an Histogram to see details.
    </div>
    </>
}