export const dateUtilities = {
  "monthsNames": [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  "Quarters": [
    "Q1",
    "Q1",
    "Q1",
    "Q2",
    "Q2",
    "Q2",
    "Q3",
    "Q3",
    "Q3",
    "Q4",
    "Q4",
    "Q4"
  ],
  "monthsNamesShort": [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],

}

export const weekNumber = (d) => {
  d = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  return [d.getUTCFullYear(), weekNo];
}

export const calculateDateRange = (e) => {

  const reference_date = new Date(process.env.REACT_APP_REFERENCE_DATE)
  let startdate = new Date(process.env.REACT_APP_REFERENCE_DATE)
  startdate.setMonth(reference_date.getUTCMonth() - e)
  const current_start = startdate.getUTCFullYear() + '-' + ("0" + (startdate.getUTCMonth() + 1)).slice(-2) + '-01';
  const current_end = reference_date.getUTCFullYear() + '-' + ("0" + (reference_date.getUTCMonth() + 1)).slice(-2) + '-' + reference_date.getUTCDate()

  const priorenddate = new Date(current_start)
  priorenddate.setUTCDate(priorenddate.getUTCDate() - 1)

  let priordate = new Date(current_start)
  priordate.setUTCMonth(priorenddate.getUTCMonth() - e)

  const prior_start = priordate.getUTCFullYear() + '-' + ("0" + (priordate.getUTCMonth() + 1)).slice(-2) + '-01';
  const prior_end = priorenddate.getUTCFullYear() + '-' + ("0" + (priorenddate.getUTCMonth() + 1)).slice(-2) + '-' + priorenddate.getUTCDate()
  return [current_start + ';' + current_end, prior_start + ';' + prior_end]
}

export const lastDayMonth = (date) => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0).getUTCDate();
}

export const referencePlusOneDay = (stringDate) => {
  const reference = new Date(stringDate);
  reference.setUTCDate(reference.getUTCDate() + 1);
  return reference.toISOString().split('T')[0]
}

/**
* Format date in YYYY-MM-DD format. Most common. 
* @param {*} date date string   
*/
export const formatDate = (date) => {

  let d = new Date(date),
    month = '' + (d.getUTCMonth() + 1),
    day = '' + d.getUTCDate(),
    year = d.getUTCFullYear()
  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-')
}


/**
 * Return the year to date (reference) range for current and prior period    
 */
export const YearToDateRange = () => {

  const today = new Date(process.env.REACT_APP_REFERENCE_DATE)
  const ytd = [formatDate(new Date(today.getUTCFullYear(), 0, 1, 1)), formatDate(today)]
  const today_prior = new Date(today.setFullYear(today.getUTCFullYear() - 1));
  const ytd_prior = [formatDate(new Date(today_prior.getUTCFullYear(), 0, 1, 1)), formatDate(today_prior)]

  return [ytd, ytd_prior]
}

export const StringToUTCDate = (value) => {
  const components = value.split('-')
  return new Date(Date.UTC(components[0],components[1],components[2]))
}

export const returnQuarterBoundaries = (quarter,year) => {
  switch (quarter) {
    case 1:
      return  [ year + '-01-01', year + '-03-31' ]
    case 2:
      return  [ year + '-04-01', year + '-06-30' ]
    case 3:
      return  [ year + '-07-01', year + '-09-30' ]
    case 4:
      return  [ year + '-10-01', year + '-12-31' ]
            
    default:
      return;
  }
}

export const previousQuarterLimits = () => {
  const today = new Date();
  const quarter = Math.floor((today.getMonth() / 3));
  const startFullQuarter = new Date(today.getFullYear(), quarter * 3 - 3, 1);
  const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
  return [startFullQuarter.toISOString(), endFullQuarter.toISOString()]
}

export const subtractYears = (date,years) => {
  date.setFullYear(date.getFullYear() - years);
  return date
}

export const yearShift=(granularity)=>{
  switch (granularity) {
  case 'quarter':
    return 4;
  default:  // Quarter
    return 4; 
 }
}


// ##TODO These two function can be aggregate in one ## 
export const formatLabelDate = (date,granularity) => {
  switch (granularity) {
    case 'quarter':
      return dateUtilities.Quarters[date.getUTCMonth()]

    case 'month':
      return dateUtilities.monthsNamesShort[date.getUTCMonth()]
        
    default:
      break
  }

}

export const quarterOrMonth = (dateString, granularity) => {
  const date = new Date(dateString)
  if (date.getTimezoneOffset() < 0){
    date.setUTCDate(date.getUTCDate()+1 );
  } else {
    date.setUTCDate(date.getUTCDate() + 1 );
  }

  switch (granularity) {
    case 1:
    case 'month':
      return dateUtilities.monthsNamesShort[date.getUTCMonth()] + ' ' + date.getUTCFullYear();

    case 3:
    case 'quarter':
      return dateUtilities.Quarters[date.getUTCMonth()] + ' ' + date.getUTCFullYear();
    case 'quarterreverse':
      return date.getUTCFullYear() + ' ' + dateUtilities.Quarters[date.getUTCMonth()] ;

    default:
      return dateUtilities.monthsNamesShort[date.getUTCMonth()] + ' ' + date.getUTCFullYear();
    }
}
