import {useState, useEffect, useContext} from 'react'
import { Context } from '../App';

import {useCubeQuery} from '@cubejs-client/react'
import { Tab, Tabs } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import {dateUtilities} from '../utilities/date_formatting'
import {FormattedValue} from '../common/FormattedValues/FormattedValues'
import {LineChartKPI} from '../components/Graph/LineChart'
import { useTranslation } from 'react-i18next'
import { quarterOrMonth } from '../utilities/date_formatting';
import { ValueLayoutComponent } from '../utilities/ui_tools';

export const KPIMonthlyStoreCreditView = ({query ,type}) =>{
  const[data, setData] =useState()
  const {resultSet:valueMonth} = useCubeQuery(query);
  const { t } = useTranslation()
  const [state] = useContext(Context)
  const [previousAvgLayout, setPreviousAvgLayout] = useState()
  const [priorAvgLayout, setPriorAvgLayout] = useState()
  const [avgDiff, setAvgDiff] = useState()
  const weekNumber =(d) =>{
      d = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()));
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
      return [d.getUTCFullYear(), weekNo];
  }

    useEffect(() => {
      const parseResultSet = (valueMonth) =>{

        const pivotedResult = valueMonth.tablePivot({fillMissingDates:true})
        const half = Math.ceil(pivotedResult.length / 2); 
        const firstSerie = pivotedResult.slice(0,half)
        const secondSerie = pivotedResult.slice(-half)
        let aggregatedResult = []
        for (let i = 0; i < firstSerie.length; i++) {
          const objClassFirst = Object.keys(firstSerie[i]).map((j) => {
            return secondSerie[i][j];
          });
          const objClassSecond = Object.keys(secondSerie[i]).map((j) => {
            return firstSerie[i][j];
          });

           aggregatedResult.push([objClassFirst[0],objClassFirst[1],objClassSecond[0],objClassSecond[1]]  )
        } 

        let totalPrev = 0
        let totalPrior = 0
        let previousDataSet = []
        let priorDataSet =[]
        let monthsInterval = []
        let monthsIntervalPrior = []

        const monthData = aggregatedResult.map((objClass) => {

          const dateEvent = new Date(objClass[0]);
          const dateEventPrior = new Date(objClass[2]);

          const prev = (type === '2'? (objClass[1]/100 - parseFloat(process.env.REACT_APP_SC_CORRECTION_USD)) : objClass[1]*1)
          const prior = (type === '2'? (objClass[3]/100 - parseFloat(process.env.REACT_APP_SC_CORRECTION_USD)) : objClass[3]*1)
          const previousLayout = (<ValueLayoutComponent typeLayout={type} valueRender={prev*-1} /> )
          const priorLayout = (<ValueLayoutComponent typeLayout={type} valueRender={prior*-1} /> )
          const diff =((prev - prior)/prior) * 100;
            
          const prevMonth = parseInt(objClass[1]-process.env.REACT_APP_SC_CORRECTION_CENTS)
          const priorMonth  =  parseInt(objClass[3]-process.env.REACT_APP_SC_CORRECTION_CENTS) 

            totalPrev += prevMonth
            totalPrior += priorMonth
            previousDataSet.push((type === '2' ? prevMonth/100 : prevMonth)* -1)
            priorDataSet.push((type === '2' ? priorMonth/100 : priorMonth) * -1)

            let prefix ='' 
            let prefix_prior = ''

            switch (state.granularity) {
              case 'month':
                prefix = dateUtilities.monthsNamesShort[dateEvent.getUTCMonth()]  + " " + dateEvent.getUTCFullYear()
                prefix_prior = dateUtilities.monthsNamesShort[dateEventPrior.getUTCMonth()] +  " " + dateEventPrior.getUTCFullYear()
                break;
              case 'quarter':
                prefix =quarterOrMonth(dateEvent.toISOString(),'quarter')
                prefix_prior = quarterOrMonth(dateEventPrior.toISOString(),'quarter')
                break;
  
                  
              default:
                break;
            }
            monthsInterval.push(prefix)
            monthsIntervalPrior.push(prefix_prior)

            return <tr>
                    <td className="cell-label">{prefix}</td>
                    {previousLayout}
                    <td className="cell-label">{prefix_prior}</td>
                    {priorLayout}
                    <td className="cell-value" >
                      <div className="cell-value"><FormattedValue type="percent" value={diff} signed={true} /></div>
                    </td>
                </tr>
          })
          const parsedValue = [
            monthData.reverse(), 
            totalPrev, 
            totalPrior,
            [{label:"Months",data:priorDataSet.concat(previousDataSet), labels:monthsIntervalPrior.concat(monthsInterval)},half]]

            prepareAverageRow(parsedValue)
            return parsedValue
      };
      const dataParsed =[(valueMonth)? parseResultSet(valueMonth): []];
      setData(dataParsed[0])
    },[valueMonth,type,state]);

    const prepareAverageRow = (data) => {
      if (data && data.length > 0) {
        const avgPrev =(type === '2' ? (data[1]/100)/data[0].length  :(data[1]/data[4])) ;
        const avgPrior = (type === '2' ? (data[2]/100/data[0].length) :(data[2]/data[4])) ;
        setAvgDiff(((data[1] - data[2])/data[2]) * 100)
        setPreviousAvgLayout(<ValueLayoutComponent typeLayout={type} valueRender={avgPrev*-1} /> )
        setPriorAvgLayout(<ValueLayoutComponent typeLayout={type} valueRender={avgPrior*-1} /> )
      }
    }

    return <>
        {data ?<>
        <Tabs  defaultActiveKey="graph" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="graph" title={t('TABS.GRAPH')}>
                <LineChartKPI 
                  granularity={state.granularity} 
                  formatLabel={false} 
                  dataSeries={data[3]} 
                  title="" 
                  Yaxis="$"
                  filterable={false}
                  />
            </Tab>
            <Tab eventKey="table" title={t('TABS.TABLE')}>
            <Table striped bordered>
             <thead>
                    <tr>
                        <th colSpan="2" className='cell-label'>{t('TABLES.ROW_HEADERS.LAST')}</th>
                        <th colSpan="2" className='cell-label'>{t('TABLES.ROW_HEADERS.PRIOR')}</th>
                        <th  className='cell-value'>{t('TABLES.ROW_HEADERS.DIFFERENCE')}</th>
                    </tr>
                </thead>
                <tbody>
                    {data[0]}
                    <tr className='row-summary'>
                        <td className="cell-label">Average Period</td>
                        {previousAvgLayout}
                        <td></td>
                        {priorAvgLayout}
                        <td className="cell-value">
                            <FormattedValue type="percent" value={avgDiff} signed={true}/>
                        </td>
                        </tr>
                </tbody>
        
            </Table> 
            </Tab>
        </Tabs>
        </>
        : 
            <div>Wait...</div>
        }
    </>;
};


/*
*/