import React from 'react'
import { Form, Button, FormGroup } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { getApiAdministration, postApiAdministration } from '../../common/APICalls/apiAdministration';
import { dateUtilities } from '../../utilities/date_formatting';
import { useTranslation } from 'react-i18next';

import './ExecutionComponent.css'

export const ExecutionComponent = ({title, groupsEndpoint, executionEndpoint, batch}) => {
  const [groups,setGroups]= useState([])
  const [timeFrames, setTimeFrames] = useState({years:[],months: []})
  const { t } = useTranslation();
  const [elementsToCalculate,setElementsToCalculate] = useState({year:0, month:0, group:'',enddate: process.env.REACT_APP_START_MONTH_NEXT})

  const handleGroups = () => {
      getApiAdministration(groupsEndpoint).then((response) =>{
          if (response ){
              setGroups(response)
          }    
      })
  }
  const handleTimeFrames = () => {
    getApiAdministration('events/monthsyears').then((response) =>{
        if (response ){
            setTimeFrames(response)
        }    
    })
  }

  const changeYear = (e) =>{
    setElementsToCalculate({
      ...elementsToCalculate,
      year: e.target.value
    })
  }
  const changeMonth = (e) =>{
    setElementsToCalculate({
      ...elementsToCalculate,
      month: e.target.value
    })
  }
  const changeGroup = (e) =>{
    setElementsToCalculate({
      ...elementsToCalculate,
      group: e.target.value
    })
  }
  
  const executeForm =(e)=>{
    e.preventDefault();
    postApiAdministration(executionEndpoint,elementsToCalculate).then((response) => {
      if (response.again) {
        return recursivePost (batch, elementsToCalculate)
      }
    })
  }

  function recursivePost(endpoint,elements) {
      postApiAdministration(endpoint,elements).then((response) => {
        if (response.again) {
          return recursivePost (batch, elementsToCalculate)
        }
      })
        
  }

  useEffect(() => {
      handleGroups()
      handleTimeFrames()
  },[])


  return(<Form onSubmit={executeForm} className='form_size'>  
        <FormGroup >
          <Form.Label className="controllabel">{title} {t('SECTION.ADMINISTRATION.EXECUTION_FORM.EXECUTION_GROUP')}</Form.Label>
          <Form.Select id='CAMGroupSelect' onChange={changeGroup}>
              <option value=''>-</option>
              {groups.map((channel) =>{
                  return <option
                      value={channel.id}>
                          {channel.name}
                      </option>
              }
              )}
          </Form.Select>
        </FormGroup>
        <Form.Group>
        <Form.Label className="controllabel">{t('SECTION.ADMINISTRATION.EXECUTION_FORM.EXECUTION_YEAR')}</Form.Label>
          <Form.Select id='Year' onChange={changeYear}>
              <option value=''>-</option>
              {timeFrames.years.map((year) =>{
                  return <option
                      value={year.MnYearTransaction}>
                          {year.MnYearTransaction}
                      </option>
              }
              )}
          </Form.Select>
        </Form.Group>
      <Form.Group >
      <Form.Label className="controllabel">{t('SECTION.ADMINISTRATION.EXECUTION_FORM.EXECUTION_MONTH')}</Form.Label>
      <Form.Select id='Month' onChange={changeMonth}>
          <option value=''>-</option>
          {timeFrames.months.map((month) =>{
              return <option
                  value={month.MnMonthTransaction}>
                      {dateUtilities.monthsNames[month.MnMonthTransaction-1]}
                  </option>
          }
          )}
      </Form.Select>
    </Form.Group>
    <Form.Group className="float-right" >      
      <Button  variant="outline-primary" 
              type="submit">
        {t('SECTION.ADMINISTRATION.EXECUTION_FORM.EXECUTION_EXECUTE')}
      </Button>
    </Form.Group>
  </Form>)

}