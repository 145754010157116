import {useState, useEffect, useContext} from 'react';
import { Context } from '../App';
import {useCubeQuery} from '@cubejs-client/react';
import {revenueByEvents} from '../kpi_queries/gross_revenue'

import Table from 'react-bootstrap/Table'
import { FormattedValue } from '../common/FormattedValues/FormattedValues';
import { Container, Row, Col, Card } from 'react-bootstrap'
import { FullDoughnuthKPI } from './Graph/FullDoughnuth'
import { BarChartKPINoOver } from './Graph/Istogram';

import {groupValues} from '../utilities/array_manipulation'
import { useTranslation } from 'react-i18next';
import { generateUUID } from '../utilities/ui_tools';

export const KPICountryGroup= ({referencesDates,groupDimension,referenceTotal, kpi}) =>{

    const [data, setData] =useState([])
    const [state] = useContext(Context)

    const [totalValue, setTotal] = useState(0)

    const {resultSet:revenueByCountry} = useCubeQuery(revenueByEvents(groupDimension,referencesDates.split(';'),kpi));
    const {resultSet:totalRevenuePeriod} = useCubeQuery (referenceTotal);

    const { t } = useTranslation();

    useEffect(() => {
        const parseResultSet = (revenueByCountry,totalRevenuePeriod) =>{
          let totalFromQuery = 0
            const revenue =Object.keys(totalRevenuePeriod.tablePivot()[0]).map(function(key){
              let revenue = totalRevenuePeriod.tablePivot()[0][key];
              return revenue
            });
            setTotal(parseInt(revenue[0]))
            const countriesData = revenueByCountry.tablePivot().map((k) => {
                const objClass = Object.keys(k).map((i) => {
                  return k[i];
                });
                totalFromQuery += parseInt(objClass[1])
                return [objClass[0],objClass[1]/100,totalFromQuery/100]
            });
            return groupValues (countriesData,(parseInt(revenue[0])/100),true,"money",null, kpi === 'MnEvents.netMargin')
        };

        const dataParsed =(revenueByCountry && totalRevenuePeriod) ? parseResultSet(revenueByCountry, totalRevenuePeriod) : [];
        setData(dataParsed)
    },[totalRevenuePeriod,revenueByCountry]);



    return <>{data[0] ?
          <>
          {kpi !== 'MnEvents.netMargin' ?
          <>
          <Row key={generateUUID(16)} md={2} lg={2} className="g-5 car row_kpi">
            <Col className="col_kpi">
              <Card className="card_kpi">
                <Card.Title>{t('KPIs.TITLES.TOTAL')}</Card.Title>
                <Card.Body>
                  <div className='chart-md'>
                  <FullDoughnuthKPI key={generateUUID(16)}  values={data[1][0]} labelsIn={data[0][0]} />
                  </div>
                  <Table bordered striped>
                    <thead>
                      <tr key={generateUUID(16)} >
                        <th className='cell-emphasis cell-label'></th>
                        <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.AMOUNT')}</th>
                        <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.PERCENT')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data[2][0]}
                      <tr className='row-summary'>
                        <td className="cell-label grouped-cell">{t('TABLES.END_ROW.TOTAL')}</td>
                        <td className="cell-value grouped-cell"><FormattedValue type="money" value={parseInt(totalValue)/100} /></td>
                        <td className="cell-value grouped-cell"><FormattedValue type="percent" value={100} /></td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col_kpi">
            <Card className="card_kpi">
                <Card.Title>{t('KPIs.TITLES.COUNTRIES_NON_US')}</Card.Title>
                <Card.Body>
                  <div className='chart-md'>
                    <FullDoughnuthKPI key={generateUUID(16)}  values={data[1][1]} labelsIn={data[0][1]} />
                  </div>
                  <Table bordered striped>
                    <thead>
                      <tr key={generateUUID(16)} >
                        <th className='cell-emphasis cell-label'></th>
                        <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.AMOUNT')}</th>
                        <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.PERCENT')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data[2][1]}
                    </tbody>
                  </Table>   
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Container fluid>
            <Row id="Graph">
            </Row>
            <Row id="Tables">
              <Col md={6}>
              </Col>
            </Row>
          </Container> </>:
            <>
          <Row key={generateUUID(16)} md={2} lg={2} className="g-5 car row_kpi">
          <Col className="col_kpi">
            <Card className="card_kpi">
              <Card.Title>{t('KPIs.TITLES.TOTAL')}</Card.Title>
              <Card.Body>
                <div className='chart-md'>
                <BarChartKPINoOver 
                  key={generateUUID(16)}
                  dataseries={{title:'US vs. Non US', dimension: data[0][0], data: data[1][0]}} 
                  colorMono={false}  
                  Yaxis="$"
                  Xaxis="Country Type" /> 
                </div>
                <Table bordered striped>
                  <thead>
                    <tr key={generateUUID(16)} >
                      <th className='cell-emphasis cell-label'></th>
                      <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.AMOUNT')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data[2][0]}
                    <tr className='row-summary'>
                      <td className="cell-label grouped-cell">{t('TABLES.END_ROW.TOTAL')}</td>
                      <td className="cell-value grouped-cell"><FormattedValue type="money" value={parseInt(totalValue)/100} /></td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col_kpi">
          <Card className="card_kpi">
              <Card.Title>{t('KPIs.TITLES.COUNTRIES_NON_US')}</Card.Title>
              <Card.Body>
                <div className='chart-md'>
                <BarChartKPINoOver 
                  key={generateUUID(16)}
                  dataseries={{title:'Top Non US', dimension: data[0][1], data: data[1][1]}} 
                  colorMono={false}  
                  Yaxis="$"
                  Xaxis="Countries" />
                </div>
                <Table bordered striped>
                  <thead>
                    <tr key={generateUUID(16)} >
                      <th className='cell-emphasis cell-label'></th>
                      <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.AMOUNT')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data[2][1]}
                  </tbody>
                </Table>   
              </Card.Body>
            </Card>
          </Col>
          </Row>
          <Container fluid>
          <Row id="Graph">
          </Row>
          <Row id="Tables">
            <Col md={6}>
            </Col>
          </Row>
          </Container>
          </>
          }
          </>
          :
          <></>
        }

    </>
}