// Hooks Import
import {useState, useEffect} from 'react';

// Graph Type Import
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

// Color Palette import 
import { palette } from '../../utilities/ui_tools';

// Chart Configuration Option 
const options = {
  plugins:{
    legend: {
      display: false,   // Display legend
    }
  },
  maintainAspectRatio:false,  
  elements: {
    arc: {
      borderWidth: 0    // Arc border width
    }
  },
  cutout: 80,     
}


/**
 * 
 * @param {*} values    Dataset values 
 * @param {*} labelsIn  Label for graph series
 * @returns Rendered Doughnuth Graph inside JSX component
 * This Graph is used for all doughnugth grphas where total is 100%
 * 
*/
export const FullDoughnuthKPI =({values,labelsIn, keyStorage}) => {

  const [renderValues, setRenderValues] = useState()  // State variable used to render compoi
  
  // The useEffect Hook is triggered when labels or values .
  // The query result is parsed and is returned a table row.
  useEffect(()=>{        

    if (values && labelsIn) {
      setRenderValues({
        labels: labelsIn,
        datasets: [{
          label: '',
          data: values,
          labels: labelsIn,
          backgroundColor:palette({colorMono: null, storeKey:keyStorage}),
          borderWidth: 1
        }]              
      })

    }
  },[labelsIn, values])
  
  
    // Render JSX Component
    // The Doughnut is rendered if is passed a not null value oterwise an empty div is returned
    return (<>
     {renderValues ?
        <Doughnut 
          options={options}
          data={renderValues} 
          height={'200px'}
        />
        :
        <></>
      }
      </>)
  }