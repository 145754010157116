import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';

import { Context } from '../App';
import { useCases } from '../kpi_queries/use_cases';

import {FullDoughnuthKPI} from './Graph/FullDoughnuth'
import { SquareLegend, generateUUID } from '../utilities/ui_tools';

import Table from 'react-bootstrap/Table'
import { Container,Row, Col } from 'react-bootstrap';

import {FormattedValue} from '../common/FormattedValues/FormattedValues'
import { ValueLayoutComponent } from '../utilities/ui_tools';

import { BarChartKPINoOver } from './Graph/Istogram';
export const TotalByUseCase = ({query, groupDimension, referenceTotal}) =>{

    const[data, setData] =useState([])
    const [state] = useContext(Context)

    const {resultSet:useCaseData} = useCubeQuery(query(groupDimension,state.date_range.split(';')))
    const {resultSet:totalPeriod} = useCubeQuery (referenceTotal)
    const { t } = useTranslation()

    useEffect(() => {

        const parseResultSet = (useCaseData,totalPeriod) =>{
          let total = 0
          let amountResultset = []
          let labelsResultset = []

          const totalPrevYear = Object.keys(totalPeriod.tablePivot()[0]).map(function(key){
            let numberusers = totalPeriod.tablePivot()[0][key];
            return numberusers
          });

          const countriesData = useCaseData.tablePivot().map((k) => {
            const objClass = Object.keys(k).map((i) => {
              return k[i];
            });
            total += parseInt(objClass[1])
            return [objClass[0],objClass[1],total]
          })

          const dataFormatted = countriesData.map((objClass,index) => { 
            amountResultset.push(objClass[1]/100)
            labelsResultset.push((objClass[0])? useCases.description_short[objClass[0]] : "N/A")
            return objClass[1] ? <tr key={generateUUID(16)}>
                <td key={generateUUID(16)}  className="cell-label"><SquareLegend indexColor={index} keyStorage={''}/>&nbsp;{(objClass[0])? useCases.description[objClass[0]] : "N/A"}</td>
                <td key={generateUUID(16)} className="cell-value" ><FormattedValue type="money" value={objClass[1]/100}/></td>
            </tr>
            :
            <></>
          })
          return [dataFormatted,totalPrevYear, total,{label:"month",dimension:labelsResultset, data:amountResultset}];
        }
        const dataParsed =[(useCaseData && totalPeriod)? parseResultSet(useCaseData,totalPeriod): []];
        setData(dataParsed[0])
    },[useCaseData, totalPeriod]);


    const prev =  data[1]/100 ;
    const notAvailable = data[1] - data[2] 

    const previousAvgLayout = (<ValueLayoutComponent valueRender={prev} typeLayout={'2'} /> )

    return data ?
     <Container>
      <Row>
      <Col className='center-vertical'>
          {data.length >=3 &&  data[3].data ?
              <BarChartKPINoOver 
              key={generateUUID(16)}
              dataseries={data[3]} 
              colorMono={false}  
              Yaxis="$"
              Xaxis='Use Cases' /> 
          :
          <></>}
        </Col>

      </Row>
     <Row>
      <Col md={12}>
      <Table striped bordered>
        <thead>
          <tr>
            <th  className='cell-emphasis cell-label'>{t('TABLES.ROW_HEADERS.GROUP')}</th>
            <th  className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.AMOUNT')}</th>
          </tr>
        </thead>
        <tbody>
          {data[0]}
          {notAvailable>0 ?
          <tr>
              <td className="cell-label">N/A</td>
              <td className="cell-value"><FormattedValue type="money" value={notAvailable/100} /></td>

          </tr>
          :
          <></>
          }
          <tr className='row-summary'>
              <td className="cell-label">Total Period</td>
              {previousAvgLayout}
          </tr>

        </tbody>
      </Table>
      </Col>
     </Row>
    </Container>
    :
    <></>
}