import React,{useState} from 'react'
import PropTypes from 'prop-types'
import './Login.css'
import  { Navigate } from 'react-router-dom'
import { Form, Button, Image, Alert, FloatingLabel } from 'react-bootstrap'

async function loginUser(credentials) {
    return fetch(process.env.REACT_APP_BI_API_ROOT + 'login_check', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
    .then(data => data.json())
}
export const  Login = ({setToken}) => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [errorMsg, setErrorMsg] = useState('');
    const handleSubmit = async e => {
        e.preventDefault();
        if (email === undefined || password === undefined || email === '' || password === '') {
          setErrorMsg('Please Specify Email and Password!')
        } else {
          const loginResponse = await loginUser({
            email,
            password
        });
        if (loginResponse) {
            if (loginResponse.token) {
              setToken(loginResponse);
              return <Navigate to='/'  />
            } else {
              localStorage.clear()
              setErrorMsg(loginResponse.message)
            }
        } else {
          setErrorMsg('No service response. Contact Support!')
        }

        }
    }
    return(<div className='login-container bg-gradient-primary'>
        <div className='login-wrapper'>
            <div className='login-header'>
                <Image src='mn-logo-2.png'  />
                <h5>Business Intelligence System</h5>
            </div>
            <Form  onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="username">
              <FloatingLabel controlId = 'SelectTimeInterval' label = "Email" >
                  <Form.Control type="text" onChange={e => setEmail(e.target.value)} />
              </FloatingLabel>
            </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <FloatingLabel controlId = 'SelectTimeInterval' label = "Password" >
                    <Form.Control type="password" onChange={e => setPassword(e.target.value)} />
                  </FloatingLabel>
                </Form.Group>
            <Button variant="primary" type="submit" className='login-button'> 
                Submit
            </Button>
            <Alert key='danger' variant='danger' className={errorMsg !== '' ?  'login-error-box showComponent' : 'login-error-box hideComponent'}>
                {errorMsg}
            </Alert>
            </Form>
        </div>
    </div>
  )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};