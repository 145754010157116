import React from 'react'
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { CostData } from '../CostData/CostData';
import { FTEs } from '../FTE/FTEs';
import { PersonnelFTEs } from '../FTE/PersonnelFTEs';
export const AdminTabEditing = () => { 
  const { t } = useTranslation()
  const [itemDisplay, setItemDisplay]= useState(<PersonnelFTEs />)
  const [selectedItem, setSelectedItem] = useState('fte')

  const selectItem = (item) => {
    setSelectedItem(item)
    switch (item) {
        case "fte":
            setItemDisplay(<PersonnelFTEs />)
            break;
        case "cost":
            setItemDisplay(<CostData />)
            break;
        default:
            setItemDisplay(<></>)
            break;
    }
  }
  return  <>
      <Navbar bg="primary" data-bs-theme="dark" className='navbar-primary' default >
      <Container>
          <Nav className="me-auto" defaultActiveKey="group">
              <Nav.Link  className={selectedItem ==='fte' ? 'nav-link--active' :'navlink--item'}   eventKey="fte" onClick={() => selectItem("fte")}>{t('SECTION.ADMINISTRATION.EDITING.FTE')}</Nav.Link>
              <Nav.Link  className={selectedItem ==='cost' ? 'nav-link--active' :'navlink--item'}   eventKey="cost" onClick={() => selectItem("cost")} >{t('SECTION.ADMINISTRATION.EDITING.COSTDATA')}</Nav.Link>
          </Nav>
      </Container>
      </Navbar>
      {itemDisplay}
</>
}
