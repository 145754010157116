import {useState, useEffect, useContext} from 'react';
import { useCubeQuery } from '@cubejs-client/react';

import { Context } from '../App';

import { FormattedValue } from '../common/FormattedValues/FormattedValues';
import { ChurnRate, ChurnRateSimple } from '../logic/CalculatedValues';
import { MemoHalfDoughnutKPI } from './Graph/HalfDoughnut';

import { customers_by_months_long } from '../kpi_queries/customers';
import { total_reveneue_period_v15 } from '../kpi_queries/gross_revenue';
import { QueryPeriod } from '../logic/CalculatedValues';


export const LTVWidget = ({kpi, lookback}) =>{
  const cachedData = JSON.parse(sessionStorage.getItem("LTV"))
  const [state] = useContext(Context)

    const[data, setData] = useState()

    const churn = ChurnRateSimple(        
      [state.date_range.split(';')[1].slice(0,-2) + '01'],
      'month',
      state.lookback
    )

    const churnPrior = ChurnRateSimple(        
      [state.date_range_prior.split(';')[1].slice(0,-2) + '01'],
      'month',
      state.lookback
    )

    /* Fix here period !!!!*/


    const {resultSet:average_customers} = useCubeQuery(customers_by_months_long([state.data_series_date_interval_prior[0],state.data_series_date_interval[1]],state.granularity))
    const totalRevenue  = QueryPeriod([state.date_range.split(';'), state.date_range_prior.split(';')], total_reveneue_period_v15, 'totalAmount')
    


    useEffect(() => {
    if (churn && churnPrior && average_customers && totalRevenue){
      

        const sumrevenue = parseInt(totalRevenue[0]/100)
        const sumRevenuePrior = parseInt(totalRevenue[1]/100)
        const change = ((sumrevenue - sumRevenuePrior) / sumRevenuePrior) * 100

        const customersMonth = average_customers.tablePivot().map((k) => {
            const objClass = Object.keys(k).map((i) => {
              return k[i];
            });
            return objClass
        })

        const customersMonthPrev = customersMonth.slice( state.lookback * -1)
        const customersMonthPrior = customersMonth.slice( state.lookback * -2, state.lookback * -1)
        
        const sumCustomers = customersMonthPrev.reduce((a,b)=>a+parseInt(b[1]),0)
        const sumCustomersPrior = customersMonthPrior.reduce((a,b)=>a+parseInt(b[1]),0)

        const avgCustomers = (sumCustomers / customersMonthPrev.length)  || 0;
        const avgCustomersPrior = (sumCustomersPrior / customersMonthPrior.length) || 0;

        const arpu = Math.round(((sumrevenue)/avgCustomers)) 
        const arpuPrior = Math.round((sumRevenuePrior)/avgCustomersPrior)

        let multiplier = 12 / parseInt(state.lookback) 
        const LTVValue = (arpu/(churn.churn/100))
        const LTVValuePrior = ((arpuPrior)/(churnPrior.churn/100))

        let MoneyValue = 0
        let VariationValue = 0.0
        switch (kpi) {
          case 'arpu':
            const multiplierArpu =  (lookback === 12 ?1 : lookback === 3 ? 4 : 12)
            MoneyValue = arpu * multiplierArpu
            VariationValue = ((arpu-arpuPrior)/arpuPrior) *100
            
            break;
        
          default:
            MoneyValue = LTVValue
            VariationValue = ((LTVValue-LTVValuePrior)/LTVValuePrior) *100
            break;
        }
        const dataParsed =(churn && average_customers && totalRevenue)? {MoneyValue: MoneyValue, Variation: VariationValue} :null;
        setData(dataParsed)
        sessionStorage.setItem("LTV",JSON.stringify(dataParsed))
      }
    },[churn, average_customers, totalRevenue, state.lookback, churnPrior, kpi, lookback]);
    
    return <>
      {data ?
      <>
        <FormattedValue type="money"  value={data.MoneyValue} />
        <div className="chart-xs">
          <MemoHalfDoughnutKPI value={data.Variation} ascending={true} heightPx={'200px'} signed={true} />
        </div>
      </>
      :
      <></>
    }
    </> 
};