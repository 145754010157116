// System Import

// GUI Component Import
import { FormattedValue } from "../common/FormattedValues/FormattedValues";

//Utilities Import
import { dateUtilities } from "./date_formatting";
import { yearShift, formatLabelDate } from "./date_formatting";

export const FormatCustomerKPIs = ({pivotedResult,type,granularity}) =>{

  let aggregatedResult = []
  for (let i = 0; i < pivotedResult.length; i++) {


    const objClassFirst = Object.keys(pivotedResult[i]).map((j) => {
      const mapped = pivotedResult[i][j] 
      return mapped
    })

     aggregatedResult.push(objClassFirst)
  } 

  let totalPrev = 0
  let totalPrior = 0
  let totalPriorYear = 0
  let previousDataSet = []
  let priorDataSet =[]
  let monthsInterval = []


  const formattedResult= []

  if(granularity === 'quarter'){
   
    aggregatedResult = aggregatedResult.filter((x) => {
      const dateEvent = new Date(x[0].replace('T', ' '))
      if (dateEvent.getTimezoneOffset() < 0){
        dateEvent.setUTCDate(dateEvent.getUTCDate() + 1 );
      } else {
        dateEvent.setUTCDate(dateEvent.getUTCDate() + 1 );
      }
  
        return [2, 5, 8, 11].includes(dateEvent.getUTCMonth())
    })
  }

  const half = Math.ceil(aggregatedResult.length / 2); 

  const parsableResult = aggregatedResult.slice(half)

  for (let index = 0; index < parsableResult.length; index++) {

    const newItem = parsableResult[index].concat(aggregatedResult[half+index-1])
    newItem.push(newItem[3]>0 ? (newItem[1]-newItem[3])/newItem[3] * 100 : 0)

    newItem.push(...aggregatedResult[half + index-yearShift(granularity)])
    newItem.push(newItem[6]>0 ? (newItem[1]-newItem[6])/newItem[6] * 100 : 0)
    formattedResult.push(newItem)
  }
  const monthData = formattedResult.map((objClass) => {

      const dateEvent = new Date(objClass[0].replace('T',' '));
      const dateEventPrior = new Date(objClass[2].replace('T',' '));
      const dateEventPriorYear =  new Date(objClass[5].replace('T',' '));

      if (dateEvent.getTimezoneOffset() < 0){
        dateEvent.setUTCDate(dateEvent.getUTCDate() - 1 );
      } else {
        dateEvent.setUTCDate(dateEvent.getUTCDate() + 1 );
      }
  
 
      if (dateEventPrior.getTimezoneOffset() < 0){
        dateEventPrior.setUTCDate(dateEventPrior.getUTCDate() - 1 );
      } else {
        dateEventPrior.setUTCDate(dateEventPrior.getUTCDate() + 1 );
      }
  
 
      if (dateEventPriorYear.getTimezoneOffset() < 0){
        dateEventPriorYear.setUTCDate(dateEventPriorYear.getUTCDate() -1 );
      } else {
        dateEventPriorYear.setUTCDate(dateEventPriorYear.getUTCDate() + 1 );
      }
  
 

      const prev = (type === '2'? objClass[1]/100 : objClass[1]*1)  ;
      const prior = (type === '2'? objClass[3]/100 : objClass[3]*1) ;
      const priorYear = (type === '2'? objClass[6]/100 : objClass[6]*1) ;
      
      totalPrev += parseInt(objClass[1])
      totalPrior += parseInt(objClass[3])
      totalPriorYear += parseInt(objClass[6])
      previousDataSet.push((type === '2' ? parseInt(objClass[1])/100 : parseInt(objClass[1])))
      priorDataSet.push((type === '2' ? parseInt(objClass[3])/100 : parseInt(objClass[3])))

      const prefix = formatLabelDate(dateEvent,granularity) 
      const prefix_prior = formatLabelDate(dateEventPrior,granularity)
      const prefix_priorYear = formatLabelDate(dateEventPriorYear, granularity)
      monthsInterval.push(dateUtilities.monthsNamesShort[dateEvent.getUTCMonth()] + ' ' + (dateEvent.getUTCFullYear()).toString())

      return <tr>
          <td className="cell-label">
            {prefix} {dateEvent.getUTCFullYear()}
          </td>
          <td>
            <div className="cell-value">
              <FormattedValue type={type} value={prev} signed={true}/>
            </div>
          </td>
          <td className="cell-label">
            {prefix_prior} {dateEventPrior.getUTCFullYear()}
          </td>
          <td>
            <div className="cell-value">
              <FormattedValue type={type} value={prior} signed={true}/>
            </div>
          </td>
          <td className="cell-value" >
            <div className="cell-value">
              <FormattedValue type="percent" value={objClass[4]} signed={true}/>
            </div>
          </td>
          <td className="cell-label">
            {prefix_priorYear} {dateEventPriorYear.getUTCFullYear()}
          </td>
          <td>
            <div className="cell-value">
              <FormattedValue type={type} value={priorYear} signed={true}/>
            </div>
          </td>
          <td className="cell-value" >
            <div className="cell-value">
              <FormattedValue type="percent" value={objClass[7]} signed={true}/>
            </div>
          </td>
      </tr>
  })

  return {
    tableData: monthData.reverse(), 
    totalPrevious: totalPrev, 
    totalPrior:totalPrior,
    totalPriorYear:totalPriorYear,
    dataChart: [{label:"Months",data:previousDataSet, labels:monthsInterval}],
    serieLenght:monthData.length
  }

}


export const FormatChurnRateKPIData = (pivotedResult,granularity) =>{
  let aggregatedResult = []
  for (let i = 0; i < pivotedResult.length; i++) {


    const objClassFirst = Object.keys(pivotedResult[i]).map((j) => {
      const mapped = pivotedResult[i][j] 
      return mapped
    })

     aggregatedResult.push(objClassFirst)
  } 

  if (aggregatedResult){
    const half = Math.ceil(aggregatedResult.length / 2);

    const parsableResult = aggregatedResult.slice(half)
    const formattedResult= []
  
    for (let index = 0; index < parsableResult.length; index++) {
  
      const newItem = parsableResult[index].concat(aggregatedResult[half+index-1])
      newItem.push(newItem[7]>0 ? (newItem[3]-newItem[7]) : 0)
  
      newItem.push(...aggregatedResult[half + index-yearShift(granularity)])
      newItem.push(newItem[12]>0 ? (newItem[3]-newItem[12]) : 0)
      formattedResult.push(newItem)
    }
    return formattedResult
  }

  return 0
}