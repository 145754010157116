import React from 'react';

import { useState, useEffect } from 'react';
import { FormattedValue } from '../common/FormattedValues/FormattedValues';
import { QueryPeriod } from '../logic/CalculatedValues';
import { MemoHalfDoughnutKPI } from './Graph/HalfDoughnut';

export const QueryCountWidget = ({ period, query, kpi, multiplier = 1 }) => {
    const [data, setData] = useState([])
    const total = QueryPeriod(period, query, kpi)

    useEffect(() => {

        if (total) {
            const prev = parseInt(total[0]/multiplier)
            const prior = parseInt(total[1]/multiplier)

            const changeCustomers = ((prev - prior) / Math.abs(prior)) * 100
            const dataParsed = [<>
                <FormattedValue type="money" value={prev} />
                <div className='chart-xs'>
                    <MemoHalfDoughnutKPI value={changeCustomers}
                        ascending={true}
                        heightPx={'200px'}
                        signed={true} />
                </div>
            </>]

            setData(dataParsed)
        }

    }, [total]);

    return data
};

export const MEmoQueryCountWidget = React.memo(QueryCountWidget)