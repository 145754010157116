import {useState, useEffect, useContext} from 'react';
import { Context } from '../App';
import Table from 'react-bootstrap/Table'
import { FormattedValue } from '../common/FormattedValues/FormattedValues';
import { Container, Row, Col } from 'react-bootstrap';
import { FullDoughnuthKPI } from './Graph/FullDoughnuth';
import {groupValues, costAttributionToCohort} from '../utilities/array_manipulation'

import { useTranslation } from 'react-i18next';
import {QueryCubeRemote} from '../common/APICalls/apiCalls'
import { generateUUID } from '../utilities/ui_tools';
import { BarChartKPINoOver } from './Graph/Istogram';

export const CustomerCohort =({referenceTotal,queryToRender, type, storageKey}) => {

    const { t } = useTranslation();

    const[data, setData] =useState([])
    const [state] = useContext(Context)

    const[grandTotal,setGrandTotal] = useState(0)
    
    const revenueByCohort =  QueryCubeRemote(queryToRender) 
    const revenueTotal = QueryCubeRemote(referenceTotal) 

    useEffect (() => {
        const parseResultSet =(revenueByCohort, revenueTotal) => {
            if (revenueByCohort[1]==='RESULT' && revenueTotal[1]==='RESULT'){
                setGrandTotal(revenueTotal[0]*100)

                if (type === 'gross_margin'){
                    return groupValues (
                      costAttributionToCohort(parseInt(revenueTotal[0])*100,
                      revenueByCohort[0]),
                      parseInt(revenueTotal[0]),
                      false,
                      'money',
                      null, 
                      type === 'net_profit')
    
                } else {
                    return groupValues (revenueByCohort[0],parseInt(revenueTotal[0]),false,'money',null,type === 'net_profit')
                }
            } else {
                let totalPrev = 0
                const totalRevenue =Object.keys(revenueTotal.tablePivot()[0]).map(function(key){
                    let totalRevenue = revenueTotal.tablePivot()[0][key];
                    return type==='store_credit' ? (totalRevenue -parseInt(process.env.REACT_APP_SC_CORRECTION_CENTS))*-1 :totalRevenue
                })
                setGrandTotal(totalRevenue[0])
      
                const cohortData = revenueByCohort.tablePivot().map((k) => {
                    
                    const objClass = Object.keys(k).map((i) => {
                        return k[i];
                    });
                    const prev = objClass[2]/100;
                    totalPrev += parseInt(objClass[1])
                    return [objClass[0],prev, 0, totalPrev]
                })
                localStorage.setItem(storageKey,JSON.stringify({
                    dataSet:cohortData,
                    dataTotal:totalRevenue/100
                }))
                if (type === 'gross_margin'){
                    return groupValues (costAttributionToCohort(totalRevenue,cohortData),parseInt(totalRevenue/100),false,'money',null,type === 'net_profit')
    
                } else {
                    return groupValues (cohortData,parseInt(totalRevenue/100),false,'money',null,type === 'net_profit')
                }
            }
        }
        const dataParsed =[(revenueByCohort && revenueTotal)? parseResultSet(revenueByCohort,revenueTotal): []];
        setData(dataParsed[0])
    },[revenueByCohort,revenueTotal,type,storageKey,state]);

    

    return  <div>
        {data[0] ?
        <>
        { type !== 'net_profit' ?
        <Container>
            <Row lg={2}>
                <Col>
                    <Table striped bordered>                 
                        <thead>
                            <tr key={generateUUID(16)}>
                                <th className='cell-emphasis cell-label'>{t('TABLES.ROW_HEADERS.COHORT')}</th>
                                <th className='cell-emphasis cell-value' >{t('TABLES.ROW_HEADERS.AMOUNT')}</th>
                                <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.PERCENT')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data[2][0]}
                            <tr key={generateUUID(16)} className='row-summary'>
                                <td className="cell-label">{t('TABLES.END_ROW.TOTAL')}</td>
                                <td className="cell-value"><FormattedValue type="money" value={grandTotal/100} /></td>
                                <td className="cell-value"><FormattedValue type="percent" value={100} /></td>
                                <td></td>
                            </tr>
                        </tbody>
                
                    </Table>
                </Col>
                
                <Col>
                    <div className='chart-md'>
                        <FullDoughnuthKPI values={data[1][0]} labelsIn={data[0][0]} />
                    </div>
                </Col>
            </Row>
        </Container> 
        :
        <Container>
            <Row>
            <Col>
                    <div className='chart-md'>
                    <BarChartKPINoOver 
                      key={generateUUID(16)}
                      dataseries={{title:'Top vs Others', dimension: data[0][0], data: data[1][0]}} 
                      colorMono={false}  
                      Yaxis="$"
                      Xaxis="Cohort" 
                      /> 


                      <FullDoughnuthKPI values={data[1][0]} labelsIn={data[0][0]} />
                    </div>
                </Col>

            </Row>
            <Row>
                <Col>
                    <Table striped bordered>                 
                        <thead>
                            <tr key={generateUUID(16)}>
                                <th className='cell-emphasis cell-label'>{t('TABLES.ROW_HEADERS.COHORT')}</th>
                                <th className='cell-emphasis cell-value' >{t('TABLES.ROW_HEADERS.AMOUNT')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data[2][0]}
                            <tr key={generateUUID(16)} className='row-summary'>
                                <td className="cell-label">{t('TABLES.END_ROW.TOTAL')}</td>
                                <td className="cell-value"><FormattedValue type="money" value={grandTotal/100} /></td>
                                <td></td>
                            </tr>
                        </tbody>
                
                    </Table>
                </Col>                
            </Row>
        </Container> 
        }
        </>        
        : 
        <div>Wait...</div>}
            </div>;

}