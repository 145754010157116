// Application Import
import { Tab, Tabs } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'

// Hooks Import
import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useCubeQuery } from '@cubejs-client/react'


// Internal Components Import
import { quarterOrMonth, referencePlusOneDay } from '../utilities/date_formatting'
import { FormattedValue } from '../common/FormattedValues/FormattedValues'
import { LineChartKPI } from '../components/Graph/LineChart'

import { Context } from '../App'

import { count_customers_granular } from '../kpi_queries/customers'
import { capitalizeSentence } from '../utilities/stringUtility'
import { FormatChurnRateKPIData } from '../utilities/dataSeriesParsing'
import { generateUUID } from '../utilities/ui_tools'
// Render the history trend for Churn Rate based on
export const ChurnRateHistoryWidget = () => {
  const { t } = useTranslation()

  const [data, setData] = useState()
  const [state] = useContext(Context)

  const renderRow = (x) => {
    return <tr key={generateUUID(8)}> 
      <td className="cell-label cell-emphasis">
        {quarterOrMonth(x[0], state.granularity)}
      </td>
      <td className="cell-value cell-emphasis" >
        <FormattedValue type="percent" value={x[3]} />
      </td>
      <td className='cell-value'>
        <FormattedValue type="absolut" value={x[1]} signed={true} />
      </td>
      <td className='cell-value'>
        <FormattedValue type="absolut" value={x[2]} signed={true} />
      </td>
      <td className="cell-label cell-emphasis">
        {quarterOrMonth(x[4], state.granularity)}
      </td>
      <td className="cell-value cell-emphasis" >
        <FormattedValue type="percent" value={x[7]} />
      </td>

      <td className='cell-value'>
        <FormattedValue type="absolut" value={x[5]} signed={true} />
      </td>
      <td className='cell-value'>
        <FormattedValue type="absolut" value={x[6]} signed={true} />
      </td>
      <td className="cell-value" >
        <FormattedValue type="percent" value={x[8]} signed={true} />
      </td>
      <td className='cell-value cell-emphasis'>
      {quarterOrMonth(x[9], state.granularity)}
      </td>
      <td className="cell-value cell-emphasis" >
        <FormattedValue type="percent" value={x[12]} signed={true} />
      </td>

      <td className='cell-value'>
        <FormattedValue type="absolut" value={x[10]} signed={true} />
      </td>
      <td className="cell-value" >
        <FormattedValue type="absolut" value={x[11]} />
      </td>
      <td className="cell-value" >
        <FormattedValue type="percent" value={x[13]} signed={true} />
      </td>

    </tr>
  }

  const { resultSet: customers } = useCubeQuery(count_customers_granular(
      ['CustomersKpi.date', 'CustomersKpi.start', 'CustomersKpi.lost', 'CustomersKpi.churn_rate'],
      [    
        state.data_series_date_interval_prior[0],
        referencePlusOneDay(state.data_series_date_interval[1]),
      ],
  
      state.granularity,
      state.lookback
  ))

  useEffect(() => {
      if (!customers) {
          return;
      }
      let total = customers.tablePivot({
          fillMissingDates: true
      })
      if(state.granularity === 'quarter'){
          total = total.filter((x) =>{
            const d = new Date(x['CustomersKpi.date'].replace('T', ' '));
            if (d.getTimezoneOffset() < 0){
              d.setUTCDate(d.getUTCDate()+1 );
            } else {
              d.setUTCDate(d.getUTCDate() + 1 );
            }
            return [2, 5, 8, 11].includes(d.getUTCMonth())
          })
      }

     const churnData =  FormatChurnRateKPIData(total, state.granularity) 
     setData({
      tableData: churnData.map((x) => renderRow(x)).reverse(),
      dataChart: [
        {
          label: "Months",
          data: churnData.map((x) => x[3]),
          labels: [...new Set(churnData.map((x) => quarterOrMonth(x[0], state.granularity)))]
        }
      ]

     })
  }, [customers, state.granularity])


  return <>
    {data ? <>
      <Tabs defaultActiveKey="graph" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="graph" title={t('TABS.GRAPH')}>
          {data.dataChart.length>0 ?
          <LineChartKPI 
            formatLabel={false} 
            granularity={state.granularity} 
            dataSeries={data.dataChart} 
            title="" 
            Yaxis="%" />
          :
          <></>}
        </Tab>
        <Tab eventKey="table" title={t('TABS.TABLE')}>
          <Table striped bordered>
            <thead>
              <tr>
                <th className="cell-label-compact">{capitalizeSentence(state.granularity)}</th>
                <th className="cell-value-compact">Churn Rate</th>
                <th className="cell-value-compact"># Customers</th>
                <th className="cell-value-compact"># Lost</th>
                <th className="cell-label-compact">{capitalizeSentence('Prior ' +  state.granularity)}</th>
                <th className="cell-value-compact">Churn Rate</th>
                <th className="cell-value-compact"># Customers</th>
                <th className="cell-value-compact"># Lost</th>
                <th className='cell-value-compact'>Difference</th>
                <th className="cell-label-compact">{capitalizeSentence('Prior Year')}</th>
                <th className="cell-value-compact">Churn Rate</th>
                <th className="cell-value-compact">{capitalizeSentence('# Customers')}</th>
                <th className="cell-value-compact"># Lost</th>
                <th className='cell-value-compact'>Difference</th>
               

              </tr>
            </thead>
            <tbody>
              {data.tableData}
            </tbody>
          </Table>
        </Tab>
      </Tabs>
    </>
      :
      <div>Wait...</div>
    }
  </>
}
