// Application Import
import { Form, FloatingLabel } from 'react-bootstrap'
import { useContext } from 'react'
// Hooks Import 
import { useTranslation } from 'react-i18next'
import { Context } from '../../App'
/** The component return the section's select boxes to manage Time Interval, Look Back and Granularity
*   @param {*} controls               // This is the configration parameters received by the caller. 
*   @param {*} handleTimeInterval,    // Parent function to handle Time Interval Selection 
*   @param {*} handleLookBack,        // Parent function to handle Look Back Selection
*   @param {*} handleGranularity,     // Parent function to handle Granularity Selection
*   @param {*} granularityValue,      // Default Granularity Value 
*   @param {*} timeIntervalValue,     // Default Tim Interval Value
*   @param {*} disabled_selectorsLabel   // JSON Object for disabled controls  
*/ 
export const SelectRangesInline = ({
  controls, 
  handleTimeInterval, 
  handleLookBack, 
  handleGranularity,
  disabled_selectorsLabel}) => {


  const { t } = useTranslation()
  const [state] = useContext(Context)
  // Handler for control selection. Each control call the parent handler so any caller can have a different behavior  
  const handleGranularitySelection =(e) => {
    handleGranularity(e.target.value)
  }
  
  const handleLookBackSelection =(e) =>{
    handleLookBack(parseInt(e.target.value))
  }

  const handleTimeRangeSelection = (e) => {
    handleTimeInterval(parseInt(e.target.value))
  }


  // Return the rendered component. Values, handler and control configuration are defined by the caller.
  return <>

      {/* Time Interval select  */}
      <div className = 'selectLeftItemContainer'>
        <FloatingLabel controlId = 'SelectTimeInterval' label = {t('SELECTORS.TIMEINTERVAL.TITLE')} >
          <Form.Select disabled value = {state.timeInterval} aria-label = ""  className = 'selectItem' size = "sm" onChange = {handleTimeRangeSelection}>
            {controls.timeInterval.disabled ? <option value="-">{disabled_selectorsLabel ? disabled_selectorsLabel.timeInterval : t('SELECTORS.TIMEVALUES.NONE')}</option> : controls.timeInterval.options }
          </Form.Select>   
        </FloatingLabel>   
      </div>

      {/* Look Back select  */}
      <div className='selectLeftItemContainer'>
        <FloatingLabel controlId='SelectLookBack' label={t('SELECTORS.LOOKBACK.TITLE')} >
          <Form.Select  aria-label=""  disabled className='selectItem' size="sm" onChange={handleLookBackSelection}>
            {controls.lookback.disabled ? <option value="-">{disabled_selectorsLabel ? disabled_selectorsLabel.lookback : t('SELECTORS.TIMEVALUES.NONE')}</option> : controls.lookback.options}
          </Form.Select>   
        </FloatingLabel>   
      </div>

      {/* Granularity select  */}
      <div className='selectLeftItemContainer'>
        <FloatingLabel  controlId='SelectGranularity' label={t('SELECTORS.GRANULARITY.TITLE')} >
            <Form.Select disabled value={state.granularity_value} aria-label="" size="sm" className='selectItem'  onChange={handleGranularitySelection}>
              {controls.granularity.options}
            </Form.Select>   
        </FloatingLabel>   
    </div>
</>
}