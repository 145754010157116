// Application Import
import { Card, Row, Col } from 'react-bootstrap';

// Context Import
import { Context } from '../../App'

// Hooks Import
import { useState, useContext } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { dateUtilities } from '../../utilities/date_formatting'

//Queries Import
import { store_credit_by, store_credit_by_month_long, store_credit_last_month } from '../../kpi_queries//store_credit'

// Internal Component Import
import { KPIMonthlyStoreCreditView } from '../../components/KPI_Monthly_StoreCredit'
import { CustomerCohort } from '../../components/CustomerCohort'

// Formatting and Selector configuration import 
import { TitleCard, TitleSection } from '../../utilities/ui_tools'
import { ConcentrationIndexWidget } from '../ConcentrationIndex';
import { ConcentrationIndexGrupedWidget } from '../ConcentrationIndexGrouped';
import { concentrationIndexStoreCredit } from '../../kpi_queries/concentration_index';
import { concetrationIndexStoreCreditGrouped } from '../../kpi_queries/concentration_index';
import { StoreCreditByLocation } from '../StoreCreditByLocation';



/**
* The component manage and display the KPI related to  Store Credit.   
*/
export const StoreCreditSection = () => {

  const { t } = useTranslation()    // Initialize translation component

  const [state] = useContext(Context) // General Context
  const [trendLabelRange ] = useState(state.date_range.split(';'))

  return <div>


    {/* Store Credit Trend */}
    <Card id="storeCredit" className="card_section">

      <TitleCard label={t('SECTION.STORE_CREDIT.TREND')} />

      <Card.Body className='card-height-m'>
        <KPIMonthlyStoreCreditView 
          query={store_credit_by_month_long([state.data_series_date_interval[0],state.data_series_date_interval[1]], state.granularity)}
          granularity = {state.granularity} 
          reduce={true}
          type="2" 
        />
      </Card.Body>

      <Card.Footer className='card-footer-muted'>

        <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_TOTMONTHS"
          t={t}
          components={{
            DATE_START: dateUtilities.monthsNamesShort[new Date(trendLabelRange[0]).getUTCMonth()] + ' ' + (new Date(trendLabelRange[0]).getUTCFullYear()),
            DATE_END: process.env.REACT_APP_HR_REFERENCE_DATE,
          }}
        />

      </Card.Footer>

    </Card>

    {/* Store Credit By Cohort*/}
    <Card className="card_section">

      <TitleCard label={t('SECTION.STORE_CREDIT.COHORT')} date_end={process.env.REACT_APP_HR_REFERENCE_DATE} />

      <Card.Body className='card-height-s'>
      <CustomerCohort queryToRender={store_credit_by(['StoreCredit.customerCohort','StoreCredit.customerCohortId'] ,[process.env.REACT_APP_START_MONTH,process.env.REACT_APP_START_MONTH_NEXT],[["StoreCredit.customerCohortId","asc"]])}
          referenceTotal={store_credit_last_month}
          type="store_credit"
          storageKey='SC_Cohort' />
      </Card.Body>

      <Card.Footer className='card-footer-muted'>
        {t('KPIs.LABELS_AND_FOOTERS.FOOTER_ATDATE')}{process.env.REACT_APP_HR_REFERENCE_DATE}.
        <br/>
        <Trans  i18nKey = "KPIs.LABELS_AND_FOOTERS.FOOTER_COHORT"
                t={t}
                components = {{ 
                  MONTHS:state.lookback.toString(),
                  UNIT:state.lookback === 1 ? 'month' : 'months'
                }} 
        />
      </Card.Footer>

    </Card>

    {/* Store Credit By Location*/}
    <Card className="card_section">

      <TitleCard label={t('SECTION.STORE_CREDIT.LOCATION')} date_end={process.env.REACT_APP_HR_REFERENCE_DATE} />

      <Card.Body className='card-height-s'>
        <StoreCreditByLocation 
          queryToRender={store_credit_by(['StoreCredit.location'],[process.env.REACT_APP_START_MONTH,process.env.REACT_APP_START_MONTH_NEXT])} 
          queryToRenderDetail={store_credit_by(['StoreCredit.country'],[process.env.REACT_APP_START_MONTH,process.env.REACT_APP_START_MONTH_NEXT])}
          key='SC_Location' />
      </Card.Body>

      <Card.Footer className='card-footer-muted'>
        {t('KPIs.LABELS_AND_FOOTERS.FOOTER_ATDATE')}{process.env.REACT_APP_HR_REFERENCE_DATE}.
        <br/>
        <Trans  i18nKey = "KPIs.LABELS_AND_FOOTERS.CREDIT_CARD"
                t={t}
                components = {{ 
                  MONTHS:state.lookback.toString(),
                  UNIT:state.lookback === 1 ? 'month' : 'months'
                }} 
        />
      </Card.Footer>

    </Card>

    {/* Store Credit Concentration Index */}
    <Card className="card_section">

      <TitleCard label={t('SECTION.STORE_CREDIT.CONCENTRATION')} date_end={process.env.REACT_APP_HR_REFERENCE_DATE} />

      <Card.Body className='card-height-xs'>

        <Row xs={1} md={2} lg={2} className="g-5 car row_kpi">

          {/* General Concentration Index*/}
          <Col lg={3} className="col_kpi">
            <ConcentrationIndexWidget queryToRender={concentrationIndexStoreCredit(state.date_range.split(';'))} />
          </Col>

          {/* Concentration Index by Cohort*/}
          <Col lg={9} className="col_kpi">
            <ConcentrationIndexGrupedWidget queryToRender={concetrationIndexStoreCreditGrouped(state.date_range.split(';'))} />
          </Col>
        </Row>

      </Card.Body>



      <Card.Footer className='card-footer-muted'>
        {t('KPIs.LABELS_AND_FOOTERS.FOOTER_ATDATE')}{process.env.REACT_APP_HR_REFERENCE_DATE}.<br/>
        <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.LOWER_BETTER" t={t} />

      </Card.Footer>

    </Card>
  </div>
}