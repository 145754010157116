import { useCases } from "./use_cases";

export const grossProfitByUseCase = (value, dateRange) => ({
  "measures": [
    "MnEvents.grossMargin"
  ],
  "timeDimensions": [
    {
      "dimension": "MnEvents.timestamp",
      "dateRange": dateRange,
    }
  ],
  "order": {},
  "dimensions": [
    value
  ],
  "filters": [
    {
      "member": "MnEvents.useCase",
      "operator": "equals",
      "values": useCases.margin
    },
    {
      "member": "MnEvents.netMargin",
      "operator": "set",
    }

  ],
  "limit": 50
});


export const gross_profit_by_cohort = (compareRange) => {
  return {
    "measures": [
      "MnEvents.grossMargin"
    ],
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "compareDateRange": compareRange
      }
    ],
    "order":[["MnEvents.customerCohortId","asc"]],
    "dimensions": [
      "MnEvents.customerCohort",
      "MnEvents.customerCohortId"
    ],
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.margin

      },
      {
        "member": "MnEvents.customerCohort",
        "operator": "set",
        "values": []
      },

    ],
    "limit": 50
  }
}

export const gross_profit_by_cohort_history = (dateRange, granularity, dimension) => {
  let queryDimension = []
  let queryOrder = [[
    "MnEvents.timestamp",
    "asc"
  ],
  [
    "MnEvents.grossMargin",
    "desc"
  ]]
  switch (dimension) {
    case '1':
      queryDimension = ["MnEvents.useCase"]
      break
    case '2':
      queryDimension = ["MnEvents.customerCohort"]
      break
    default:
      break
  }

  return {
    "measures": [
      "MnEvents.grossMargin"
    ],
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "dateRange": dateRange,
        "granularity": granularity
      }
    ],
    "order": queryOrder,
    "dimensions": queryDimension,
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.margin

      },
      {
        "member": "MnEvents.customerCohort",
        "operator": "set",
        "values": []
      },

    ]
  }

}

export default grossProfitByUseCase;