import { Form, Table, Button, Row, InputGroup, Col } from "react-bootstrap"
import { useTranslation } from 'react-i18next'

import { getApiAdministration, putApiAdministration } from "../../common/APICalls/apiAdministration"
import { useEffect, useState } from "react"

import * as FaIcons from 'react-icons/fa';

const COAEditForm = ({idCoa,currentGroupId, currentGroupName, endWork}) =>{
    const [groups,setGroups]= useState([])
    
    const [newGroup, setNewGroup] = useState({})
    const [onWork, setOnWork] = useState(false)

    const handleGroups = () => {
        getApiAdministration('groups').then((response) =>{
            if (response ){
                setGroups(response)
            }    
        })
    }

    useEffect(() => {
        handleGroups()
        
    },[])


    useEffect(()=>{
        setNewGroup({
          coaid: idCoa,
          group_id: currentGroupId ? currentGroupId : 0,
          group_description: currentGroupName? currentGroupName :'N/A'})
  
    },[idCoa])
    const handleForm = e => {
        e.preventDefault()
        setOnWork(true)
        putApiAdministration('coa/edit',newGroup).then((response) =>{
            if (response ){
                setOnWork(false)
                setNewGroup(false)
                handleGroups()
                endWork()
            }    
        })

    }
    const handleChangeGroup =(e) => {
        const index = e.target.selectedIndex
        setNewGroup({
            coaid: idCoa,
            group_id: e.target.value,
            group_description: e.target[index].text
        })    
      }
    

    return <Row>
         <Form onSubmit={handleForm}> 
            <InputGroup style={{display:'flex'}} >
            <Form.Select id='CAMGroupSelect' onChange={handleChangeGroup}>
                <option
                  selected={currentGroupId === 0}
                  value='0'>N/A</option>
                {groups.map((group) =>{
                    return <option
                        value={group.id}
                        selected={currentGroupId === group.id}
                        >
                            {group.name}
                        </option>
                }
                )}
            </Form.Select>
            <Button variant="outline-primary" 
                        className="buttonQBOBoxInRow" 
                        type="submit">
                            <FaIcons.FaSave />
            </Button>
            </InputGroup>
        </Form>
    </Row>
}
export const COAInfo = () => {

    const { t } = useTranslation()
    const [data, setData] = useState([])
    const [onEdit, setOnEdit] = useState()

    const handleCOA = () => {
        getApiAdministration('coa/list').then((response) =>{
            if (response ){
                setData(response)
            }    
        })
    }
    
    
    useEffect(() => {
        handleCOA()
    },[])
    return <Table striped bordered>
            <thead>
            <tr>
                <th className = "cell-label">{t('SECTION.ADMINISTRATION.COA_TABLE.ACCOUNTNUMBER')}</th>
                <th className = "cell-label">{t('SECTION.ADMINISTRATION.COA_TABLE.NAME')}</th> 
                <th className = "cell-value">{t('SECTION.ADMINISTRATION.COA_TABLE.RELATEDGROUP')}</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                {data.map((account) =>{
                    return <tr>
                        <td className="cell-value">{account.AccountNumber}</td>
                        <td className="cell-label">{account.FullyQualifiedName}</td>
                        <td className="cell-value">{onEdit === account.id ? 
                            <COAEditForm    idCoa={account.id} 
                                            currentGroupId={account.id_group}
                                            currentGroupName={account.description_group}
                                            endWork={()=>{setOnEdit(null); handleCOA()}}></COAEditForm> : 
                            <span>{account.description_group}</span>
                            }
                            
                        </td>
                        <td className="cell-label">
                            <Row className="showComponentRowComponent">
                              <Col>
                            { onEdit === account.id ? 
                                <Button variant="outline-danger" 
                                  onClick={()=>setOnEdit(null)}>
                                  <FaIcons.FaUndo /> 
                                </Button>
                            
                            : 
                              <Button variant="outline-secondary" 
                                onClick={()=>setOnEdit(account.id)}>
                                  <FaIcons.FaEdit/>
                              </Button>
                            }
                            </Col>
                          </Row>
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
    }