// Application Import
import React, { useState, useContext } from 'react'
import { Nav, Image } from "react-bootstrap"
import { Link } from 'react-router-dom'

import { SelectRangesInline } from '../SelectRanges/SelectRanges'
import { Selector_v15_context_config } from '../../utilities/selectorsConfigurations'

// Hooks Import
import { useTranslation } from 'react-i18next'
import { useToken, destroyToken } from '../App/useToken'

// CSS Import
import './SideBar.css'

//Icon Import
import * as FaIcons from 'react-icons/fa'
import { Context } from '../../App'
import { calculateDateRange } from '../../utilities/date_formatting'

//The component render thedashboard side bar and handle the 
export const SideBar = () => {

    const { t } = useTranslation()
    const { token } = useToken()

    const [stateContext, dispatchStateContext] = useContext(Context) //Gross Revenue Context

    const [sidebar, setSidebar] = useState(false)

    const cachedViewItem = sessionStorage.getItem('viewItem')
    const [selectedItem, setSelectedItem] = useState(cachedViewItem ? parseInt(cachedViewItem) : 0)

    const showSidebar = () => setSidebar(!sidebar)

    const selectItem = (itemSelected) => {
        sessionStorage.setItem('viewItem', itemSelected)
        setSelectedItem(itemSelected)
    }

    //Destroy logged session and reload application 
    const logout = () => {
        destroyToken();
        localStorage.clear();
        window.location.reload();
    }

    //Open the Documentation in a new window 
    const goToDocs = () => {
        window.open("https://" + process.env.REACT_APP_DOC_USER + ':' + process.env.REACT_APP_DOC_PWD + '@' + process.env.REACT_APP_DOC_URL, "_blank");
    }

  /**
   * Function to handle the change of Granularity performed by the user 
   * @param {*} e string with granularity
  */
  const handleGranularitySelection = (e) => {
    dispatchStateContext({
      type: 'granularity_value',
      value: e
    })

    dispatchStateContext({
      type: 'granularity',
      value: e === '1' ? 'month' : e === '3' ? 'quarter' : e === '0.25' ? 'week' : 'day' 
    })


  }

  /**
  * Function to handle the change of Time Interval performed by the user 
  * @param {*} e string with date range interval 
  */
  const handleTimeIntervalSelection = (e) => {

    // The date range is calculated on the base of parameter passed from user control
    const ranges = calculateDateRange(e)

    //Update customer trend state 
    //setTrendCusotmersLabelRange(ranges[0].split(';'))

    dispatchStateContext({
      type: 'date_range',
      value: ranges[0]
    })
    dispatchStateContext({
      type: 'date_range_prior',
      value: ranges[1]
    })
    dispatchStateContext({
      type: 'timeInterval',
      value: e
    })

    if (e === 0) {
      dispatchStateContext({
        type: 'granularity',
        value: 'day'
      })
    } else if (e !== 0 && stateContext.granularity === 'day') {
      dispatchStateContext({
        type: 'granularity',
        value: 'month'
      })
    }
  }
  
  const handleLookBackSelection = (e) => {
    //Set the reference and start date from environment file
    const reference_date = new Date(process.env.REACT_APP_REFERENCE_DATE)

    let startdate = new Date(process.env.REACT_APP_REFERENCE_DATE)
    startdate.setUTCMonth(reference_date.getUTCMonth() - e)

    // Set the time interval to query on bade of reference periodd and user selection
    const current_start = startdate.getUTCFullYear() + '-' + ("0" + (startdate.getUTCMonth() + 1)).slice(-2) + '-01';
    const current_end = reference_date.getUTCFullYear() + '-' + ("0" + (reference_date.getUTCMonth() + 1)).slice(-2) + '-' + reference_date.getUTCDate()

    // Set the prior period date
    const priorenddate = new Date(current_start)
    let priordate = new Date(current_start)

    priorenddate.setUTCDate(priorenddate.getUTCDate() - 1)
    priordate.setFullYear(priorenddate.getUTCFullYear())
    priordate.setUTCMonth(priorenddate.getUTCMonth() - e)

    // Formatting the prior period limit range
    const prior_start = priordate.getUTCFullYear() + '-' + ("0" + (priordate.getUTCMonth() + 1)).slice(-2) + '-01';
    const prior_end = priorenddate.getUTCFullYear() + '-' + ("0" + (priorenddate.getUTCMonth() + 1)).slice(-2) + '-' + priorenddate.getUTCDate()

    //Update the state variable to be used for the label 
    //setSummaryLabelRange([current_start, current_end])


    //Update customer context
    dispatchStateContext({
      type: 'date_range',
      value: current_start + ';' + current_end
    })
    dispatchStateContext({
      type: 'date_range_prior',
      value: prior_start + ';' + prior_end
    })
    dispatchStateContext({
      type: 'lookback',
      value: e + 1
    })
  }

    // The component render the navigation bar.  
    return <>
        <Nav className='Sidebar  d-none d-md-block bg-gradient-primary'>
            <div className='SidebarWrapOuter'>
                {/** Logo DNSimple  */}
                <Link onClick={() => selectItem(0)}
                    to="/"
                    className={`sidebar-brand ${selectedItem === 0 ? "active" : ""}`}>
                    <Image src='dnsimple-logo-light.png' className="logo" />
                </Link>

                {/** Application Title */}
                <div className='title'> {t('GENERAL.TITLE')}</div>

                {/** Reference Date */}
                <div className='sidebar-divider-secondary title'> {t('GENERAL.REFERENCEDATE')}
                    {process.env.REACT_APP_HR_REFERENCE_DATE}
                </div>
                <div className='sideBarControGroup' style={{display:'none'}}>
                  <SelectRangesInline controls = {Selector_v15_context_config()} 
                                                          handleGranularity = {handleGranularitySelection} 
                                                          handleTimeInterval = {handleTimeIntervalSelection}
                                                          handleLookBack = {handleLookBackSelection}
                                                          disabled_selectorsLabel = {{}}
                  />

                </div>
                
                {/** Section Submenu */}
                <div className='sidebar-heading'>{t('SECTION.SECTIONS').toUpperCase()}</div>

                {/** Summary */}
                <Link onClick={() => selectItem(0)}
                    to="/"
                    className={`menuItem ${selectedItem === 0 ? "active" : ""}`}>
                    <FaIcons.FaTachometerAlt className='gliphWrapper' onClick={showSidebar} /> {t('SECTION.DASHBOARD')}
                </Link>

                {/** Customers */}
                <Link onClick={() => selectItem(1)}
                    to={{ pathname: '/customers', }}
                    className={`menuItem ${selectedItem === 1 ? "active" : ""}`}>
                    <FaIcons.FaUsers className='gliphWrapper' onClick={showSidebar} /> {t('SECTION.CUSTOMERS.TITLE')}
                </Link>


                {/** Revenue */}
                <Link onClick={() => selectItem(2)}
                    to={{ pathname: '/revenue', }}
                    className={`menuItem ${selectedItem === 2 ? "active" : ""}`}>
                    <FaIcons.FaRegCreditCard className='gliphWrapper' onClick={showSidebar} /> {t('SECTION.REVENUE.TITLE')}
                </Link>

                {/** Gross Profit */}
                <Link onClick={() => selectItem(3)}
                    to={{ pathname: '/grossprofit', }}
                    className={`menuItem ${selectedItem === 3 ? "active" : ""}`}>
                    <FaIcons.FaFunnelDollar className='gliphWrapper' onClick={showSidebar} /> {t('SECTION.GROSSPROFIT.TITLE')}
                </Link>


                {/** Net Profit */}
                <Link onClick={() => selectItem(4)}
                    to={{ pathname: '/netprofit', }}
                    className={`menuItem ${selectedItem === 4 ? "active" : ""}`}>
                    <FaIcons.FaFunnelDollar className='gliphWrapper' onClick={showSidebar} /> {t('SECTION.NETPROFIT.TITLE')}
                </Link>

                {/** Store Credit */}
                <Link onClick={() => selectItem(6)}
                    to={{ pathname: '/store_credit', }}
                    className={`menuItem ${selectedItem === 6 ? "active" : ""}`}>
                    <FaIcons.FaWallet className='gliphWrapper' onClick={showSidebar} /> {t('SECTION.STORE_CREDIT.TITLE')}
                </Link>


                {/** Profit and Loss */}
                <Link onClick={() => selectItem(8)}
                    to={{ pathname: '/profitandloss', }}
                    className={`menuItem ${selectedItem === 8 ? "active" : ""}`}>
                    <FaIcons.FaBalanceScale className='gliphWrapper' onClick={showSidebar} /> {t('SECTION.PROFITANDLOSS.TITLE')}
                </Link>



                {/** Utilities */}
                <div className='sidebar-heading '><b>{t('SECTION.UTILITIES').toUpperCase()}</b></div>

                {/** Administration */}
                <Link onClick={() => selectItem(7)}
                    to={{ pathname: '/administration', hash: "", }}
                    className={`menuItem ${selectedItem === 7 ? "active" : ""}`}>
                    <FaIcons.FaCogs className='gliphWrapper' onClick={showSidebar} /> {t('SECTION.ADMINISTRATION.TITLE')}
                </Link>

                {/** Documentation */}
                <Link onClick={() => goToDocs()}
                    className="menuItem">
                    <FaIcons.FaBook className='gliphWrapper'  /> {t('SECTION.DOCUMENTATION')}
                </Link>

                {/** Logout */}
                {/** Displayed only if the user is logged  */}
                {(token) ? <>
                    <Link onClick={() => logout()}
                        to="/"
                        className="item-divider menuItem">
                        <FaIcons.FaSignOutAlt className='gliphWrapper' onClick={showSidebar} /> {t('SECTION.LOGOUT')}
                    </Link>
                </>
                    : <></>
                }
            </div>
        </Nav>
    </>
}

