
/** 
* Transform and return an input value in a formatted HTML string 
* @param {*} type Literal to detemine which type of fomattation will be used. Values: percent,decimal,absolute,money
* @param {*} value The value to be formatted
* @param {*} @signed Explicit if the value must return sign 
* @return HTML Snippet with formatted value
* */  

export const FormattedValue = ({type,value,signed}) => { 
    
    //If the value is null return an empty SPAN oterwise return the valeu passed in the desidered format
    return (value) ? <span>
        {type === 'percent' ? ((signed && value>0) ? '+':'') + 
        value.toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' %'
         :
         type === 'decimal' ?
         value.toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits: 2})
         :
         type === 'absolute' ?
         value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0})
         :
         type ==='money' ? 
         
          value > 0 ? 
            "$" + value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0}) 
          :
            "- $" + Math.abs(value).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0}) 
        :
         type ==='moneyexact' ? 
          value > 0 ?  
            "$" + value.toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits: 2}) 
            : 
            "- $" + value.toLocaleString('en-US',{minimumFractionDigits: 2, maximumFractionDigits: 2}) 
          :
          value.toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0})}
    </span>
    :<span></span>
}