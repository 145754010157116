import { useState, useEffect} from 'react';
import { MemoHalfDoughnutKPI } from './Graph/HalfDoughnut';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card } from 'react-bootstrap';

import {QueryCubeRemote} from '../common/APICalls/apiCalls'

export const ConcentrationIndexGrupedWidget = ({queryToRender}) => {
    const { t } = useTranslation();

    const[data, setData] = useState([])
    const concentrationIndexDataArray =  QueryCubeRemote(queryToRender) 

    useEffect(() => {
        const parseResultSet = (concentrationIndexDataArray) =>{
            const concentrationIndex = concentrationIndexDataArray.tablePivot().map((k) => {
                const objClass = Object.keys(k).map((i) => {
                  return k[i]
                })

                return <Col lg={4}>
                    <div className="chart-xs">
                        <MemoHalfDoughnutKPI value={objClass[1]} ascending={false} heightPx={'200px'}/>
                    </div>
                    <div>
                    {objClass[0]} 
                    </div>
                </Col>                    
            })

            return <div className='height-full center-vertical'>
                <Card className="card_kpi">
                <Card.Title>{t('KPIs.TITLES.CONCENTRATION_COHORT')}</Card.Title>
                <Card.Body>
                    <Row >
                        {concentrationIndex}
                    </Row>
                </Card.Body>
                </Card>
            </div>
            
        }
        const dataParsed =[(concentrationIndexDataArray)? parseResultSet(concentrationIndexDataArray): []];
        setData(dataParsed) ;
    },[concentrationIndexDataArray, t]);
    return data;

};

