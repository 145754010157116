import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { createContext, useReducer } from 'react'
import { SideBar } from './components/SideMenu/SideBar'
import { Footer } from './components/Footer/Footer'

import { DashBoardPage } from './components/Pages/Dashboard/Dashboard'
import { AdministrationPage } from './components/Pages/Administration/Administration'
import { StoreCreditSection } from './components/Sections/StoreCredit'
import { ProfitAndLossSection } from './components/Pages/PAndL/PAndL'
import { Login } from './components/Login/Login'
import './i18n'
import { useToken } from './components/App/useToken'
import { DataPage } from './components/Pages/Data/Data'
import { pandl_by_customer } from './kpi_queries/customers_number'
import { QueryCubeRemote } from './common/APICalls/apiCalls'

import { ApplicationParameterContext } from './utilities/ParameterContext'
/**
 * Define initial parameters for queries parameters and Context Initialization
 */


const SeriesDatesPrior = () => {
  const d = new Date(process.env.REACT_APP_REFERENCE_DATE)
  d.setUTCMonth(d.getUTCMonth() - 24)
  
  const endDate = d.getUTCFullYear() + '-' + (d.getUTCMonth()+1) + '-' + d.getUTCDate()
  d.setUTCMonth(d.getUTCMonth() - 24)
  d.setUTCDate(d.getUTCDate()+1 );

  const startdate = d.getUTCFullYear() + '-' + (d.getUTCMonth()+1) + '-' + d.getUTCDate()
  return [startdate, endDate]
}

const SeriesDates = () => {
  const d = new Date(process.env.REACT_APP_REFERENCE_DATE)
  d.setUTCMonth(d.getUTCMonth() - 24)
  d.setUTCDate(d.getUTCDate() + 1)

  const year = d.getUTCFullYear().toString()
  const month = String(d.getUTCMonth() + 1).padStart(2, '0') // Ensure month has leading zero
  const date = String(d.getUTCDate()).padStart(2, '0') // Ensure date has leading zero
  
  const formattedDate =  year + "-" +  month + "-" + date;
  
  return [formattedDate, process.env.REACT_APP_REFERENCE_DATE];
  //}
  //return [d.getUTCFullYear() + '-' + (d.getUTCMonth()+1) + '-' + d.getUTCDate(),process.env.REACT_APP_REFERENCE_DATE]
}

//Default date ranges and granularity
const initialContextValues = {
  data_series_date_interval: SeriesDates(),
  data_series_date_interval_prior: SeriesDatesPrior(),
  date_range: process.env.REACT_APP_DATERANGE,
  date_range_long: process.env.REACT_APP_DATERANGE_LONG,
  date_range_prior: process.env.REACT_APP_DATERANGE_PRIOR,
  last_month: process.env.REACT_APP_LAST_MONTH,
  last_month_prior: process.env.REACT_APP_LAST_MONTH_PRIOR,

  granularity: 'quarter',
  granularity_value: "3",
  grouping_component:'MnEvents.totalAmount',
  gross_revenue_grouped: '0',
  gross_profit_grouped: '0',
  period: 'Last Twelve Months',
  period_months: '24',
  limit:20,
  lookback: 12,
  timeInterval: 23,
  topcustomers_period: [
    process.env.REACT_APP_DATERANGE_PRIOR.split(';'),
    process.env.REACT_APP_DATERANGE.split(';')
  ],
  KPIs_Exclusion_List:[], // List of customer not included in total with  Totals in period Trends
  KPIs_Exclusion_Detail:[],  // List of customer not included in total with  Totals in period Trends
}


// Define Contexts used across the application.
export const Context = createContext(initialContextValues)

/** 
 * Application Entry Point
 * 
 */
function App() {

  // Definition of contexts states. Each states is initialized calling the reducer with default parameter.
  // The context state initialitation specify the dispatcher method  for their update. 

  //General Context State
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'granularity':
        return { ...state, granularity: action.value }
      case 'date_range':
        return { ...state, date_range: action.value }
      case 'period_months':
        return { ...state, period_months: action.value }
      case 'date_range_prior':
        return { ...state, date_range_prior: action.value }
      case 'gross_revenue_grouped':
        return { ...state, gross_revenue_grouped: action.value }
      case 'gross_profit_grouped':
        return { ...state, gross_profit_grouped: action.value }
      case 'timeInterval':
        return { ...state, timeInterval: action.value}
      case 'granularity_value':
        return { ...state, granularity_value: parseFloat(action.value)}
      case 'lookback':
        return { ...state, lookback: parseFloat(action.value)}
      case 'KPIs_Exclusion_List':
        return { ...state, KPIs_Exclusion_List: action.value}
      case 'KPIs_Exclusion_Detail':
        return { ...state, KPIs_Exclusion_Detail: action.value}
  
      default:
        return state
    }
  }, initialContextValues)


  const { token, setToken } = useToken()

  const topCustomerList =   QueryCubeRemote(pandl_by_customer(state.topcustomers_period[1], [],10)) 


  const  [grossRevenueTrend, setGrossRevenueTrend] = useState()
  const  [grossProfitTrend, setGrossProfitTrend] = useState()
  const  [netProfitTrend, setNetProfitTrend] = useState()
  
  

  useEffect(() => {
    const parseResultSet = (topCustomerList) =>{
      const pivoted = topCustomerList.tablePivot()   
      const list = pivoted.map(x => x['MnEvents.customerId'])
      return [pivoted,list]
    }
    const dataParsed =(topCustomerList)? parseResultSet(topCustomerList): [null ,[]]
    if (topCustomerList){
      dispatch({
        type: 'KPIs_Exclusion_List',
        value: dataParsed[1]
      })
      dispatch({
        type: 'KPIs_Exclusion_Detail',
        value: dataParsed[0]
      })  
    }

  },[topCustomerList])


  //If the token isn't set and the environment allow login return the Login Component instead the dashboard page
  if (!token || localStorage.getItem('token')==='undefined') {
    return <Login setToken={setToken} />
  }

 
  //If login is not necessary the function return the application main view.
  //The content is placedas child of Contexts defined to make accessible to all other components.  
  return (
    <Context.Provider value={[state, dispatch]}>
      <div className="App">
        {/* Define the router component to allow navigation between the sections. */}
        <Router basename={process.env.REACT_APP_BASE_PATH}>
          <div className="App-body">
            {/* Call the sidebar component */}
            <div id="sidebar-wrapper" md={1}>
              <SideBar />
            </div>
            {/* Content Area. Are displayed Component called by the router (Url path) */}
            <div id="page-content-wrapper" md={11}>

              <Routes>
                <Route path="/administration" element={<AdministrationPage />} />
                <Route path="/:element" element={<DataPage />} />
                <Route path="/" element={<DashBoardPage />} />
                <Route path="/profitandloss" element={<ProfitAndLossSection />} />
                <Route path="/storecredit" element={<StoreCreditSection />} />
              </Routes>
              <Footer />
            </div>
          </div>
        </Router>
      </div>
    </Context.Provider>
  )
}

export default App
