import axios from 'axios';

let APIKit = axios.create({
  baseURL: process.env.REACT_APP_BI_API_ROOT,
  timeout: 1000000,
})

const currentToken =localStorage.getItem('token')

const config = currentToken === 'undefined' ? 
null
:
{
  headers: { 
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}` ,
  }
}


export const getVersionCheck =  () =>{
    if (config === null){
      return '\u2014';
    }
    const response = APIKit.get('version',config).then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          return '\u2014';
        }
      });
    if (response)
      return  response.data
    else
      return {id: null}     
}

export const getApiAdministration = async (endpoint) =>{
  return APIKit
    .get(endpoint,config)
    .then(response => {
    return response.data;
  })
  .catch((error) => {
    if (error.response && error.response.status === 404) {
      return {code: error.response.status, message:error.response.message };
    }
  });
}

export const getApiData = async (endpoint,params) =>{
  const configa = {...config, ...params}
  return APIKit
    .get(endpoint, configa)
    .then(response => {
    return response.data;
  })
  .catch((error) => {
    if (error.response && error.response.status === 404) {
      return '\u2014';
    }
  });
}


export const postApiAdministration = async(endpoint,body) =>{
  return APIKit
  .post(endpoint,body,config)
  .then(response => {
    return response.data;
  })
  .catch((error) => {
    if (error.response && error.response.status === 404) {
      return {result: 500};
    }
  });
}

export const putApiAdministration = async(endpoint,body) =>{
  return APIKit
  .put(endpoint,body,config)
  .then(response => {
    return {result: 200, message: response};
  })
  .catch((error) => {
    if (error.response && error.response.status === 404) {
      return {result: 500};
    }
  });
}

export const deleteApiAdministration = async(endpoint) =>{
  return APIKit
  .delete(endpoint,config)
  .then(response => {
    return {code: 200, message: response};
  })
  .catch((error) => {
      if (error.response) {
        return {code: error.response.status, message:error.response.statusText };
      }
  });
}
