import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { GroupsInfo } from '../COA/Groups';
import { COAInfo } from '../COA/COA';
export const AdminTabSetUp = () =>{
  const { t } = useTranslation()
  const [itemDisplay, setItemDisplay]= useState(<COAInfo />)
  const [selectedItem, setSelectedItem] = useState('coamap')
  const selectItem = (item) => {
    setSelectedItem(item)
    switch (item) {
        case "coamap":
            setItemDisplay(<COAInfo />)
            break;
        case "camgroup":
          setItemDisplay(<GroupsInfo />)
          break;

        default:
          setItemDisplay(<></>)
        break;
    }
  }
  return  <>
      <Navbar bg="primary" data-bs-theme="dark" className='navbar-primary' default >
      <Container>
          <Nav className="me-auto" defaultActiveKey="pers">
              <Nav.Link className={selectedItem ==='coamap' ? 'nav-link--active' :'navlink--item'}  eventKey="coamap" onClick={() => selectItem("coamap")} >{t('SECTION.ADMINISTRATION.SETUP.COA')}</Nav.Link>
              <Nav.Link className={selectedItem ==='camgroup' ? 'nav-link--active' :'navlink--item'}  eventKey="camgroups"  onClick={() => selectItem("camgroup")}>{t('SECTION.ADMINISTRATION.SETUP.CAM')}</Nav.Link>
          </Nav>
      </Container>
      </Navbar>
      {itemDisplay}
</>

}