import { Table, Button, Row, Col, Form, Modal, Spinner, InputGroup } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { dateUtilities,  returnQuarterBoundaries } from "../../utilities/date_formatting"
import { getApiAdministration, postApiAdministration , putApiAdministration, deleteApiAdministration } from "../../common/APICalls/apiAdministration"
import { useEffect, useState } from "react"
import { quarterOrMonth } from "../../utilities/date_formatting"
import * as FaIcons from 'react-icons/fa'

import { previousQuarterLimits } from "../../utilities/date_formatting"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CurrencyInput from 'react-currency-input-field';

import { FormattedValue } from "../../common/FormattedValues/FormattedValues"
export const CostData = () => {

  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [groupList,setGroupList] = useState([])
  
  const [onWork, setOnWork] = useState(false)
  const [onEditing, setOnEditing] = useState(false)
  const [newCost, setNewCost] = useState(false)
  const [editCost, setEditCost] = useState(false)

  const [groupSelectValue, setGroupSelectValue] = useState('')
  const [currentCost, setCurrentCost] = useState({})

  const previousQuarter = previousQuarterLimits()

  const [referenceDate, setReferenceDate] = useState()  

  const [deleteCost, setDeleteCost] = useState(false)
  const [onDeleteCost, setOnDeleteCost] = useState(false)

  const handleCostEntries = () => {
    getApiAdministration('costentries').then((response) =>{
        if (response ){
          setData(response)
        }    
    })
  }

  const CAMGroupsList = () => {
    getApiAdministration('groupsmanual').then((response) =>{
      if (response ){
        setGroupList(response)
      }    
  })

  }



  const handleCostDescription = (e) => {
    let cost = {...currentCost};
    cost.EntryDescription = e;  
    setCurrentCost(cost)
  }

  const handleCostAmount = (e) => {

    // if value is not blank, then test the regex
    const value = e.replace(/[^0-9.,]|\$|,/g,'')

    let cost = {...currentCost};
      cost.Amount = value       //Set Amount in Cents 
      setCurrentCost(cost)  

  }
  const handleCancelEditingAddGroups = () => { 
    setNewCost(false)
  }

  const handleChangeGroup = (e) => {
    let cost = {...currentCost}
    setGroupSelectValue(e.target.value)
    cost.CAMGroup = e.target.value
    cost.CAMGroupDescription = e.target[e.target.selectedIndex].text
    setCurrentCost(cost)
  }

  const handleSetPeriodDate = (e) => {
    //Calculate Quarter Limits
    const quarter = returnQuarterBoundaries(Math.floor(((e.getUTCMonth()) / 3)+1), e.getUTCFullYear() )
    const referenceDateComponents =quarter[0].split('-')
    const startFullQuarter = new Date( Date.UTC(parseInt(referenceDateComponents[0]),parseInt(referenceDateComponents[1]),1))
    const endFullQuarter = new Date(quarter[1]);


    setReferenceDate(startFullQuarter)

    let cost = {...currentCost}
    cost.ReferenceDate = quarter[0]
    cost.StartDate =  quarter[0]
    cost.EndDate = quarter[1]
    setCurrentCost(cost)
    
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setOnWork(true)
    const cost = {...currentCost}
 
    
    if (newCost) {

      postApiAdministration('costentry',cost).then((response) =>{
        if (response ){
            
          cost.EntryDescription = ''
          cost.CAMGroup = ''
          cost.CAMGroupDescription = ''
          setCurrentCost(cost)
          setGroupSelectValue(e.target.value)

          // Reset state variables
          setNewCost(false)
          setCurrentCost({})
          setReferenceDate(new Date(previousQuarter[0]))

          // Reload Table
          handleCostEntries()

          // Turn off Work Process
          setOnWork(false)
        }    
      })

    } else {

      putApiAdministration('costentry',cost).then((response) =>{
          if (response ){
 
            cost.EntryDescription = ''
            cost.CAMGroup = ''
            cost.CAMGroupDescription = ''
            
            setCurrentCost(cost)
            setGroupSelectValue(e.target.value)
  
            // Reset state variables
            setNewCost(false)
            setCurrentCost({})
                // Prepare Reference Date


            setReferenceDate(new Date(previousQuarter[0]))

            // Reload Table
            handleCostEntries()
  
            // Turn off Work Process
            setOnWork(false)
            setOnEditing(false)
   
            setEditCost(false)
            handleCostEntries()
        }    
      })
    }


  }

  const handleEditCost = (e) => {
    setOnEditing(true)
    const referenceCost = data[e]
    referenceCost.Amount = referenceCost.Amount/100
    referenceCost.ReferenceDate = referenceCost.ReferenceDate.split('T')[0]
    referenceCost.StartDate = referenceCost.StartDate.split('T')[0]
    referenceCost.EndDate = referenceCost.EndDate.split('T')[0]

    setCurrentCost(referenceCost)
    // Prepare Reference Date
    const dateReference = data[e].ReferenceDate.split('T')[0].split('-')     
    setReferenceDate(new Date(Date.UTC(dateReference[0], dateReference[1],dateReference[2])))
    setGroupSelectValue(data[e].CAMGroup)

  }

  const handleNewCost = () => {
    setReferenceDate(new Date(previousQuarter[0]))

    let cost = {...currentCost}
    cost.ReferenceDate = previousQuarter[0].split('T')[0]
    cost.StartDate =  previousQuarter[0].split('T')[0]
    cost.EndDate = previousQuarter[1].split('T')[0]
    cost.EntryDescription = ''
    cost.Amount = 0
    setCurrentCost(cost)

    setNewCost(true)
  }
  const handleCancelEditing =(e) =>{

    const referenceCost = currentCost
    referenceCost.Amount = referenceCost.Amount*100
    setCurrentCost(referenceCost)

    setOnEditing(false)
    setCurrentCost({})
  }
  const handleDeleteCost = (e) => {
    setCurrentCost(data[e])
    setDeleteCost(true)
  }

  const handleConfirmTrash = () =>{
    let cost = {...currentCost}
    setOnDeleteCost(true)
    deleteApiAdministration('costentry/' +  cost.id).then((response) => {
      if (response.code===200) {
        setOnDeleteCost(false)
        setDeleteCost(false)
        // Reset state variables
        setNewCost(false)
        setCurrentCost({})
        setReferenceDate(new Date(previousQuarter[0]))

        // Reload Table
        handleCostEntries()

        // Turn off Work Process
        setOnWork(false)
        
      }
    })

  }
  const classesButtons = () => {
    return ['buttonQBOBoxInRow', newCost ? 'showComponentRow' : 'hideComponent'].join(' ')
  }

  const classesNewEntryButton = () => {
    return [newCost ? 'hideComponent' : 'showComponentRow'].join(' ')
  }


  useEffect(() => {
    handleCostEntries()
    CAMGroupsList()
  },[])


  const newCostActionRow = <tr className={newCost ? 'hideComponent' : 'showComponentRow'} >
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td className="cell-value">
      <Button variant="outline-primary" disabled={onEditing} className={classesNewEntryButton()} onClick={()=>handleNewCost()}>    
            Add Entry
      </Button>
    </td>
  </tr>

  const newCostRow =  <tr className={newCost ? 'showComponentRow' : 'hideComponent'}>
      <td >
        <DatePicker 
          showIcon 
          showQuarterYearPicker
          minDate={new Date("01-01-2020")}
          maxDate={new Date(previousQuarter[0])}
          dateFormat="yyyy QQQ"
          wrapperClassName="customDatePickerWidth"
          value={referenceDate}
          selected={referenceDate}
          onChange={(date) => handleSetPeriodDate(date)}
          />
      </td>
      <td>
      <InputGroup style={{display:'flex'}} >
          <Form.Select 
            id='CAMGroupSelect' 
            onChange={(e) =>handleChangeGroup(e)} 
          >
              <option selected={groupSelectValue === ''} value=''>-</option>
              {groupList.map((vendor) =>{
                  return <option selected={groupSelectValue === vendor.id} value={vendor.id}>{vendor.name}</option>
              }
              )}
          </Form.Select>
        </InputGroup>
      </td>
      <td>
        <CurrencyInput className="form-control cell-value" placeholder="Amount" prefix="$" value={currentCost.Amount} onChange={(e) =>handleCostAmount(e.target.value)} />
      </td>
      <td>
        <Form.Control 
            type="text" 
            placeholder="Notes" 
            value={currentCost.EntryDescription}
            onChange={(e) => handleCostDescription(e.target.value)} 
        />
      </td>
      <td>
        <Row className={onEditing  ? 'hideComponent' : 'showComponentRow'}>
          <Col>
            <Button variant="outline-danger" 
                  className={classesButtons()} 
                  onClick={()=>handleCancelEditingAddGroups()}>
                    <FaIcons.FaBan />
            </Button>
          </Col>
          <Col>
            <Button variant="outline-success" 
              className={classesButtons()}  
              onClick={(e)=> handleSubmit (e)}
              >
              <FaIcons.FaCheckDouble />
            </Button>
              
          </Col>
        </Row>

      </td>
    </tr>

  return <div>
      <Table striped bordered style={{width:'100%'}}>
      <tbody>
          {newCostRow}  
          {newCostActionRow}        

      </tbody>
      </Table>
      
      <Table striped bordered style={{width:'100%'}}>
      <thead>
        <tr>
          <th className = "cell-label">{t('SECTION.ADMINISTRATION.COSTENTRIES_TABLE.PERIOD')}</th>
          <th className = "cell-label">{t('SECTION.ADMINISTRATION.COSTENTRIES_TABLE.CAMGROUP')}</th>
          <th className = "cell-value">{t('SECTION.ADMINISTRATION.COSTENTRIES_TABLE.AMOUNT')}</th>
          <th className = "cell-label">{t('SECTION.ADMINISTRATION.COSTENTRIES_TABLE.NOTES')}</th>
          <th className = "cell-label">{t('GENERAL.ACTIONS.LABEL')}</th>

        </tr>
      </thead>
      <tbody>
          {data.map((manualCost,index) =>{
              return <tr>
                  <td className="cell-label">
                    <span className={onEditing && currentCost.id === manualCost.id ? 'hideComponent' : 'showComponentRow'}>
                      {quarterOrMonth(manualCost.ReferenceDate,'quarterreverse')}
                    </span>
                    <span className={onEditing && currentCost.id === manualCost.id ? 'showComponentRow' : 'hideComponent'}>
                      <DatePicker 
                        showIcon 
                        showQuarterYearPicker
                        minDate={new Date("01-01-2020")}
                        maxDate={new Date(previousQuarter[0])}
                        dateFormat="yyyy QQQ"
                        wrapperClassName="customDatePickerWidth"
                        value={referenceDate}
                        selected={referenceDate}
                        onChange={(date) => handleSetPeriodDate(date)}
                        />
                      </span>
                    
                  </td>
                  <td className="cell-label">
                    <span className={onEditing && currentCost.id === manualCost.id ? 'hideComponent' : 'showComponentRow'}>
                      {manualCost.CAMGroupDescription}
                    </span>
                    <span className={onEditing && currentCost.id === manualCost.id ? 'showComponentRow' : 'hideComponent'}>
                      <Form.Select 
                        id='CAMGroupSelect' 
                        onChange={(e) =>handleChangeGroup(e)} 
                      >
                          <option selected={groupSelectValue === ''} value=''>-</option>
                          {groupList.map((vendor) =>{
                              return <option selected={groupSelectValue === vendor.id} value={vendor.id}>{vendor.name}</option>
                          }
                          )}
                      </Form.Select>
                    </span>
                  </td>
                  <td className="cell-value">
                    <span className={onEditing && currentCost.id === manualCost.id ? 'hideComponent' : 'showComponentRow'}>
                      <FormattedValue type="moneyexact" value={manualCost.Amount/100} /> 
                    </span>
                    <span className={onEditing && currentCost.id === manualCost.id ? 'showComponentRow' : 'hideComponent'}>
                      <CurrencyInput className="form-control cell-value" placeholder="Amount" prefix="$" value={currentCost.Amount} onChange={(e) =>handleCostAmount(e.target.value)} />
                    </span>
                  </td>
                  <td className="cell-label">
                    <span className={onEditing && currentCost.id === manualCost.id ? 'hideComponent' : 'showComponentRow'}>
                      {manualCost.EntryDescription}
                    </span>
                    <span className={onEditing && currentCost.id === manualCost.id ? 'showComponentRow' : 'hideComponent'}>
                      <Form.Control 
                          type="text" 
                          placeholder="Notes" 
                          value={currentCost.EntryDescription}
                          onChange={(e) => handleCostDescription(e.target.value)} 
                      />

                    </span>
                  </td>
                  <td className="cell-label">
                  <Row className={onEditing && currentCost.id === manualCost.id ? 'hideComponent' : 'showComponentRow'}>
                      <Col>
                        <Button variant="outline-secondary" 
                          disabled={(onEditing && currentCost.id !== manualCost.id) || newCost }
                          onClick={((e) => handleEditCost(index))}>
                            <FaIcons.FaPencilRuler />
                        </Button>
                      </Col>
                      <Col>
                        <Button variant="outline-danger" 
                          disabled={(onEditing && currentCost.id !== manualCost.id) ||  newCost }
                          onClick={(e)=> handleDeleteCost(index)}
                          >
                            <FaIcons.FaTrash />
                        </Button>
                        
                      </Col>
                    </Row>
                    <Row className={onEditing && currentCost.id === manualCost.id ? 'showComponentRow' : 'hideComponent'}>
                      <Col>
                        <Button variant="outline-danger" 
                              onClick={()=>handleCancelEditing()}>
                                <FaIcons.FaBan />
                        </Button>
                      </Col>
                      <Col>
                        <Button variant="outline-success" 
                          onClick={(e)=> handleSubmit (e)}
                          >
                          <FaIcons.FaCheckDouble />
                        </Button>
                          
                      </Col>
                    </Row>


                  </td>
              </tr>
          })}

      </tbody>


    </Table>

    <Modal show={deleteCost} >
            <Modal.Header>
            <Modal.Title>
                Confirm Operation 
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='d-flex justify-content-center'>
                <div className={onDeleteCost ? "hideComponent" : "showComponent"}>
                    Do you want delete this Cost Entry?
                </div>
                <div  className={!onDeleteCost ? "hideComponent" : "showComponent"}>
                <Spinner animation="grow" size="xl" />
                </div>
            </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" 
                        className="buttonQBOBoxInRow" 
                        onClick={()=>setDeleteCost(false)}>
                            {t('ACTIONS.CANCEL')}
                </Button>
                <Button variant="primary" 
                        className="buttonQBOBoxInRow" 
                        onClick={() => handleConfirmTrash()}>
                            {t('ACTIONS.CONFIRM')}
                </Button>

            </Modal.Footer>
        </Modal>

     

  </div>

}