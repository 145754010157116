// Hooks Import
import { useTranslation} from 'react-i18next';
import { useContext } from 'react';

// Context Import 
import { Context } from '../App';

export const Selector_customers_trend_config = () =>{

  const { t } = useTranslation()

  return {
    lookback:{
      disabled:true
    },
    timeInterval:{
      disabled:false,
      options: <>
      <option value="23">{t('SELECTORS.TIMEINTERVAL.24_MONTHS')}</option>
      <option value="11">{t('SELECTORS.TIMEINTERVAL.12_MONTHS')}</option>
      <option value="5">{t('SELECTORS.TIMEINTERVAL.6_MONTHS')}</option>
      <option value="2">{t('SELECTORS.TIMEINTERVAL.3_MONTHS')}</option>
      </>
    },
    granularity:{
      disabled:true,
    }

  }
}
export const Selector_customers_churntrend_config =() =>{
  const { t } = useTranslation()
  const [customerState] = useContext(Context)
  return {
      lookback:{
        disabled:false,
        options: <>
        <option value="11">{t('SELECTORS.LOOKBACK.12_MONTHS')}</option>
        <option value="2">{t('SELECTORS.LOOKBACK.3_MONTHS')}</option>
        <option value="0">{t('SELECTORS.LOOKBACK.1_MONTH')}</option>
        </>
      },
      timeInterval:{
        disabled:true
      },
      granularity:{
        disabled:false,
        options: <> 
          <option disabled={customerState.churnratetrend_lookback === 12 || customerState.churnratetrend_lookback === 3 ? false : true} value="3">{t('SELECTORS.GRANULARITY.QUARTER')}</option>
          <option disabled={customerState.churnratetrend_lookback === 12 || customerState.churnratetrend_lookback === 1 ? false : true} value="1">{t('SELECTORS.GRANULARITY.MONTH')}</option>
        </>
      }
    }

}

export const Selector_look_back_12 = () =>{
  const { t } = useTranslation()
  return {
      lookback:{
        disabled:false,
        options: <>
        <option value="11">{t('SELECTORS.LOOKBACK.12_MONTHS')}</option>
        <option value="5">{t('SELECTORS.LOOKBACK.6_MONTHS')}</option>
        <option value="2">{t('SELECTORS.LOOKBACK.3_MONTHS')}</option>
        <option value="0">{t('SELECTORS.LOOKBACK.1_MONTH')}</option>
        </>
      },
      timeInterval:{
        disabled:true
      },
      granularity:{
        disabled:true,
        options: <> 
        <option value="">{t('SELECTORS.GRANULARITY.NONE')}</option>
        </>
      }
    }
}

export const Selector_gross_trend_config = () =>{
  const { t } = useTranslation()
  const [grossState] = useContext(Context)
  return {
    lookback:{
      disabled:true
    },
    timeInterval:{
      disabled:false,
      options: <>
      <option value="23">{t('SELECTORS.TIMEINTERVAL.24_MONTHS')}</option>
      <option value="11">{t('SELECTORS.TIMEINTERVAL.12_MONTHS')}</option>
      <option value="5">{t('SELECTORS.TIMEINTERVAL.6_MONTHS')}</option>
      <option value="2">{t('SELECTORS.TIMEINTERVAL.3_MONTHS')}</option>
      <option value="0">{t('SELECTORS.TIMEINTERVAL.1_MONTH')}</option>
      </>
    },
    granularity:{
      disabled:false,
      options: <> 
        <option disabled={grossState.grosstrend_timeInterval === 1? true : false} value="month">
          {t('SELECTORS.GRANULARITY.MONTH')}
        </option>
        <option disabled={grossState.grosstrend_timeInterval === 1? true : false} value="week">
          {t('SELECTORS.GRANULARITY.WEEK')}
        </option>
        <option disabled={grossState.grosstrend_timeInterval === 1? false : true} value="day">
          {t('SELECTORS.GRANULARITY.DAY')}
        </option>
      </>
    }

  }


}

export const Selector_grossprofit_trend_config = () =>{
  const { t } = useTranslation()

  return {
    lookback:{
      disabled:true
    },
    timeInterval:{
      disabled:false,
      options: <>
      <option value="23">{t('SELECTORS.TIMEINTERVAL.24_MONTHS')}</option>
      <option value="11">{t('SELECTORS.TIMEINTERVAL.12_MONTHS')}</option>
      </>
    },
    granularity:{
      disabled:true,
    }

  }


}
export const Selector_top_customers_config = () =>{
  const { t } = useTranslation()

  return {
    lookback:{
      disabled:true
    },
    timeInterval:{
      disabled:false,
      options: <>
      <option value="23">{t('SELECTORS.TIMEINTERVAL.24_MONTHS')}</option>
      <option value="11">{t('SELECTORS.TIMEINTERVAL.12_MONTHS')}</option>
      <option value="5">{t('SELECTORS.TIMEINTERVAL.6_MONTHS')}</option>
      </>
    },
    granularity:{
      disabled:true,
      options: <> 
        <option value="quarter">{t('SELECTORS.GRANULARITY.QUARTER')}</option>
        <option value="month">{t('SELECTORS.GRANULARITY.MONTH')}</option>
      </>
    }

  }
}

export const Selector_PandL = () => {
  const { t } = useTranslation()
  return {
    timeInterval:{
      disabled:false,
      options: <>
      <option value="MnEvents.totalAmount">{t('SELECTORS.PANDL.GROSSREVENUE')}</option>
      <option value="MnEvents.grossMargin">{t('SELECTORS.PANDL.GROSSPROFIT')}</option>
      <option value="MnEvents.netMargin">{t('SELECTORS.PANDL.NETPROFIT')}</option>
      </>
    }
  }
}

export const Selector_disabled = () =>{
  return {
    lookback:{disabled:true},
    timeInterval:{disabled:true},
    granularity:{disabled:true}
  }
}



export const Selector_v15_context_config = () => {
  const { t } = useTranslation()
  const [currentContext] = useContext(Context)

  return {
    lookback:{
      disabled:false,
      options: <>
      <option value="11">{t('SELECTORS.LOOKBACK.12_MONTHS')}</option>
      <option value="2">{t('SELECTORS.LOOKBACK.3_MONTHS')}</option>
      <option value="0">{t('SELECTORS.LOOKBACK.1_MONTH')}</option>
      </>
  },
    timeInterval:{
      disabled:false,
      options: <>
      <option value="23">{t('SELECTORS.TIMEINTERVAL.24_MONTHS')}</option>
      </>
    },
    granularity:{
      disabled:false,
      options: <> 
        <option disabled={currentContext.lookback === 12 || currentContext.lookback === 3 ? false : true} value="3">{t('SELECTORS.GRANULARITY.QUARTER')}</option>
        <option disabled={currentContext.lookback === 12 || currentContext.lookback === 1 ? false : true} value="1">{t('SELECTORS.GRANULARITY.MONTH')}</option>
      </>
    }
  }

}