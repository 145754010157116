import { useCases } from "./use_cases";
export const teammember_monthly =(kpi,daterange,granularity) =>{

  const query = 
  {
    "measures": [
      "TeamMembersMonth." + kpi + "Member",
      "TeamMembersMonth." + kpi,
      "TeamMembersMonth.full_fte"
    ],
    "timeDimensions": [
      {
        "dimension": "TeamMembersMonth.dateDocument",
        "granularity": granularity,
        "dateRange": [process.env.REACT_APP_REFERENCE_START,process.env.REACT_APP_REFERENCE_DATE]
  
      }
    ],
    "order": {},
    "filters": [
      {
        "member": "MnEvents.useCase",
        "operator": "equals",
        "values": useCases.revenue
        
      }
    ]
  }
  return query
}

export const teammember_monthlydetail = (month) => {
  return {
    
      "measures": [
        "MnTeammembersDetail.summedvalue"
      ],
      "order": [
        [
          "MnTeammembersDetail.refDate",
          "asc"
        ],
        [
          "MnTeammembersDetail.name",
          "asc"
        ]
      ],
      "dimensions": [
        "MnTeammembersDetail.name"
      ],
      "timeDimensions": [
        {
          "dimension": "MnTeammembersDetail.refDate",
          "granularity": "month"
        }
      ],
      "filters": [
        {
          "member": "MnTeammembersDetail.refDate",
          "operator": "lt",
          "values": [
            month
          ]
        },
        {
          "member": "MnTeammembersDetail.endDate",
          "operator": "gt",
          "values": [
            month
          ]
        },

        {
          "member": "MnTeammembersDetail.summedvalue",
          "operator": "gt",
          "values": [
            "0"
          ]
        }
      ]
  }
}
