import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { QuerySummaryPeriod } from "../../../logic/CalculatedValues";
import { useState, useContext, useEffect } from 'react';
import { CustomersSection } from '../../../components/Sections/Customers';
import { Card, Row, Col } from 'react-bootstrap';
import { Context } from '../../../App';
import { lazy } from "react";

import { CountWidget } from "../../Widgets/CountWidget";
import { QueryCountWidget } from "../../QueryCountWidget";
import { LineChartKPI } from "../../Graph/LineChart";
import { BarChartRevenue } from "../../Graph/Istogram";
import { total_amount_by_events_bottomup_v15 } from "../../../kpi_queries/p_and_l";
import { total_reveneue_period_v15 } from "../../../kpi_queries/gross_revenue";
import { YearToDateRange } from "../../../utilities/date_formatting";
import { Suspense } from "react";
import { LoadingScreen } from "../../Loader/LoadingScreen";
/**
 * 
 * @returns Dashboard page with all section and KPIs indicators.
 * 
 * The page contain every section and  hide or show sections based on url's hash component presence and value.
 * If the has component is empty the title page dispalyed is the general application.
 * If the location URL component isn't empty the component return visible only the specified section and adapt the page title.  
 */
export const DashBoardPage = () => {       

    const { t } = useTranslation() // Define component to use i18n internationalitation strings



    const [state, dispatch] = useContext(Context) //Gross Revenue Context

    const [revenue,setRevenue] = useState({
      grossRevenue: {detail: [], trendLine:{label:"Months", data:[], labels:[]}, trailing:{previous:0, prior:0}, ytd: {previous:0, prior:0} },
      grossProfit: {detail: [], trendLine:{label:"Months", data:[], labels:[]}, trailing:{previous:0, prior:0}, ytd: {previous:0, prior:0} },
      netProfit: {detail: [], trendLine:{label:"Months", data:[], labels:[]}, trailing:{previous:0, prior:0}, ytd: {previous:0, prior:0} }
    })


    const totalGrossRevenue = QuerySummaryPeriod([state.data_series_date_interval, state.data_series_date_interval_prior], total_amount_by_events_bottomup_v15, 'totalAmount', state.granularity)
    const totalGrossProfit = QuerySummaryPeriod([state.data_series_date_interval, state.data_series_date_interval_prior], total_amount_by_events_bottomup_v15,'grossMargin', state.granularity )
    const totalNetProfit = QuerySummaryPeriod([state.data_series_date_interval, state.data_series_date_interval_prior], total_amount_by_events_bottomup_v15, 'netMargin', state.granularity)


    let location = useLocation()    // The variable has information about the url. 
                                    // useLocation hook to obtain from internal router the URL object and his components.

    useEffect (() => {
      if (
        (totalGrossRevenue && totalGrossRevenue.detail.length >0) && 
        (totalGrossProfit && totalGrossProfit.detail.length >0 ) && 
        (totalNetProfit && totalNetProfit.detail.length >0 ))  {
          setRevenue({
            grossRevenue: totalGrossRevenue,
            grossProfit: totalGrossProfit,
            netProfit: totalNetProfit
        })
      }
    },[totalGrossRevenue, totalGrossProfit,totalNetProfit])


    return <>
        <div className='rounded-3 jumbotron'>
            <h2>{location.hash === '' ? t('GENERAL.MAIN_TITLE') :  t('SECTION.' + location.hash.replace('#','').toUpperCase() +'.TITLE')}</h2>
        </div>
        <div className={location.hash ==='' || location.hash ==='#customers' ? 'displayed': 'hidden'} >
          <Suspense fallback={<LoadingScreen />}>
            <CustomersSection genericView={true} />
          </Suspense>
        </div>
        
        {/* Gross Profit Trend Card*/}


      <Card id="ytdRevenue" className="card_section">
        <Card.Header>
          <div  className = "jumbotron-kpi">
            <div className = 'jumbotron-section-wrapper'>
              <div md = {7} className = "section-title-left">
                <h5 className = 'text-custom-primary'>Revenue</h5>
              </div>
            </div>
          </div>
        </Card.Header>

        <Card.Body className='card-height-xs'>
          <Card >
            <Card.Body>
              <Row >
                <Col className="col_kpi_small" >
                  <Row>
                    <Card className="card_kpi">

                    <Card.Title>Trailing 12 Months</Card.Title>
                      <Card.Body>
                        <QueryCountWidget 
                          period={[state.date_range.split(';'), state.date_range_prior.split(';')]}
                          query={total_reveneue_period_v15} 
                          kpi={'totalAmount'} 
                          multiplier={100}
                        />
                      </Card.Body>
                    </Card>
                    <Card className="card_kpi_bottom">
                      <Card.Title>Year to Date</Card.Title>
                      <Card.Body>
                        <QueryCountWidget 
                          period={YearToDateRange()} 
                          query={total_reveneue_period_v15} 
                          kpi={'totalAmount'}
                          multiplier={100}  
                        />
                      </Card.Body>
                    </Card>
                  </Row>
                </Col>

                <Col>
                <Card className="card_kpi">
                <Card.Title>Trend</Card.Title>
                  <Card.Body>
                    {revenue.grossRevenue.trendLine.data.length >0 ? <LineChartKPI formatLabel={true} dataSeries={[revenue.grossRevenue.trendLine]} title="" granularity={state.granularity} Yaxis="$" filterable={false}/> :<></>}
                </Card.Body>
                </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>

      <Card id="ytdGrossProfit" className="card_section">
        <Card.Header>
          <div  className = "jumbotron-kpi">
            <div className = 'jumbotron-section-wrapper'>
              <div md = {7} className = "section-title-left">
                <h5 className = 'text-custom-primary'>Gross Profit</h5>
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Row >
            <Col className="col_kpi_small" >
            <Row>
              <Card className="card_kpi">

                <Card.Title>Trailing 12 Months</Card.Title>
                <Card.Body>
                  <QueryCountWidget 
                    period={[state.date_range.split(';'), state.date_range_prior.split(';')]}
                    query={total_reveneue_period_v15} 
                    kpi={'grossMargin'} 
                    multiplier={100}
                  />
                </Card.Body>
              </Card>
              <Card className="card_kpi_bottom">
                <Card.Title>Year to Date</Card.Title>
                <Card.Body>
                  <QueryCountWidget period={YearToDateRange()} 
                    query={total_reveneue_period_v15} 
                    kpi={'grossMargin'}
                    multiplier={100} 
                  />
                </Card.Body>
              </Card>
            </Row>

            </Col>

            <Col>
              <Card className="card_kpi">
                <Card.Title>Trend</Card.Title>
                  <Card.Body>
                    {revenue.grossProfit.trendLine.data.length >0 ? <LineChartKPI formatLabel={true} dataSeries={[revenue.grossProfit.trendLine]} granularity={state.granularity} title=""  Yaxis="$" filterable={false}/> :<></>}
                  </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card id="ytdNetProfit" className="card_section">
        <Card.Header>
          <div  className = "jumbotron-kpi">
            <div className = 'jumbotron-section-wrapper'>
              <div md = {7} className = "section-title-left">
                <h5 className = 'text-custom-primary'>Net Profit</h5>
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Row >
          {/** 

            <Col className="col_kpi_small" >
            <Row>
              <Card className="card_kpi">
                <Card.Title>Trailing 12 Months</Card.Title>
                <Card.Body>
                  <QueryCountWidget 
                    period={[state.date_range.split(';'), state.date_range_prior.split(';')]}
                    query={total_reveneue_period_v15} 
                    kpi={'netMargin'} 
                    multiplier={100}
                  />
                </Card.Body>
              </Card>
              <Card className="card_kpi_bottom">
                <Card.Title>Year to Date</Card.Title>
                <Card.Body>
                  <QueryCountWidget 
                    period={YearToDateRange()} 
                    query={total_reveneue_period_v15} 
                    kpi={'netMargin'}
                    multiplier={100}  
                  />
                </Card.Body>
              </Card>
            </Row>

            </Col>
  */}
            <Col>
              <Card className="card_kpi">
                <Card.Title>Trend</Card.Title>
                  {revenue.netProfit.trendLine.data.length >0 ? <BarChartRevenue formatLabel={true} colorMono={true} dataseries={[revenue.netProfit.trendLine]} title="" Yaxis="$"/> :<></>}
                <Card.Body>
                </Card.Body>
              </Card>

            </Col>
          </Row>
        </Card.Body>
      </Card>

  </>
}